import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, HostListener } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { EventsService } from "../../services/events.service";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.css']
})
export class AgendaComponent implements OnInit {
  cerraModal = true;


  eventForm: FormGroup;
  events;
  user;
  vIngresos = false;
  privados = [];
  miercoles = [];
  jueves = [];
  viernes = [];
  dia = "jueves";
  lugar = 0;
  rooms = ["Salón INTELIGENCIA ARTIFICIAL", "Salón INTELIGENCIA CREATIVA", "Salón INTELIGENCIA HUMANA"]
  eventshow: any;
  ingresosPop: any;

  ratingsUser

  estilo





  constructor(
    private eventosService: EventsService,
    private authService: AuthService
  ) {
    let user = localStorage.getItem('userInfo')
    this.user = JSON.parse(user).user;
    console.log("C USER", this.user)
    /* this.eventosService.ratingGetByUser(this.user._id).then((r) => {
      console.log("RAT USER", r)
      this.ratingsUser = r
    }) */
  }


  ngOnInit(): void {

    this.loaduser();
    //this.getEvents();
    this.eventForm = new FormGroup({
      name: new FormControl('', Validators.required),
      date: new FormControl('', Validators.required),
      show: new FormControl('', Validators.required),
      dia: new FormControl('', Validators.required),
      lugar: new FormControl('', Validators.required),
      id: new FormControl("0", [Validators.required,]),
    })


  }

  async rating() {
    this.ratingsUser = await this.eventosService.ratingGetByUser(this.user._id)
  }

  descargar_calificaciones(event) {
    var eid=event._id
    this.eventosService.ratingGetByEvent(eid).then((r:any)=>{
      console.log("RGBE",r)
      var cal_ = r.map((contact) => { return { "nombre": contact.user.name, "correo": contact.user.email, "doc": contact.user.doc, "Empresa": contact.user.company, "Cargo": contact.user.position, "celular": contact.user.phone, "calificacion": contact.rating } })
    this.exportAsExcelFile(cal_, "calificaciones"+event.name)
    })
  }

  googleCalendar(event): void {
    console.log("Hola, soy un evento", event);
    var startDate = new Date(event.fechaCompleta);
    var endDate = new Date(startDate.getTime() + 60 * 60 * 1000);
    var title = event.name;
    var details = '';
    var location = "https://lad.pragma.co/agenda";

    var formattedStartDate =
      startDate.getFullYear() +
      ('0' + (startDate.getMonth() + 1)).slice(-2) +
      ('0' + startDate.getDate()).slice(-2) +
      'T' +
      ('0' + startDate.getHours()).slice(-2) +
      ('0' + startDate.getMinutes()).slice(-2) +
      ('0' + startDate.getSeconds()).slice(-2) +
      'Z-5';

    var formattedEndDate =
      endDate.getFullYear() +
      ('0' + (endDate.getMonth() + 1)).slice(-2) +
      ('0' + endDate.getDate()).slice(-2) +
      'T' +
      ('0' + endDate.getHours()).slice(-2) +
      ('0' + endDate.getMinutes()).slice(-2) +
      ('0' + endDate.getSeconds()).slice(-2) +
      'Z-5';

    console.log(
      "Fecha formateada para Google:",
      formattedStartDate,
      "\nTitle: ",
      title,
      "\nDetails: ",
      details,
      "\nFinal date: ",
      formattedEndDate,
      "\nLocation: ",
      location
    );

    var href =
      "https://www.google.com/calendar/render?action=TEMPLATE" +
      "&text=" +
      encodeURIComponent(title) +
      "&dates=" +
      encodeURIComponent(formattedStartDate + '/' + formattedEndDate) +
      "&details=" +
      encodeURIComponent(details) +
      "&location=" +
      encodeURIComponent(location) +
      "&sf=true&output=xml";

    // Abrir la URL en una nueva ventana o pestaña
    window.open(href, '_blank');
  }




  outlookCalendar(event) {
    console.log("Hola, soy un evento de Outlook", event);
    var startDate = event.fechaCompleta + ":00-05:00";
    var title = event.name;
    var details = "";
    var location = "https://lad.pragma.co/agenda";

    console.log("Fecha formateada para Outlook:", startDate,
      "\n title:", title,
      "\n details:", details,
      "\n location:", location);
    var href = `https://outlook.live.com/calendar/0/deeplink/compose?path=/calendar/action/compose&rru=addevent&startdt=${startDate}&subject=${title}&body=${details}&location=${location}`;

    window.open(href, '_blank');
  }





  onClickRating(e: any, id) {

    let data = {
      "user": this.user._id,
      "event": id,
      "rating": e.rating
    }
    this.eventosService.updateRating(data).then((r) => {
      console.log(r)
    })

    console.log("CURSO", data)

    this.eventosService.ratingGetByEvent(id).then((r) => {
      console.log("RAT", r)
    })

  }


  loaduser() {
    this.authService.loaduser()
      .then((response) => {
        console.log(response);
        this.authService.setUserInfo({ 'user': response['user'] });
        let user = localStorage.getItem('userInfo')
        this.user = JSON.parse(user).user;
        console.log("USER", this.user.rol, this.user._id)
      //  this.rating()
        this.getEvents();
      })
      .catch((err) => {
        location.href = "/login"
        console.log(err)
      })
  }


  getEvents() {

    this.miercoles = [];
    this.jueves = [];
    this.viernes = [];
    this.privados = [];
    var now = new Date();
    var n = now.getUTCHours();
    if (n < 5) {
      n = 24 + n;
    }
    function compare(a, b) {
      if (a.date < b.date) {

        return -1;
      }
      if (a.date > b.date) {
        return 1;
      }
      return 0;
    }


    now.setHours(n - 5);
    console.log(now);
    this.eventosService.getAll()
      .then((r) => {
        console.log(r)
        this.events = [];
        var events = r["events"]
        events.sort(compare);
        events.forEach(element => {

         

         // console.log("EVVE", element._id, this.ratingsUser, "RAT", rat);
          var date = new Date(element.date);
          element.fechaCompleta = element.date;

          //var fecha= date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getUTCFullYear()+" hora: "+date.getHours()+":"+(date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
          var fecha = " Hora: " + date.getHours() + ":" + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
          element.date = fecha;
          if (now.getTime() > date.getTime() - (300 * 1000)) {
            element.isnow = true
          }
          if (element.pay != "premium") {
            this.events.push(element)
            switch (element.day) {
              case "miercoles":
                this.miercoles.push(element);
                break;
              case "jueves":
                this.jueves.push(element);
                break;
              case "viernes":
                this.viernes.push(element);
                break;
              default:
                break;
            }
          }
          else {
            this.privados.push(element);

          }


        });
        this.cambioDia("miercoles")
        this.cambioLugar(0)
      })
  }

  newEvent() {
    this.eventosService.create(this.eventForm.value)
      .then((response) => {
        //console.log(response);
        var emptyUser = {
          name: "",
          date: "",
          id: "0"
        };
        this.eventForm.reset(emptyUser);
        this.getEvents();
      })
      .catch((err) => {
        console.log(err);
      })
  }
  edit(event: any) {

    var evento: Object = {
      id: event._id,
      name: event.name,
      date: event.date,
      dia: event.dia,
      lugar: event.lugar,
      show: event.show,


    }
    this.eventForm.reset(evento);
  }
  deleteEvent(id) {
    this.eventosService.delete(id)
      .then((r) => {
        this.getEvents();
      })
  }
  register(event) {
    this.eventosService.register(event, this.user)
      .then((r) => {
        console.log(r);
        this.loaduser();
      })
  }

  getRegisters(event) {
    this.eventosService.registrados(event)
      .then((r: any) => {
        console.log(r)
        let csvContent = "data:text/csv;charset=utf-8,";
        r["results"].forEach(element => {
          let row = element.user.name + "," + element.user.correo;
          csvContent += row + "\r\n";
        });
        saveAs(csvContent, "registrados_" + r["results"][0].eventName + ".csv");
      })

  }


  popIngresos(event) {
    this.eventosService.getIngresoEvent(event)
      .then((r: any) => {
        console.log(r)
        this.ingresosPop = r;
        this.vIngresos = true;
      })
  }

  verIngresos(event) {
    this.eventosService.getIngresoEvent(event)
      .then((r: any) => {
        console.log(r)
        let csvContent = "data:text/csv;charset=utf-8,";
        r.forEach(element => {
          let row = element.user.name + "," + element.user.email + "," + element.user.doc + "," + element.user.company + "," + new Date(element.hora).toLocaleString();
          csvContent += row + "\r\n";
        });
        saveAs(csvContent, "ingresos_.csv");
      })

  }
  cambioDia(dia) {
    this.dia = dia;
    switch (dia) {
      case "miercoles":
        this.events = this.miercoles;
        break;
      case "jueves":
        this.events = this.jueves;
        break;
      case "viernes":
        this.events = this.viernes;
        break;
      default:
        break;
    }
    this.cambioLugar(this.lugar);
  }

  cambioLugar(lugar) {
    this.lugar = lugar;
    console.log(lugar)


    if (lugar == 0) {
      this.estilo = "r0"
    }
    else if (lugar == 1) {
      this.estilo = "r1"
    }
    else if (lugar == 2) {
      this.estilo = "r2"
    }

    this.eventshow = this.events.filter((r) => { return r.room == lugar })

  }



  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_' + new Date().getTime() + EXCEL_EXTENSION);
  }


}
