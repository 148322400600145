import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import  Swal from 'sweetalert2'; 
@Component({
  selector: 'app-conferencistas',
  templateUrl: './conferencistas.component.html',
  styleUrls: ['./conferencistas.component.css']
})
export class ConferencistasComponent implements OnInit {

  constructor(private authService : AuthService, private router : Router) { }
  miFormulario: FormGroup;
submitted;
valido=false;
conferencista;
  ngOnInit(): void {

    this.miFormulario = new FormGroup({
 
      'correo': new FormControl('', [
        Validators.required,
        Validators.email
      ])
    });
  this.authService.geturl()
  .then((response)=>{
    console.log(response);
    this.conferencista =response["url"];
    console.log(this.conferencista);
  })

  }
  get f() { return this.miFormulario.controls; }
fin(){
  this.authService.findConfer(this.miFormulario.get("correo").value)
  .then((response) => {
    console.log(response);
  this.valido=true;
  })
  .catch((err)=>{
    Swal.fire("no estas en nuestra base de datos")
})}
 

}
