import { Component, OnInit, Output, EventEmitter, ElementRef, ViewChild, ChangeDetectorRef, Input} from '@angular/core';
import Swal from 'sweetalert2';
import { AuthService } from '../../services/auth.service';
import QrScanner from 'qr-scanner';

@Component({
  selector: 'app-lector-usuario',
  templateUrl: './lector-usuario.component.html',
  styleUrls: ['./lector-usuario.component.css']
})
export class LectorUsuarioComponent implements OnInit {
  data: boolean=false;
  manilla: any;
  result: any
  cameras:any;
  qrScanner: any;
  @ViewChild('video1', { static: false}) videoElement: ElementRef;
  @Input() modo="contactos";
    
  @Output() newPar =new EventEmitter();
  @Output() cancel =new EventEmitter();
  inter=null;
  isEmpresa: boolean;
  user;
  constructor( private authService: AuthService,
    private changeDetectorRef: ChangeDetectorRef) { }


    ngOnInit(): void {
      this.loaduser()
          this.inter=setInterval(()=>{
            console.log("i ")
          if(this.videoElement.nativeElement){
            this.iniciar();
          }
          },100)
          
        }
        loaduser() {
          this.authService.loaduser()
            .then((response) => {
              console.log(response);
              this.authService.setUserInfo({ 'user': response['user'] });
              let user = localStorage.getItem('userInfo')
              this.user = JSON.parse(user).user;
              
          
             
            })
            .catch((err) => {
               location.href = "/login"
              console.log(err)
            })
        }
      
      
        title = 'qr';
        camaras:any = [] ;
        desiredDevice:any = "";
        selectedCamera(id){
          console.log(id)
          this.qrScanner.setCamera(id);
        }
      
      
      
        iniciar(){
      clearInterval(this.inter);
          this.qrScanner = new QrScanner(this.videoElement.nativeElement,(result) => {
           
            //console.log("RES", this.result.data)
            
            if (result) {
             
              
        this.scanSuccessHandler(result.data);
              this.qrScanner.stop()
              
              this.qrScanner.destroy()
              //this.destroy();
              
             // this.ngOnInit();
              // return
        
            }
         
          }, {
            highlightScanRegion: true,
            highlightCodeOutline: true });
        
          this.qrScanner.start()
          QrScanner.listCameras().then((r)=>{
            console.log(r)
            this.cameras = r
          })
        }
        
      
      
        scanSuccessHandler(e:string){
          console.log("lectura: ",e)
         
      this.authService.loadcontact(e)
      .then((response)=>{
      console.log("resopnse: ",response)
      if(response["user"]!= null){
      
        this.data=true;
        this.manilla=response["user"]
        if(this.manilla.nit){
          this.isEmpresa=true;
        }
       // console.log(this.manilla)
        this.changeDetectorRef.detectChanges();
      }
      else{
        Swal.fire("Error leyendo código")
      }
      
      })
      .catch((err)=>{
        console.log(err)
        Swal.fire("Error leyendo código")
      })
          
       
        }
      
        camerasFoundHandler(e:any){
          console.log(e)
          this.camaras = e;
        }
      
        
        seleccionarCamara(deviceValue:any) {
          console.log(deviceValue);
          if(deviceValue != ""){
            this.desiredDevice = deviceValue
          }
      }
      
      
      termina(){
       location.reload();
      }
      
      async addContact(){
        const { value: comment} = await Swal.fire({
          title: 'Ingresa un comentario para recordar a tu contacto',
          input: 'text',
          background:"#F0F8FF",
          inputPlaceholder: 'Conmentario (opcional)',
          confirmButtonColor:"#696969",
         
        })
        this.authService.addContact(this.manilla._id,comment)
        .then((r)=>{
          console.log(r)
          if(this.manilla.empresat){
            console.log("agrenando empresa")
            this.authService.addCompany(this.manilla.empresat,comment)
            .then((r)=>{
              console.log(r)
                
              
            })
            .catch((err)=>{
              console.log(err);
              
            })
          }
      
         
      
          Swal.fire("Agregado correctamente")
          .then((r)=>{
           
      
            location.href="/escarapela"
          })
        })
        .catch((err)=>{
          console.log(err);
          Swal.fire("Error agregando usuario")
          .then((r)=>{location.reload()})
        })
      }
      async addCompany(){
        const { value: comment} = await Swal.fire({
          title: 'Ingresa un comentario para recordar a tu contacto',
          input: 'text',
          background:"#F0F8FF",
          inputPlaceholder: 'Conmentario (opcional)',
          confirmButtonColor:"#696969",
         
        })
        this.authService.addCompany(this.manilla._id,comment)
        .then((r)=>{
          console.log(r)
          Swal.fire("Empresa agregada correctamente")
          .then((r)=>{
            location.href="/escarapela"
          })
        })
        .catch((err)=>{
          console.log(err);
          Swal.fire("Error agregando empresa")
          .then((r)=>{location.reload()})
        })
      }
      
      addPar(){
        this.newPar.emit(this.manilla._id);
      }
      cancelar(){
      this.cancel.emit();
      }
      
      }
      