<div *ngIf="user.rol=='usuario'" style="  position: fixed; bottom:10px; right: 2px; z-index: 200;  text-align: center; color: #999; padding: 10px">
    <div style="  position: absolute; bottom:0px; right: 8px;   text-align: center; color: #999; padding: 10px">
      <button class="btn btn-orange" type="button" id="btnChat" (click)="openChat()" >
        <strong>Comunicarse con el asesor.</strong> 
      </button>
    </div>
    
    <div #chat [style.display]="'none'"  >
      <app-chat ></app-chat>
      <button (click)="closeChat()" class="btn btn-danger btnChat " >Cerrar</button>
    </div>
  </div>
  
  
  <div style="padding: 5px; text-align: center;">
  
    <h1 style="color: white" >Bienvenido {{user.name}} </h1> 
    <h3 style="color: white" *ngIf="stand"  >{{stand.name}}</h3>
    <br>
  
    <div class="row justify-content-center" *ngIf="stand">
      <div *ngIf="session"  class=" col-md-5  ">
        <div  id="session" width="98%" style="margin-top: 0; height:450px ; text-align: left !important;">
          <img src="/assets/boton_ingresar.png" id="img_sesion" >
          <opv-session #ovSessionComponent [sessionName]="stand.name" [user]="user.name" [tokens]="tokens" [ovSettings]="ovSettings"
            (sessionCreated)="handlerSessionCreatedEvent($event)" (participantCreated)="handlerPublisherCreatedEvent($event)" (error)="handlerErrorEvent($event)">
          </opv-session>
      </div> 

      </div>
      <div *ngIf="!session" class=" col-md-6 embed-responsive embed-responsive-16by9">
        <iframe id="iframe" class="embed-responsive-item"  width="95%" frameborder="0" [src]="stand.liframe | safe" allowfullscreen style="display: block;"></iframe>

      </div>
  
      <div  class=" col-md-6  embed-responsive embed-responsive-16by9">
        <iframe id="iframe" class="embed-responsive-item"  width="95%" frameborder="0" [src]="stand.riframe | safe" allowfullscreen style="display: block;"></iframe>
  
    </div>
  
      <div class=" col-md-5">
        <div>
          <button *ngIf="user.rol=='admin' && session" class="bnt btn-primary" (click)="cerrar_llamada()">Cerrar llamada</button>
          <button *ngFor="let usr of connectedUsers" class="bnt btn-primary" (click)="colgar(usr.correo)"> colgar {{usr.name}} </button>
        </div>
        <br>
        
        <br>
      </div>
  
  
    </div>
    
    
    <br>
    <div class="row justify-content-center" >
      <div class="container text-white" *ngIf="user.rol=='admin'">
        <button (click)="downUsers()" class="btn btn-primary" >Descargar audiencia</button><br>
        <h3>Usuarios en linea: {{existingUsrs?.length || '0' }} </h3> 
        <ul>
          <li (click)="openChatAdmin(usr.id)" *ngFor="let usr of existingUsrs" id="li{{usr.id}}">
            {{usr.name}}
          </li>
        </ul>
      </div>
    </div>
  </div>
  
  
  <div *ngIf="user.rol=='admin'"  >
    <div *ngFor="let usr of existingUsrs" >
      <div id="chat{{usr.id}}" style="display: none;">
        <app-chat  [remoteUser]="usr" ></app-chat>
        <button (click)="closeChatAdmin(usr.id)" class="btn btn-danger btnChat " >Cerrar</button>
        <button (click)="calluser(usr)" class="btn btn-danger btnCall "  >llamar</button>
      </div>
    </div>
  </div>
  
  
  
  
  <div *ngIf="callactive">
    <h2>llamada activa</h2>
    <div>
      local: <video id="localVideo" autoplay="true"  width="400px"></video>
    </div>
    <hr>
    <div>
      remoto:<video id="remoteVideo" autoplay="true" width="400px"></video>
    </div>
  </div>
  
  
  
  <audio id="audio">
    <source src='/ring.mp3' type="audio/mp3">
  </audio>
  
  
  
  
  
  
  