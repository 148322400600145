import { Component, OnInit } from '@angular/core';
import { AuthService } from "../../services/auth.service";
import Swal from 'sweetalert2';
@Component({
  selector: 'app-networking',
  templateUrl: './networking.component.html',
  styleUrls: ['./networking.component.css']
})
export class NetworkingComponent implements OnInit {

  user
  usuariosNetworking
  modal_contactar = false
  nombre_contacto
  mensaje_contacto
  id_contacto
  emailcontacto

  modal_contactos = false
  mis_contactos
  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    this.loaduser()


  }

  contactar(nombre, id, email) {
    this.modal_contactar = true;
    this.nombre_contacto = nombre
    this.id_contacto = id
    this.emailcontacto = email
  }

  limpiar() {
    this.authService.limpiarNetworking().then((l) => {
      console.log(l)
    })
  }

  getContactos() {
    /* this.authService.contactosNetworking(this.user._id).then((c)=>{
      console.log("CONTAC", c)
      this.mis_contactos = c
    }) */

    this.authService.contactosNetworking(this.user._id).then((c) => {
      console.log("CONTAC", c)
      this.mis_contactos = c
    })
  }

  estadoContacto(id) {


    Swal.fire({
      title: "Sus datos de contacto serán visibles para la persona que acepta su petición",
      text: "",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Continuar',
      cancelButtonText: "Cancelar"
    }).then((result) => {
      if (result.value) {

        /* console.log("ACEPTAR")
        this.authService.estadoNetworking(this.user._id, true).then((r)=>{
         console.log("RES",r)
         this.getUsuariosNetworking()
     
        }) */


        this.authService.estadoContactoNetworking(id).then((c) => {
          console.log("COC", c)
          this.ngOnInit()
        })


      }
      else {

      }
    });


    


  }

  getUsuariosNetworking() {

    this.authService.getTickets().then((r: any) => {

      let fil = r.filter(f => f.type == 1 || f.type == 2)


      

      console.log("FIL", fil)

      this.usuariosNetworking = fil
      this.usuariosNetworking.map((u) => {
        var result = this.mis_contactos.some(t => t.contacto._id === u.user._id || t.user._id === u.user._id);
        if (result) {
          u.contacto = true
        }
      })

      this.ordenarPorNombre(this.usuariosNetworking)

    })




  }



  ordenarPorNombre(personas: any[]): any[] {



    return personas.sort((a, b) => {

        const nombreA = a.user.name.toLowerCase();
        const nombreB = b.user.name.toLowerCase();

        if (nombreA < nombreB) {
          return -1;
        }
        if (nombreA > nombreB) {
          return 1;
        }
        return 0;

    });
  }

  enviar_contacto() {


    Swal.fire({
      title: "Sus datos de contacto serán visibles para la persona que acepta su petición",
      text: "",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Continuar',
      cancelButtonText: "Cancelar"
    }).then((result) => {
      if (result.value) {

        /* console.log("ACEPTAR")
        this.authService.estadoNetworking(this.user._id, true).then((r)=>{
         console.log("RES",r)
         this.getUsuariosNetworking()
     
        }) */


        console.log("EEE", this.mensaje_contacto, this.emailcontacto)
        this.authService.contactarNetworking(this.user._id, this.id_contacto, this.emailcontacto, this.mensaje_contacto).then((r) => {
          console.log("CON", r)
          this.modal_contactar = false
          this.ngOnInit()
        })


      }
      else {

      }
    });





  }

  loaduser() {
    console.log("cargando usuario")
    this.authService.loaduser()
      .then((response) => {
        console.log("user: ", response);
        if (response != null) {
          this.authService.setUserInfo({ 'user': response['user'] });
          let user = localStorage.getItem('userInfo')
          this.user = JSON.parse(user).user;
          this.getContactos()
          this.getUsuariosNetworking()
          if (!this.user.networking) {
            this.popNetworking()
          }
        }
        else {
          location.href = "/login"
        }

      })
      .catch((err) => {
        location.href = "/login"
        console.log(err)
      })
  }


  popNetworking() {
    Swal.fire({
      title: "Al hacer parte de netwoking sus datos de contacto serán visibles para otros usuarios",
      text: "",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Continuar',
      cancelButtonText: "Cancelar"
    }).then((result) => {
      if (result.value) {
        console.log("ACEPTAR")
        this.authService.estadoNetworking(this.user._id, true).then((r) => {
          console.log("RES", r)
          this.getUsuariosNetworking()
        })
      }
      else {
        location.href = "/"
      }
    });
  }

}
