<div class="mt-5 row justify-content-center">

    <div class="col-md-4">
        <div class="caja_formulario">
            
            <form [formGroup]="messageForm" (ngSubmit)="newMessage()" >  
                <div class="form-group">
                    <label style="color: white; margin-bottom:1px; "  >Numero:</label>
                    <input type="text" class="form-control"  formControlName="number" 
                     [ngClass]="{ 'is-invalid': submitted && f.number.errors }">
                    <div *ngIf="submitted && f.number.errors" class="invalid-feedback">
                        <div *ngIf="f.number.errors.required">Campo obligatorio</div>
                    
                    </div>
                </div> 
                <div class="form-group">
                    <label style="color: white; margin-bottom:1px; "  >Texto:</label>
                    <textarea  cols="30" rows="3"
                    class="form-control"  formControlName="text" 
                     [ngClass]="{ 'is-invalid': submitted && f.text.errors }"></textarea>
                   
                    <div *ngIf="submitted && f.text.errors" class="invalid-feedback">
                        <div *ngIf="f.text.errors.required">Campo obligatorio</div>
                    
                    </div>
                </div> 
                <input type="submit" value="Enviar" class="btn btn-primary">

            </form>
        </div>
    </div>

</div>