<div class="card  "  style="width:100% ; text-align: center;">



    <div class=""
     
      > 
 
 
  
   
   
       <div style="width: 100%;">
   
 
         <ngx-qrcode [elementType]="elementType" [value]="qrvalue" cssClass="aclass"
           [errorCorrectionLevel]="correctionLevel" alt="Qr code" [width]="110"   *ngIf="!user.clave">
         </ngx-qrcode>
       </div>
     
       <div class="card-body" style="margin-top: 0px; padding: 0px;" >
        
        <h5 style="font-size: 22px; font-weight: 600; color: black;  margin: 10px;" class="card-title ">
            {{ user.name | uppercase }}
          </h5>
          <h5 style="font-size: 20px; font-weight: lighter; color: black;  margin: 10px;" class="card-title  ">
            {{ user.email | uppercase }}
          </h5>
          
          <p style="font-size: 16px; display: block; line-height: 21px;margin-top:5px" class="card-text">
            <span *ngIf="!user.nit" style="font-weight: 600">
              <span style="font-size: 18px; font-weight: lighter; ">{{ user.position }}
                <br>
                {{ user.company }}
              </span> 
              <br />
           
            </span>
            
          </p>
         
       </div>
     </div>
   </div>