import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { EventsService } from '../../services/events.service';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';

@Component({
  selector: 'app-impresion',
  templateUrl: './impresion.component.html',
  styleUrls: ['./impresion.component.css']
})
export class ImpresionComponent implements OnInit {


  @Input() user ={"clave":"","nit":"","pais":"","apellido":"", "phone":"","empresar":"","lname":"Soporte", "name":"Soporte","email":"adminDefault","cargo":"","position":"na","rol":"admin","company":"","id":"admin1","_id":"","photoavatar":"https://selesoluciona.com:8351/fotosmarcos/profile.png","contactos":[],"ticket":{"type":""}};
  elementType = NgxQrcodeElementTypes.URL;
  qrvalue = null;
 
  correctionLevel =NgxQrcodeErrorCorrectionLevels.LOW;
  constructor() { }

  ngOnInit(): void {
    console.log("imp",this.user);
    if(this.user._id){
      this.qrvalue=this.user._id;
    }

       
  }

}
