import { Component, OnInit } from '@angular/core';
import { EventsService } from '../../services/events.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { interval } from 'rxjs';
import { saveAs } from 'file-saver';
import { AuthService } from '../../services/auth.service';
import {ChangeDetectorRef} from '@angular/core'
import  Swal from 'sweetalert2'; 

@Component({
  selector: 'app-notas',
  templateUrl: './notas.component.html',
  styleUrls: ['./notas.component.css']
})
export class NotasComponent implements OnInit {
  createNota: FormGroup;
  user; 
  text;
  constructor(private authService : AuthService, private eventService : EventsService, private router: Router, private cd: ChangeDetectorRef)
   { 
    this.createNota = new FormGroup({
      'correo':new FormControl("",),
      "text":new FormControl("")
    })
   }

  ngOnInit(): void {
    let user = localStorage.getItem('userInfo')
    this.user= JSON.parse(user).user;
this.load();
   
  }
load(){
  this.eventService.loadNota(this.user.correo)
  .then((response) => {
    console.log(response);
    this.createNota.patchValue({
      
      text: response["stand"]["text"]
    });
//console.log(this.text);
    })
 .catch((err)=>{
   console.log(err)
 })
}

  newNota(){
    this.createNota.patchValue({
      correo: this.user.correo,
    
    });
    this.eventService.newNota(this.createNota.value)
    .then((response) => {
      console.log(response);
    
    })
    .catch((err)=>{
     console.log(err);
  })
  }


  download(){
    var text = this.createNota.get("text").value;
    text=text.replace(/\n/g, "\r\n");
    console.log(text);

    var file = new File([text],"notas"+this.user.name+".txt", {type: "text/plain;charset=utf-8"});

    saveAs(file);
  }

}
