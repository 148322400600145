<div class="row mt-5 justify-content-center" style="overflow:auto ;" >
    <div class="col-md-8">
        <table class="w-100 bg-dark text-white">
            <tr>
              
                <th>Nombre</th>
                <th>nit</th>
                <th># personas</th>
           
         
            
            </tr>
            <tr *ngFor="let usr of empresas" >
                
               <a href="/empresap/{{usr.id}}"> <th  >{{usr.name}}</th></a>
                  <th>{{usr.nit}}</th>
                
                  <th>{{usr.personas.length}}</th>
               
            
            </tr>
            </table>
    </div>

</div>