<div class="fondo" >






<div   *ngIf="!data" class="row mt-2 justify-content-center">
    <div class="col-md-6 col-8" style="text-align: center;" >
        <select #listcameras (change)="selectedCamera(listcameras.value)">
            <option value="">-</option>
            <option *ngFor="let camera of cameras" value="{{camera.id}}">{{camera.label}}</option>
        </select>
        
        <video  playsinline  #video1  width="80%" height="auto" ></video>
    </div>
    </div>
    <div   *ngIf="data" class="row mt-2 justify-content-center">
        <div class="col-md-3 col-7"  > 
            <app-profile  [user]="manilla" >
            </app-profile>
            <br>
            
            <div style="text-align: center;">
                <button *ngIf="!isEmpresa && modo=='contactos' " class="btn btn-primary" (click)="addContact()" >Agregar a mis contactos</button>
               
                <button *ngIf="isEmpresa && modo=='contactos' " class="btn btn-primary" (click)="addCompany()" >Agregar a empresas de interés</button>
                <button *ngIf="!isEmpresa && modo=='pares' " class="btn btn-primary" (click)="addPar()" >Agrear a mis compañeros de juego</button>
               
            </div>
            
                </div>
    </div>
    <div style="text-align: center;">
        <a href="/"  *ngIf="modo!='pares' "> <button  class="btn btn-danger mt-3"  >Cancelar</button></a>
       <button *ngIf="modo=='pares' " class="btn btn-danger mt-3" (click)="cancelar()" >Cancelar</button>
        <br><br>
        
    </div>
</div>