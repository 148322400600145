
  <button (click)="downloadContacts()" class="btn btn-orange" >Descargar </button>

  <div  class="row justify-content-center" >
    <div class="col-md-8" >
      <div class="row" >
        <div class="col-12">
          <h1>Contactos</h1>
        </div>
      </div>
      <div class="row justify-content-center" >
        
        <div  class="col-md-6 col-6" *ngFor="let contact of contacts"  >
          <div class="card" >
            <div class="card-body" >
              <app-profile  [user]="contact" >
              </app-profile>
           <div *ngIf="contact.comentarios && contact.comentarios[0]  " > 
            <strong>Comentario:</strong><br>
            {{contact.comentarios[0]}}
           </div>
            </div>
          </div>
     
      </div>
  
      </div>
    </div>
    <!-- <div class="col-md-6" *ngIf="!user.nit" >
      <div class="row justify-content-center" >
        <h1>Empresas de interés</h1>
        <div  class="col-md-6 col-6" *ngFor="let contact of companies"  >
          <app-profile  [user]="contact" >
          </app-profile>
      </div>
      </div>
    </div> -->
  
  </div>