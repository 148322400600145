
<div class="fondo" >
 

    <div class="row justify-content-center mt-3 "  >
    <div class="col-12" >
       <div *ngIf="!file" >
        <h2>Los datos se deben agregar siguiendo este orden</h2>
        <div class="tabla">
          <table >
              <tr  >
                  
                  <th>Nombre</th>
                    <th>Correo</th>
                    <th>documento</th>
                    <th>Teléfono</th>
                    <th>Cargo</th>

          
              
              </tr>
            
            
              </table>
        </div>
    
        <!-- <h2>Por favor cargue el archivo de usuarios</h2>
        <span style="color: white; font-size: 22px;" >Selecione el delimitador  </span> <select style="font-size: 22px;" (change)="onChange($event.target.value)"  > 
    <option value=",">,</option>
    <option value=";">;</option>
        </select> <br><br>
        <input type="file" #csvReader name="Upload CSV" id="txtFileUpload" (change)="fileChangeListener($event)" accept=".csv" />    
        <br> -->
   
    <h2>Copie los datos desde su hoja de calculo</h2>
    <textarea id="marcauno" autocomplete="off" #marcauno cols="80" rows="5"></textarea>
    <button  type="button" (click)="marcaruno()" > Agregar</button>
       </div> 
    
       <div *ngIf="file" >
       
          <h2> Los datos de su archivo son:</h2>
          <div class="tabla">
            <table >
                <tr  >
                    
                    <th>Nombre</th>
                 
                      <th>Correo</th>
                      <th>id</th>
                      <th>Telefono</th>
                      <th>Cargo</th>
                    
                      <th>Estado</th>
                
                
                </tr>
              
                <tr *ngFor="let usr of csvRecords" >
                    
                    <th  style="font-weight: lighter;" >{{usr.datos[0]}}</th>
                      <th  style="font-weight: lighter;" >{{usr.datos[1]}}</th>
                      <th  style="font-weight: lighter;" >{{usr.datos[2]}}</th>
                      <th  style="font-weight: lighter;" >{{usr.datos[3]}}</th>
                      <th  style="font-weight: lighter;" >{{usr.datos[4]}}</th>
               
                      <th  style="font-weight: lighter;" >{{usr.estado}}</th>
                   
                
                
                </tr>
                </table>
          </div>
      
            <h2> Por favor asegurese de que los datos sean correctos y esten en la columna adecuada</h2>
    <br>
     <button  *ngIf="!cargando && !cargados" class="btn btn-primary" (click)="cargar()"  >Cargar Usuarios</button>
    <h2 *ngIf="cargando" >Cargando ...</h2>
    <button *ngIf="cargados" class="btn btn-primary" (click)="exit()"  >Aceptar</button>
       </div>
    </div>
    </div>
    </div>