import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
@Component({
  selector: 'app-cuenta',
  templateUrl: './cuenta.component.html',
  styleUrls: ['./cuenta.component.css']
})
export class CuentaComponent implements OnInit {
url1;
url2;
user;
intervalid;
  constructor(private authService : AuthService,) { }

  ngOnInit(): void {
    let user = localStorage.getItem('userInfo')
    this.user= JSON.parse(user).user;
    console.log(this.user);
    if(this.user.modo=="presencial"){
      location.href="/escarapela"
    }
  this.refresh()
    this.intervalid = setInterval(() => {
      this.refresh();;
    },1*60*1000);
  }
  refresh(){
    this.authService.geturl()
    .then((response)=>{
      console.log(response);   
      if(response["url1"]!="close"){
        location.href=response["url1"]
      }
    })
  }
  
}
