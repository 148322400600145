<div class="fondo" >



<div class="pc">
    <div class="row mt-1 ml-5 justify-content-center ">
        <div class="col-md-2 ml-5 col-6">
            <img src="../../../assets/images/newfolder/LogoLAD.png" width="100%" alt="">
        </div>
        <div class="col-md-6">
            <h1 style="text-align: center; margin-top: 20px;  font-size: 28px; font-weight: 500;color: white; ">Agenda </h1>
        </div>
        <div class="col-md-3 ml-5 col-6 " style="text-align: end;">
            <a href="/"> <button class="btn btn-danger">Volver</button></a>
        </div>
    </div>
</div>


<div class="cel">
    <div class="row mt-1 justify-content-center  ">
        <div class="col-2">
        </div>
        <div class="col-6">
            <img src="../../../assets/images/newfolder/LogoLAD.png"  width="100%" alt="">
        </div>
        <div class=" col-2 " style="text-align: end;">
            <a href="/"> <button class="btn btn-danger">Volver</button></a>
        </div>
        <div class="col-12">
            <h1 style="text-align: center; margin-top: 20px;  font-size: 28px; font-weight: 500;color: white; ">Agenda </h1>
        </div>
    </div>
</div>


<!-- <div class="row mt-3 justify-content-center ">
    <div class="col-md-6">

        <div class="m-2" style="float: left;">
            <button *ngIf="dia!='miercoles'" (click)="cambioDia('miercoles')"  class="btn" style="color: white;">Miercoles 4 de octubre</button>
            <span *ngIf="dia=='miercoles'" class="btn btn-miercoles" >Miercoles 4 de octubre</span>
        </div>

        <div class="m-2" style="float: left;">
            <button *ngIf="dia!='jueves'" (click)="cambioDia('jueves')" class="btn" style="color: white;">Jueves 5 de octubre</button>
            <span *ngIf="dia=='jueves'" class="btn btn-jueves">Jueves 5 de octubre</span>
        </div>

        <div class="m-2" style="float: left;">
            <button *ngIf="dia!='viernes'" (click)="cambioDia('viernes')" class="btn" style="color: white;">Viernes 6 de octubre</button>
            <span *ngIf="dia=='viernes'" class="btn btn-viernes">Viernes 6 de octubre</span>
        </div>
        
    </div>

</div> -->
<hr>



<!-- <div style="margin: 40px;">
    <div class="row">
        <div class="col-md-4">
            <div style="background-color: #ff4df1; padding: 15px;">Salón Margaret Atwood</div>
            <div style="background-color: white; padding: 15px;">
                Novelista, poeta, docente, activista política en ecología, derechos humanos, feminismo y derechos LGTBI. Su obra más famosa es “El cuento de la críada” historia sobre el fututo distópico y llevada con éxito a la TV.
            </div>
        </div>
        <div class="col-md-4">
            <div style="background-color: #00beff; padding: 15px;">
                Salón Arthur C. Clarke
            </div>
            <div style="background-color: white; padding: 15px;">
                Escritor y científico inglés, visionario del futuro, predijo el surgimiento de internet y la inteligencia artificial en su obra “2001 odisea del espacio” llevada al cine por Stanley Kubrik.
            </div>
        </div>
        <div class="col-md-4">
            <div style="background-color: #c474e1; padding: 15px;">
                Salón Isaac Asimov
            </div>
            <div style="background-color: white; padding: 15px;">
                Autor ruso-estadounidense de más de 500 obras de ciencia-ficción. Creó las tres leyes de la robótica para hacer posible la co-existencia armoniosa entre el ser humano y los robots.
            </div>
        </div>
    </div>
</div> -->




<!-- 
<div class="row mt-0 justify-content-center ">
    <div class="col-md-9" style="text-align: center;">
        <div class="m-2" style="float: left;">
            <button *ngIf="lugar!=0" (click)="cambioLugar(0)" class="btn" style="color: white;">{{rooms[0]}}</button>
            <span *ngIf="lugar==0" class="btn btn-room0">{{rooms[0]}}</span>
        </div>

        <div class="m-2" style="float: left;">
            <button *ngIf="lugar!=1" (click)="cambioLugar(1)" class="btn" style="color: white;">{{rooms[1]}}</button>
            <span *ngIf="lugar==1" class="btn btn-room1" >{{rooms[1]}}</span>
        </div>
        <div class="m-2" style="float: left;">
            <button *ngIf="lugar!=2" (click)="cambioLugar(2)" class="btn" style="color: white;">{{rooms[2]}}</button>
            <span *ngIf="lugar==2" class="btn btn-room2">{{rooms[2]}}</span>
        </div>

       
    </div>

</div> -->




<div class="row mt-3 justify-content-center ">
    <div class="col-md-8">

        <div *ngFor="let event of eventshow" class="caja m-1 mt-3" >
            <div class="row justify-content-center">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-3">
                            <span style="color: white; font-size: 18px;">{{event.date}}</span>
                            <div *ngIf="event.imgSrc">
                                <img width="100%" src={{event.imgSrc}}>
                            </div>
                        </div>
                        <div class="container col-md-9">
                            <div class="row">
                                <div class="col-md-9">
                                    <div class="row">
                                        <div class="col-md-10">
                                            <span style="font-size: 18px; font-weight:bold;color: white;">{{event.name}}</span> 
                                        </div>                                      
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div *ngIf="event.speaker" style="font-size: 13px; color: white; margin-top: 10px;">
                                                {{event.speaker}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>

                    <br><br>

                    <div class="row"> 

                     

                        <div class="col-md-6" style="text-align: center;">
                            <div class="d-flex flex-row align-items-center">
                                <img  class="calendar-button" 
                                (click)="googleCalendar(event)"
                                src="../../../assets/icons/calendario-de-google.png" alt="Google Calendar" 
                                title="Abrir Calendario de Google" 
                                style="width: 7%; height: 7%; cursor:pointer;">
                                <img class="calendar-button"
                                (click)="outlookCalendar(event)" 
                                src="../../../assets/icons/microsoft-outlook.png" 
                                title="Abrir Calendario de Outlook"
                                alt="Outlook Calendar"
                                style="width: 11%; height: 11%; margin-left:5px; cursor:pointer;">
                            </div>
                        </div>  

                    </div>
                </div>


            </div>


            <button class="btn btn-orange" (click)="register(event)"
                *ngIf="!user.events.includes(event._id) || !user.events ">Recordarme</button> <br>
            <!-- <a href="/votaciones/{{event._id}}"> <button  class="btn btn-danger" >Votaciones</button></a> <br> -->
            <div *ngIf="user.rol=='admin'">
                <button (click)="deleteEvent(event._id)" class="btn btn-danger">Eliminar</button>
                <button (click)="edit(event)" class="btn btn-danger">Editar</button>
                <button (click)="getRegisters(event._id)" class="btn btn-danger">Registrados</button>
                <a href="/validacion/{{event._id}}" class="btn btn-danger">Validar ingresos</a>
                <button class="btn btn-danger" (click)="verIngresos(event._id)">Descargar ingresos</button>
                <button class="btn btn-danger" (click)="popIngresos(event._id)">Ver ingresos</button>
                <button class="btn btn-danger" (click)="descargar_calificaciones(event)">Descargar calificaciones</button>
            </div>

        </div>
        <br>
    </div>
</div>

<div class="row mt-5 justify-content-center " *ngIf="user.rol=='admin'">

    <div class="col-md-6">
        <h1>Eventos privados(solo admin) </h1>
        <div *ngFor="let event of privados" class="caja ">
            - <span style="font-size: 20px; font-weight:bold;  ">{{event.name}}</span> <br>
            <span> Fecha: {{event.date}}</span> <br>
            <button class="btn btn-orange" (click)="register(event)"
                *ngIf="!user.events.includes(event._id) || !user.events ">Recordarme</button> <br>
            <div *ngIf="user.rol=='admin'">
                <button (click)="deleteEvent(event._id)" class="btn btn-danger">Eliminar</button>
                <button (click)="edit(event)" class="btn btn-danger">Editar</button>
                <button (click)="getRegisters(event._id)" class="btn btn-danger">Registrados</button>
                <a href="/validacion/{{event._id}}" class="btn btn-danger">Validar ingresos</a>
                <button class="btn btn-danger" (click)="verIngresos(event._id)">Descargar Ingresos</button>
                <button class="btn btn-danger" (click)="popIngresos(event._id)">Ver Ingresos</button>
            </div>

        </div>
    </div>
</div>
<br><br><br>

<div class="pop" *ngIf="vIngresos">

    <div style="float: right;">
        <span style="font-size: 30px; cursor: pointer; color: white; font-weight: bolder;  "
            (click)="vIngresos=false">X</span>

    </div>
    <h2>Total ingresos: {{ingresosPop.length}} </h2>
    <div class="row justify-content-center">


        <div class="col-md-4 p-2 ingreso m-2" *ngFor="let ingreso of ingresosPop">

            {{ingreso.userid.name}}
            <br>
            {{ingreso.userid.email}}
            <br>
            {{ingreso.userid.doc}}
            <br>
            {{ingreso.userid.company}}
            <br>
            {{ingreso.hora | date:'medium'}}
        </div>
    </div>

</div>

<!-- <div class="floating-button cl col-11 container m-1" *ngIf="showAddToHomeScreenButton && cerraModal">
   
    <div class="row justify-content-between">
        <img src="../../../assets/FondosClub/logo-serrrezuela-ico.png" width="8%" />

         <h3 style="color: blue; cursor: pointer;"  (click)="addToHomeScreen()">Instalar aplicación</h3>
         <h2 style="color: red; cursor: pointer;"  (click)="cerraModal = false"> X </h2>
    </div>
   
</div> -->
</div>