
<div class="fondo" >


<div class="row justify-content-center" >
    <div class="col-md-6" >
        <h1>Ingreso {{event.name}}</h1>
    </div>
</div>
<div   *ngIf="!data" class="row mt-2 justify-content-center">
    <div class="col-md-6 col-8" style="text-align: center;" >
        <select #listcameras (change)="selectedCamera(listcameras.value)">
            <option value="">-</option>
            <option *ngFor="let camera of cameras" value="{{camera.id}}">{{camera.label}}</option>
        </select>
        
        <video  playsinline  #video1  width="80%" height="auto" ></video>
    </div>
    <div class="col-md-4 col-8" >
        <form  [formGroup]="ipersonForm" (ngSubmit)="ingresop()">
            <div class="form-group">
                <h1>Validar Ingreso</h1>
                <input class="form-control" type="text" (change)="changeInput($event)" placeholder="número de identificación" formControlName="id" [ngClass]="{ 'is-invalid': submitted && f4.id.errors }">
                <div *ngIf="submitted && f4.id.errors" class="invalid-feedback ">
                    <div *ngIf="f4.id.errors.required">ingrese su documento</div>
                   
                </div> 
            </div>
            <input type="submit" value="Ingresar" class="btn btn-info">
        
        </form>
    </div>
    </div>
    <div   *ngIf="data" class="row mt-2 justify-content-center">
        <div class="col-md-4 col-7"  > 
            <app-profile  [user]="manilla" >
            </app-profile>
            <br>
            <div  style="font-size: 20px; text-align: center; color: white; ">
                Entrada:
                <span *ngIf="manilla.ticket.type=='1'" >Presencial</span>
                <span *ngIf="manilla.ticket.type=='2'  ">virtual</span>
                <span *ngIf="manilla.ticket.type=='3'" >Montaje</span>
                <span *ngIf="manilla.ticket.type=='4'" >Expositor</span>
                <span *ngIf="manilla.ticket.type=='5'" >Logistica</span>
                <span *ngIf="manilla.ticket.type=='6'" >Prensa</span>
                <span *ngIf="manilla.ticket.type=='7'" >Conferencistas- Directivos +CTG</span>
                <span *ngIf="manilla.ticket.type=='8'" >Directivos rueda negocios</span>
                <span *ngIf="manilla.ticket.type=='9'" >Asistentes rueda negocios</span>
                <span *ngIf="manilla.ticket.type=='10'" >Artistas</span>
                <span *ngIf="manilla.ticket.type=='11'" >Asistente Artista</span>
                <span *ngIf="manilla.ticket.type=='12'" >Montaje VIP</span>
                <br>
               
            </div>
       <br><br>
            <div style="text-align: center;">
                <button  *ngIf="manilla.ticket.ready" class="btn btn-primary m-2" (click)="download2()" >Validar e imprimir1 (pdf)</button>
                <br>
                
                <button  *ngIf="manilla.ticket.ready" class="btn btn-primary m-2" (click)="download()" >Validar e imprimir</button>
                <br>
                <button  *ngIf="manilla.ticket.ready"class="btn btn-primary m-2" (click)="termina()" >Solo validar</button>
                <br><br>
              
                <span *ngIf="!manilla.ticket.ready" style="font-size: 20px; text-align: center; color: white; ">No aprobado</span>
                <br>
                <button   class="btn btn-primary" (click)="cancelar()"  >Cancelar</button>
            </div>
            </div>
    </div>

</div>

<div class="esc" *ngIf="mostrarImp"   id="escarapela" #escarapela > 
       
    <app-impresion style="   width:9cm ;
    height: 7cm;" [user]="manilla" ></app-impresion>
</div>