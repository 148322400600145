<div class="fondo">

  <div class="row justify-content-center "
  style="text-align: center;">
  <div class="col-md-2 ">
  </div>
  <div class="col-md-6 mt-5 mb-3 col-6 ">
      <img  src="../../../assets/images/newfolder/LogoLAD.png" width="50%" alt="">
      <br><br>
      <h1 style="color: white; font-size:210%">Un evento para quienes ven en 360</h1>
  </div>
  <div class="col-md-2">
  </div>
</div>

      <div class="row mt-3 justify-content-center">
        <div class="col-md-4 caja ">
            <div class=" form-signin">
                
                <div class="row justify-content-center">
                    <form class="col-md-8" [formGroup]="miFormulario" (ngSubmit)="login()">
                        <br>
                        <label
                            style="font-size: 15px; text-align: left;color: #ffffff;">Ingrese su documento*</label>
                        <div class="form-group mt-3">
                            <input class="form-control" type="text"
                                placeholder=""
                                formControlName="correo"
                                [ngClass]="{ 'is-invalid': submitted && f.correo.errors }">
                            <div *ngIf="submitted && f.correo.errors"
                                class="invalid-feedback ">
                                <div *ngIf="f.correo.errors.required">ingrese su
                                    cedula</div>
                            </div>
                            <br><br>
                            <input type="submit" value="Ingresar"
                                class=" btn btn-orange mt-3 botonLogi w-100">
                                <br><br>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    </div>

    <div class="fixed-bottom">
      <img class="imag" src="../../../assets/images/newfolder/Footer.png">
  </div>

  <div
    class="mt-3 row justify-content-center bg-white p-1 pb-1"
    style="display: none">
    <!-- <div class="col-md-2 col-5 p-2" *ngFor="let pat of patrocinadores">
            <img src="../../../assets/patrocinadores/{{pat}}" class="pat" width="100%" alt="">
        </div> -->

    <!--Carousel Wrapper-->
 
  </div>
</div>
