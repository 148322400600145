
<div class="lienzo" >


<div class="row mt-3 justify-content-center">
    <div class="col-md-2">
        <button *ngIf="filteri!=0" class="btn btn-primary"
            (click)="filterUsers(0)">Ver todos</button>
        <h2 *ngIf="filteri==0">Todos</h2>
    </div>
    <div class="col-md-2">
        <button *ngIf="filteri!=1" class="btn btn-primary"
            (click)="filterUsers(1)">Ver Por aproabar</button>
        <h2 *ngIf="filteri==1">Por aproabar</h2>
    </div>
    <div class="col-md-2">
        <button *ngIf="filteri!=2" class="btn btn-primary"
            (click)="filterUsers(2)">Ver ya ingresaron</button>
        <h2 *ngIf="filteri==2">ya ingresaron</h2>
    </div>
    <div class="col-md-2">
        <button *ngIf="filteri!=3" class="btn btn-primary"
            (click)="filterUsers(3)">Ver No ingresaron</button>
        <h2 *ngIf="filteri==3">No ingresaron</h2>
    </div>
    <div class="col-md-2">
        <button *ngIf="filteri!=4" class="btn btn-primary"
            (click)="filterUsers(4)">Montaje</button>
        <h2 *ngIf="filteri==4">Montaje</h2>
    </div>
    <div class="col-md-2">
        <button *ngIf="filteri!=5" class="btn btn-primary"
            (click)="filterUsers(5)">Expositores</button>
        <h2 *ngIf="filteri==5">Expositores</h2>
    </div>
</div>
<div class="row justify-content-center">
    <div class="col-11">
        <h1>Usuarios: {{filtro().length}}</h1>
        <button class="btn btn-primary" (click)="descargar()">Descargar</button>
    </div>
    <div class="col-11">
        
        <button class="btn btn-primary" (click)="descargarCertificado()">Descargar certificados</button>
    </div>
    <div class="col-md-5"> 
    <div class="input-group">
        <input type="text" class="form-control" [(ngModel)]="idFiltro" (keyup)="filtrarUsuarios()" placeholder="Buscar por Nombre o Documento">
    </div>
</div>
</div>




<div *ngIf="seting" class="pop text-white w-50 container mt-5"
    style="position: fixed; top: 20%; left:25%;">
    {{c_user.name}}, cc: {{c_user.id}}, email: {{c_user.correo}}
    <form [formGroup]="rolForm" (ngSubmit)="setRols()" novalidate>
        <div class="form-group">
            Rol
            <select formControlName="rol">
                <option value="usuario">usuario</option>
                <option value="admin">admin</option>
                <option value="conferencista">conferencista</option>

            </select>
        </div>

        <input type="submit" value="Fijar">
    </form>
</div>

<div *ngIf="aporbarTicket" class="pop text-white w-50 container mt-5"
    style="position: fixed; top: 20%; left:25%;">
    <div class="row justify-content-center">
        <div class="col-md-11">
            <h2> {{sTicket.user.name}} - {{sTicket.user.doc}}</h2>
            <h3>Seleccione el paquete de ingreso con el que desea aprobar al
                usuario</h3>
            <br>
            <select (change)="aprobar2($event)" class="form-control">
                <option value>-Seleccionar paquete-</option>
                <option value="1">Presencial</option>
                <option value="2">Virtual</option>
                <!-- <option value="3">Montaje</option>
                <option value="4">Expositor</option>
                <option value="5">Logística</option>
                <option value="6">Prensa</option>
                <option value="7">Conferencistas- Directivos +CTG</option>
                <option value="8">Directivos rueda negocios</option>
                <option value="9">Asistentes rueda negocios</option>
                <option value="10">Artistas</option>
                <option value="11">Asistente Artista</option>
                <option value="12">Montaje VIP</option> -->
            </select>
            <br>
            <button class="btn btn-danger" (click)="aporbarTicket=false">Cancelar</button>
        </div>
    </div>

</div>

<div *ngIf="editarTicket" class="pop text-white w-50 container mt-5"
    style="position: fixed; top: 20%; left:25%;">
    <div class="row justify-content-center">
        <div class="col-md-11">
            <h2> {{sTicket.user.name}} - {{sTicket.user.doc}}</h2>
            <h3>Seleccione el paquete de ingreso con el que desea aprobar al
                usuario</h3>
            <br>
            <select (change)="editarticket($event)" class="form-control">
                <option value>-Seleccionar paquete-</option>
                <option value="1">Presencial</option>
                <option value="2">Virtual</option>
                <!-- <option value="3">Montaje</option>
                <option value="4">Expositor</option>
                <option value="5">Logística</option>
                <option value="6">Prensa</option>
                <option value="7">Conferencistas- Directivos +CTG</option>
                <option value="8">Directivos rueda negocios</option>
                <option value="9">Asistentes rueda negocios</option>
                <option value="10">Artistas</option>
                <option value="11">Asistente Artista</option>
                <option value="12">Montaje VIP</option> -->
            </select>
            <br>
            <button class="btn btn-danger" (click)="editarTicket=false">Cancelar</button>
        </div>
    </div>

</div>

<div *ngIf="editUser" class="pop text-white w-50 container mt-5"
    style="position: fixed; top: 5%; left:25%;">
    <div class="row justify-content-center">
        <div class="col-md-11">
            <div class="caja_formulario">

                <form [formGroup]="userForm" (ngSubmit)="edit()">
                    <div class="form-group">
                        <label>Nombre:</label>
                        <input type="text" class="form-control"
                            placeholder="Nombre" formControlName="name"
                            [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                        <div *ngIf="submitted && f.name.errors"
                            class="invalid-feedback">
                            <div *ngIf="f.name.errors.required">Ingrese su
                                nombre</div>
                            <div *ngIf="f.name.errors.minlength">El nombre debe
                                tener al menos 3 caracteres</div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label style="color: white; margin-bottom:1px; ">Empresa:</label>
                        <input class="form-control" type="text"
                            formControlName="company"
                            [ngClass]="{ 'is-invalid': submitted && f.company.errors }">
                        <div *ngIf="submitted && f.company.errors"
                            class="invalid-feedback ">
                            <div *ngIf="f.company.errors.required">ingrese su
                                empresa</div>

                        </div>
                    </div>

                    <div class="form-group">
                        <label style="color: white; margin-bottom:1px; ">Cargo:</label>
                        <input class="form-control" type="text"
                            formControlName="position"
                            [ngClass]="{ 'is-invalid': submitted && f.position.errors }">
                        <div *ngIf="submitted && f.position.errors"
                            class="invalid-feedback ">
                            <div *ngIf="f.position.errors.required">ingrese su
                                cargo</div>

                        </div>
                    </div>
                    <div class="form-group">
                        <label style="color: white; margin-bottom:1px; ">Celular:</label>
                        <input class="form-control" type="text"
                            formControlName="phone"
                            [ngClass]="{ 'is-invalid': submitted && f.phone.errors }">
                        <div *ngIf="submitted && f.phone.errors"
                            class="invalid-feedback ">
                            <div *ngIf="f.phone.errors.required">ingrese su
                                celular</div>

                        </div>
                    </div>

                    <div class="form-group">
                        <label style="color: white; margin-bottom:1px; ">Correo
                            electrónico:</label>
                        <input class="form-control" type="email"
                            formControlName="email"
                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                        <div *ngIf="submitted && f.email.errors"
                            class="invalid-feedback ">
                            <div *ngIf="f.email.errors.required">ingrese su
                                email</div>
                            <div *ngIf="f.email.errors.email">ingrese un correo
                                valido</div>
                        </div>
                    </div>

                    <input type="submit" value="Confirmar"
                        class="btn btn-orange">

                </form>
                <button class="btn btn-danger" (click)="editUser=false;">Cancelar</button>
            </div>
        </div>
    </div>

</div>
<br>
<br>

<div class="bg-dark text-white" style="overflow-x: auto;">
    <table class="w-100 bg-dark text-white">
        <tr >
            <th>Cambiar rol</th>
            <th>Aprobar</th>
            <th>Nombre</th>
            <th>Correo</th>
            <th>id</th>

            <th>organizacion</th>
            <th>cargo</th>
            <th>telefono</th>
            <th>rol</th>
            <th>entrada</th>
            <th>ingreso</th>

            <th>Eliminar</th>

        </tr>

        <tr *ngFor="let usr of filtrarUsuarios();" >

            <td>
                <button class="btn btn-primary p-0" (click)="rol(usr.user)">Cambiar
                    Rol</button>
            </td>
            <td>
                <button *ngIf="!usr.ready" (click)="aprobar(usr)"
                    class="btn btn-primary">Aprobar</button>
                <div *ngIf="usr.ready">Aprobado <button (click)="editar(usr)"
                        class="btn p-0"><i class="bi bi-gear-wide"></i></button>
                </div>
            </td>
            <td (click)="editarUser(usr.user)">{{usr.user.name | uppercase}} {{usr.user.name2 | uppercase}}{{usr.user.lname | uppercase}}</td>
            <td
                style=" font-size: 13px; max-width: 85px; overflow-wrap: break-word;">{{usr.user.email}}</td>
            <td style="color: red;">{{usr.user.doc}}</td>

            <td
                style=" font-size: 13px; max-width: 55px; overflow-wrap: break-word;">{{usr.user.company}}
                <a *ngIf="usr.user.empresa"
                    href="/empresap/{{usr.user.empresa}}" target="_blank"
                    rel="noopener noreferrer">ver</a>
            </td>
            <td
                style=" font-size: 13px; max-width: 85px; overflow-wrap: break-word;">{{usr.user.position}}</td>
            <td>{{usr.user.phone}}</td>
            <td>{{usr.user.rol}}</td>
            <td>

                <span *ngIf="usr.type=='1'">Presencial</span>
                <span *ngIf="usr.type=='2'">Virtual</span>
                <!-- <span *ngIf="usr.type=='2' && !usr.buyfor ">270</span>
                <span *ngIf="usr.type=='3'">Montaje</span>
                <span *ngIf="usr.type=='4'">Expositor</span>
                <span *ngIf="usr.type=='5'">Logistica</span>
                <span *ngIf="usr.type=='6'">Prensa</span>
                <span *ngIf="usr.type=='7'">Conferencistas- Directivos +CTG</span>
                <span *ngIf="usr.type=='8'">Directivos rueda negocios</span>
                <span *ngIf="usr.type=='9'">Asistentes rueda negocios</span>
                <span *ngIf="usr.type=='10'">Artistas</span>
                <span *ngIf="usr.type=='11'">Asistente Artista</span>
                <span *ngIf="usr.type=='12'">Montaje VIP</span> -->
            </td>
            <td>
                <i *ngIf="usr.user.login" class="bi bi-check-circle-fill"
                    style="color: green;"></i>
            </td>

            <td>
                <button class="btn btn-danger" (click)="delete(usr)"><i
                        class="bi bi-trash"></i></button>
            </td>

        </tr>
    </table>
</div>
</div>