import { Component, OnInit } from '@angular/core';
import { EmpresaService } from "../../services/empresa.service";

@Component({
  selector: 'app-empresas',
  templateUrl: './empresas.component.html',
  styleUrls: ['./empresas.component.css']
})
export class EmpresasComponent implements OnInit {

  empresas;
  constructor(private empresaService:EmpresaService,
    ) { }

  ngOnInit(): void {
    this.getEmpresas();
  }


  getEmpresas(){
  
    this.empresaService.getempresas()
    .then((response) => {
      console.log(response);
      this.empresas=response;
      
    })
    .catch((err)=>{
    })
  }

}
