<div class="container">
    <h2>Formulario</h2>
    <form (ngSubmit)="onSubmit()" #form="ngForm">
      <div class="mb-3">
        <label for="titulo" class="form-label">Título</label>
        <input
          type="text"
          class="form-control"
          id="titulo"
          name="titulo"
          [(ngModel)]="formData.titulo"
          required
          [class.is-invalid]="form.submitted && !form.controls.titulo.valid"
        />
      </div>
      <div class="mb-3">
        <label for="contenido" class="form-label">Contenido</label>
        <textarea
          class="form-control"
          id="contenido"
          name="contenido"
          [(ngModel)]="formData.contenido"
          required
          [class.is-invalid]="form.submitted && !form.controls.contenido.valid"
        ></textarea>
      </div>
      <button type="submit" class="btn btn-primary">Enviar</button>
    </form>
  </div>