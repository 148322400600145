import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { WhatsappService } from "../../services/whatsapp.service";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import  Swal from 'sweetalert2'; 
import { first } from 'rxjs/operators';
import { Theme } from 'openvidu-angular/lib/shared/types/webcomponent-config';
import { textChangeRangeIsUnchanged } from 'typescript';
@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css']
})
export class MessagesComponent implements OnInit {
  messageForm: FormGroup;
submitted;
  constructor(private authService : AuthService, private router : Router,
    private whatsappService:WhatsappService) { }

  ngOnInit(): void {
    this.messageForm = new FormGroup({
      'number': new FormControl('', [
        Validators.required,
       
      ]),
      'text': new FormControl('', [
        Validators.required,
       
      ]),
    })
  }
  get f() { return this.messageForm.controls; }
  

newMessage(){
  this.submitted=true;
  console.log(this.messageForm.value)
  if(this.messageForm.invalid){
    return
  }
  else{
    this.whatsappService.newMessage(this.messageForm.controls["text"].value,this.messageForm.controls["number"].value)
   .then(()=>{
    Swal.fire("Enviado con exito")
    this.messageForm.reset()
   })
  }
}

}
