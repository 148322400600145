<div class="m-1" >
    <div class="pc">
        <div class="row mt-1 ml-5 justify-content-center ">
            <div class="col-md-2 ml-5 col-6">
                <img src="../../../assets/images/newfolder/LogoLAD.png" width="100%" alt="">
            </div>
            <div class="col-md-6">
                <h1 style="text-align: center; margin-top: 20px;  font-size: 28px; font-weight: 500; ">Lanza tu idea para +CTG2024 </h1>
            </div>
            <div class="col-md-3 ml-5 col-6 " style="text-align: end;">
                <a routerLink="/"> <button class="btn btn-danger">Volver</button></a>
            </div>
        </div>
    </div>
    
    
    <div class="cel">
        <div class="row mt-1 justify-content-center  ">
            <div class="col-2">
            </div>
            <div class="col-6">
                <img src="../../../assets/images/newfolder/LogoLAD.png"  width="100%" alt="">
            </div>
            <div class=" col-2 " style="text-align: end;">
                <a routerLink="/"> <button class="btn btn-danger">Volver</button></a>
            </div>
            <div class="col-12">
                <h1 style="text-align: center; margin-top: 20px;  font-size: 28px; font-weight: 500; ">Lanza tu idea para +CTG2024 </h1>
            </div>
        </div>
    </div>
    <div class="row justify-content-center mt-5  ">
        <div class="col-md-5">
            <div class=" caja_formulario form-signin mt-5" style="padding: 5px;" >

                <form [formGroup]="comentarioForm" (ngSubmit)="newComentario()" novalidate="">

                    <div class="form-floating">

                        <textarea class="form-control" cols="30" rows="5"
                        placeholder="Lanza tu idea para +CTG2024"
                        formControlName="text" 
                        [ngClass]="{ 'is-invalid': submitted && f.text.errors }"
                        ></textarea>

                       
                        <div *ngIf="submitted && f.text.errors" class="invalid-feedback ">
                            <div *ngIf="f.text.errors.required">ingrese un comentario</div>

                        </div>

                    </div>


                    <input type="submit" value="Enviar" class="w-100 btn btn-orange mt-3">


                </form>

            </div>
        </div>
    </div>
    <div class="row justify-content-center  bg-white "   *ngIf="user.rol=='admin'" >
        <div class="col-md-10">
            <table class="table table-striped" >
                <thead>
                   <tr>
                    <th>
                        #
                    </th>
                    <th>
                       Usuario
                    </th>
                    <th>
                        Comentario
                    </th>
                    <th>
                        Hora
                    </th>
                   </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let comentario of comentarios; let i = index" >
                        <td>
                            {{i+1}}
                        </td>
                        <td>
                            {{comentario.user.name}}
                            <br>
                            {{comentario.user.email}}

                        </td>
                        <td>
                            {{comentario.text}}
                        </td>
                        <td>
                            {{comentario.createdDate | date:'yyyy-MM-dd HH:mm'    }}
                        </td>
                    </tr>
                </tbody>

            </table>
        </div>
    </div>
    
</div>