import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { EventsService } from '../../services/events.service';
import { ActivatedRoute } from "@angular/router";
import { Router } from '@angular/router';
import {SocketioService} from "../../services/socketio.service";
import { Subscription } from 'rxjs';
import { saveAs } from 'file-saver';


@Component({
  selector: 'app-votaciones',
  templateUrl: './votaciones.component.html',
  styleUrls: ['./votaciones.component.css']
})
export class VotacionesComponent implements OnInit {
  intervalid;
  questions=[];
  pop: boolean;
  pull: any;
  type = 'PieChart';
id;
  columnNames = ['Votos', 'Porcentage'];
  options = {    
  };
  width = 550;
  height = 400;
    constructor(  
      private eventService : EventsService, 
      private route: ActivatedRoute, private router : Router,
      private socketservice: SocketioService,
      private renderer: Renderer2,
      private elRef:ElementRef) { }
  
    ngOnInit(): void {
      let id=this.route.snapshot.paramMap.get("id")
      this.id=id;
  this.loadquestions(id);
  this.intervalid = setInterval(() => {
    this.loadquestions(id);
  }, 150000);
    
    }
    ngOnDestroy() {
      if (this.intervalid) {
        clearInterval(this.intervalid);
      }
    }
    loadquestions(id){
      this.eventService.pulls(id)
    .then((response) => {
      console.log(response);

  var ques=[];
   response["results"].forEach(element => {
     var va=0;
     var vb=0;
     var vc=0;
     var vd=0;
     element.votos.forEach(voto => {
       if(voto.voto=="a"){va=va+1}
       if(voto.voto=="b"){vb=vb+1}
       if(voto.voto=="c"){vc=vc+1}
       if(voto.voto=="d"){vd=vd+1}
     });
     var  data = [
      ['a', va],
      ['b', vb],
      ['c', vc],
      ['d', vd],
   ];
    var question={
title: element.pregunta,
data: data,

    }
console.log("ques: "+question);
ques.push(question);
});
   this.questions=ques.reverse();

    })
    .catch((err)=>{
      console.log(err);
    })
  } 

  
  downques(ques){
    console.log(ques)
    var votos=ques.votos
    console.log(votos);
    let csvContent = "data:text/csv;charset=utf-8,";
    var user=[];
    votos.forEach((rowArray)=> {
      user=[];
      user.push(rowArray.user.correo);
      user.push(rowArray.user.name);
      user.push(rowArray.vote);
      user.push(rowArray.por);
  
      console.log(user);
        let row = user.join(",");
        csvContent += row + "\r\n";
    });
    saveAs(csvContent,"resultados-"+ques.title+".csv");
  }
  
  
  closepop(){
    this.pop=false;
  this.loadquestions(this.id);
   
    }
    
    openpop(pull){
      console.log(pull);
      this.pop=true;
      this.pull=pull;
    
      }
  
   
 
  
  }
  