<div class="">
  

    <div class="row justify-content-center">
      
        <div class="col-md-4 mt-4">
            <div class="caja_formulario"> 

                <form [formGroup]="userForm" (ngSubmit)="send()" style="padding:10px;" >  
                    <div class="form-group">
                    <label>Nombre:</label>
                    <input type="text" class="form-control" placeholder="Nombre" formControlName="name"  [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                        <div *ngIf="f.name.errors.required">Ingrese su nombre</div>
                        <div *ngIf="f.name.errors.minlength">El nombre debe tener al menos 3 caracteres</div>
                    </div>
                </div> 
              
                <div class="form-group">
                    <label style=" margin-bottom:1px; "  >Empresa:</label>     
                    <input class="form-control" type="text"  formControlName="company"  [ngClass]="{ 'is-invalid': submitted && f.company.errors }">
                    <div *ngIf="submitted && f.company.errors" class="invalid-feedback ">
                        <div *ngIf="f.company.errors.required">ingrese su empresa</div>
                     
                    </div> 
                </div>

                <div class="form-group">
                    <label style=" margin-bottom:1px; "  >Cargo:</label>     
                    <input class="form-control" type="text"  formControlName="position"  [ngClass]="{ 'is-invalid': submitted && f.position.errors }">
                    <div *ngIf="submitted && f.position.errors" class="invalid-feedback ">
                        <div *ngIf="f.position.errors.required">ingrese su cargo</div>
                     
                    </div> 
                </div>
                <div class="form-group">
                 <label style=" margin-bottom:1px; "  >Celular:</label>     
                 <input class="form-control" type="text"  formControlName="phone"  [ngClass]="{ 'is-invalid': submitted && f.phone.errors }">
                 <div *ngIf="submitted && f.phone.errors" class="invalid-feedback ">
                     <div *ngIf="f.phone.errors.required">ingrese su celular</div>
                  
                 </div> 
             </div>
                 
               <div class="form-group">
                   <label style=" margin-bottom:1px; "  >Correo electrónico:</label>     
                   <input class="form-control" type="email"  formControlName="email"  [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                   <div *ngIf="submitted && f.email.errors" class="invalid-feedback ">
                       <div *ngIf="f.email.errors.required">ingrese su email</div>
                       <div *ngIf="f.email.errors.email">ingrese un correo valido</div>
                   </div> 
               </div>
               
               
              
              
                
                
                
                <div class="row justify-content-center">

                    <input type="submit" value="Confirmar" class="btn btn-orange">
                </div>
               
                
            </form>
                
            </div>
        </div>
  



  
</div>


