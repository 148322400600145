<div class="fondo">



<!-- <div *ngIf="user.rol=='usuario'" style="  position: fixed; bottom:10px; right: 2px; z-index: 200;  text-align: center; color: #999; padding: 10px">
    <div style="  position: absolute; bottom:0px; right: 8px;   text-align: center; color: #999; padding: 10px">
  
        <button class="btn btn-orange" type="button" id="btnChat" #btnChat (click)="openChat()" >
         <strong>Solicitar videollamada</strong> 
        </button>
    
      </div>
      
    <div #chat [style.display]="'none'"  >
        <app-chat >
        </app-chat>
        <button (click)="closeChat()" class="btn btn-danger btnChat " >Cerrar</button>
    
    </div>
</div> -->



    <div style="padding: 2px; text-align: center;">
      <div style="text-align: left; margin-left: 60px; "  >
        <h1 style="color: white; font-size: 32px; font-weight: bold;" >Bienvenido {{user.name}} </h1> 
      </div>
        

  
      
          
          <div class="row justify-content-center ml-3" *ngIf="event">
            <div *ngIf="!session" class=" col-md-8 embed-responsive embed-responsive-16by9">
                <iframe id="iframe" class="embed-responsive-item"  width="98%" frameborder="0" [src]="event.eventSrc | safe" allowfullscreen style="display: block;"></iframe>
                <div  style=" text-align: end;  position: absolute; bottom: 100px;right: 2px; z-index: 99999999;" >
<img src="{{img.src}}" alt="" [@bounceInUpOnEnter] [@bounceOutUpOnLeave]  width="30px;"  *ngFor="let img of exisemoji" >
                </div>
            </div>

          
 
            <div class=" col-md-3 ml-4" style="color: white;">
              <div >
                <button *ngIf="user.rol=='admin' && session" class="bnt btn-primary" (click)="cerrar_llamada()">Cerrar llamada</button>
                <button *ngFor="let usr of connectedUsers" class="bnt btn-primary" (click)="colgar(usr.correo)"> colgar {{usr.name}} </button>
              </div>
              <div *ngIf="!activePull" class="caja-conferencia" >
                
                

                <div>Envía una pregunta o saludo</div>
                <textarea style="border:1px solid #ffffff; width: 100%; border-radius: 5px" name="preguta" id="pregunta" cols="30" rows="4" ></textarea>
              
                <div style="justify-content: right; text-align: end; " >
                  <button (click)="sendQuestion()" class="btn btn-orange" >Enviar</button>
                </div>
              </div>
              
          
  
              <div *ngIf="!activePull" class="caja-conferencia mt-1" >

                <div class="row justify-content-center   "  >
                  <div class="col-12">
<h4  style="color: white;" >  Reacciona:</h4>
                  </div>
                </div>
                <div class="row justify-content-center   "  >
                  
                  <div class="col-2 m-0 p-0  " >
                      <img src="/assets/emojis/love.png"   (click)="emojistart('love')" width="100%" style="cursor: pointer;  border: solid black 1px; border-radius: 10px;  " alt="">
                  </div>
                  <div class="col-2 m-0 p-0 " >
                          <img src="/assets/emojis/like.png"  (click)="emojistart('like')" width="100%" style="cursor: pointer;  border: solid black 1px; border-radius: 10px; padding: 1px; " alt="">
                  </div>
  
                  <div class="col-2 m-0 p-0 " >
                        <img src="/assets/emojis/angry.png"  (click)="emojistart('angry')" width="100%" style="cursor: pointer;  border: solid black 1px; border-radius: 10px;  padding: 1px;" alt="">
                  </div>
                  <!-- <div class="col-2 m-0 p-0 " >
                    <img src="/assets/emojis/star.png"  (click)="emojistart('star')" width="100%" style="cursor: pointer;  border: solid black 1px; border-radius: 10px;  padding: 1px;" alt="">
              </div>                     -->
  
  
                </div>
            

                
              </div>
              <div  *ngIf="questions?.length && event && !activePull"  class="mt-1 caja-respuesta caja-conferencia"  id="caja-respuesta" >
                <div class="col-12" >
                  <div class="container" >
                    <div *ngFor="let question of questions">
                      <strong>-{{question.question.user.name}}:</strong>  {{question.question.question}}  
                      <br>
                      <div *ngIf="question.respuesta">Respuesta: {{question.respuesta}} </div>
                    </div>
                   
                </div>
                </div>
              
              </div>
           
  
              <!-- <div style="background-color: #461e7d; padding: 10px; text-align: left; border-radius: 15px" *ngIf="activePull">
                    <textarea disabled name="pull" id="pull" cols="30" rows="5">{{pull_txt}}</textarea><br>
                    <div style="margin-top: 10px">
                    <button (click)="pull('a')" class="btn btn-primary" >A</button> <br>
                    <button (click)="pull('b')"  class="btn btn-primary">B</button> <br>
                    <button  (click)="pull('c')"  class="btn btn-primary">C</button> <br>
                </div>


  
              </div> -->
              <div *ngIf="activePull" class="mt-4  btn-boton"  >
                <textarea  style="background-color:white; color: black;  " disabled name="pull" id="pull" cols="30" rows="5">{{pull_txt.pregunta}}</textarea><br>
                <div style="margin-top: 10px">
                  <img src="../../../assets/trivia/a.png" width="22%"  alt="A" (click)="pulltrivia('a')" style="cursor: pointer; margin-bottom: 5px;" >
                    <br>
                    <img src="../../../assets/trivia/b.png" width="22%"  alt="B" (click)="pulltrivia('b')" style="cursor: pointer;margin-bottom: 5px;" >
                    <br>
                    <img src="../../../assets/trivia/c.png" width="22%"  alt="B" (click)="pulltrivia('c')" style="cursor: pointer;margin-bottom: 5px;" >
                    <br>
                    <img src="../../../assets/trivia/d.png" width="22%"  alt="C" (click)="pulltrivia('d')" style="cursor: pointer;margin-bottom: 5px;" >
                    <br>
                </div>
              </div>
              <br>
  
  
  
              
  
  
           
             
  
            </div>
          </div>
          <br>
          <br>
    
        </div>

       
            <div *ngIf="user.rol=='admin'"  >
                <div *ngFor="let usr of existingUsrs" >
                    <div id="chat{{usr.id}}" style="display: none;">
                <app-chat  [remoteUser]="usr" >
                </app-chat>
                <button (click)="closeChatAdmin(usr.id)" class="btn btn-danger btnChat " >Cerrar</button>
                <button (click)="inviteuser(usr)" class="btn btn-danger btnCall "  >Invitar</button>
                </div>
                </div>
                </div> 
                <div *ngIf="user.rol=='admin' " class="bg-dark text-white m-2 w-50" >
                  <div class="col-md-6" style="border: 5px solid white;"  >

                    <div  *ngIf="triv" >
                    trivia enviada:
                      {{triv.pregunta}}
                    
                    </div>
                    
                      <button  class="btn btn-danger" (click)="cerrartrivias()" > Cerrar trivia </button>
                        <form [formGroup]="trivia" (ngSubmit)="newTrivia()" novalidate="">    
                            
                           
                             <div class="form-group">
                             <h3>Ingrese la pregunta'</h3>   
                             <textarea  class="form-control"  formControlName="pregunta"cols="30" rows="10"></textarea>  
                            
                             </div>
                           
                             <div class="form-group">
                              <h3>]Selecciona la opcion correcta</h3>     
                             
                              <select formControlName="correcta" required >
                                <option value="">Seleccione</option>
                                <option value="a">a</option>
                                <option value="b">b</option>
                                <option value="c">c</option>
                                <option value="d">d</option>
                      
                              </select>
                              </div>
                             
                             <input type="submit" value="Guardar" class="btn btn-info">
                        
                        </form>
                      </div>
                  
                    <textarea name="smsg" id="smsg" placeholder="Envio de mensaje" cols="10" rows="5"></textarea>
                    <br>
                    <button (click)="sendMsg()"  class="btn btn-primary m-2" >Enviar</button>
                    <button (click)="closeMsg()"  class="btn btn-danger m-2" >Cerrar</button>
                    <br>
                    <br>
                    <div class="row">
                    <a href="/preguntas/{{event._id}}" target="black">
                    <button class="btn btn-primary m-2">Preguntas</button>
                    </a>
                    <a href="/votaciones/{{event._id}}" target="black">
                        <button class="btn btn-primary m-2">Votaciones</button>
                        </a>
                        <button (click)="sendPanic()" >Recargar a todos</button>
                </div>
                <div class="row justify-content-center   "   *ngIf="user.rol=='admin'" >
                  <div class="col-1 m-0 p-0  ml-1 mr-1" >
                      <img src="/assets/emojis/love.png"    width="100%" style=" border: solid black 1px; border-radius: 10px;  " alt="">{{event.reactions.love}}
                      
                  </div>
                  <div class="col-1 m-0 p-0 ml-1 mr-1" >
                          <img src="/assets/emojis/like.png"   width="100%" style=" border: solid black 1px; border-radius: 10px; padding: 1px; " alt="">  {{event.reactions.like}}
                         
                  </div>
  
                  <div class="col-1 m-0 p-0 ml-1 mr-1" >
                        <img src="/assets/emojis/angry.png"   width="100%" style="  border: solid black 1px; border-radius: 10px;  padding: 1px;" alt="">  {{event.reactions.angry}}
                       
                  </div>
                                      
  
  
                </div>
            
                
                </div>
  





                <div class="row justify-content-center" >
                  <div class="container text-white" *ngIf="user.rol=='admin'">
                      <button (click)="downUsers()" class="btn btn-primary" >Descargar audiencia</button><br>
                      <h3>Usuarios en linea: {{existingUsrs?.length || '0' }} </h3> 
                      <ul>
                          
                  <li (click)="openChatAdmin(usr.id)" *ngFor="let usr of existingUsrs" id="li{{usr.id}}">
                  {{usr.name}}
                  </li>
                      </ul>  
                   </div>
                </div>






<div *ngIf="callactive">
    <h2>llamada activa</h2>
    <div>
        local: 
        <video id="localVideo" autoplay="true"  width="400px"></video>
    </div>
    <hr>
    <div>
        remoto:
        <video id="remoteVideo" autoplay="true" width="400px"></video>
    </div>


</div>


</div>


<audio id="audio">
    <source src='/ring.mp3' type="audio/mp3">
</audio>



  <!-- <div *ngIf="session" id="session">
    <img src="/assets/boton_ingresar.png" id="img_sesion">
    <opv-session #ovSessionComponent [sessionName]="event.name" [user]="user.name" [tokens]="tokens" [ovSettings]="ovSettings"
      (sessionCreated)="handlerSessionCreatedEvent($event)" (participantCreated)="handlerPublisherCreatedEvent($event)" (error)="handlerErrorEvent($event)">
    </opv-session>
  
 </div>  -->