<div class="row mt-1 ml-5 justify-content-center ">
  <div class="col-md-2 ml-5 col-6">
     <img  src="../../../assets/images/newfolder/LogoLAD.png"  width="100%" alt="">
  </div>
  <div class="col-md-6" >
      <h1 style="text-align: center; margin-top: 20px;  font-size: 28px; font-weight: 500; " >Mi agenda </h1>
  </div>
  <div class="col-md-3 ml-5 col-6 " style="text-align: end;" >
     <a href="/"> <button class="btn btn-danger" >Volver</button></a>
   </div>
</div>
 <div class="row mt-3 justify-content-center "   >
    <div class="col-md-6" >
           
        <div  class="m-2" style="float: left;">
            <button *ngIf="dia!='jueves'" (click)="cambioDia('jueves')" class="btn btn-orange">Jueves 27 Oct</button>
            <span *ngIf="dia=='jueves'" style="font-size: 20px; font-weight:bold; margin:10px ; color: white; "  >Jueves 27 Oct</span>
        </div>
        <div  class="m-2" style="float: left;">
            <button *ngIf="dia!='viernes'" (click)="cambioDia('viernes')" class="btn btn-orange">Viernes 28 Oct</button>
            <span *ngIf="dia=='viernes'" style="font-size: 20px; font-weight:bold; margin:10px ; color: white; "  >Viernes 28 Oct</span>
        </div>
    </div>

</div> 
 <hr>
<div class="row mt-0 justify-content-center "   >
  <div class="col-md-9" >
      <div   class="m-2" style="float: left;">
          <button *ngIf="lugar!=0" (click)="cambioLugar(0)" class="btn btn-orange">{{rooms[0]}}</button>
          <span *ngIf="lugar==0" style="font-size: 20px; font-weight:bold; margin:10px; color: white;  "  >{{rooms[0]}}</span>
      </div>
      
      <div   class="m-2"style="float: left;">
          <button *ngIf="lugar!=1" (click)="cambioLugar(1)" class="btn btn-orange">{{rooms[1]}}</button>
          <span *ngIf="lugar==1" style="font-size: 20px; font-weight:bold; margin:10px; color: white;  "  >{{rooms[1]}}</span>
      </div>
      <div  class="m-2" style="float: left;">
          <button *ngIf="lugar!=2" (click)="cambioLugar(2)" class="btn btn-orange">{{rooms[2]}}</button>
          <span *ngIf="lugar==2" style="font-size: 20px; font-weight:bold; margin:10px ; color: white; "  >{{rooms[2]}}</span>
      </div>
      <div  class="m-2" style="float: left;">
        <button *ngIf="lugar!=3" (click)="cambioLugar(3)" class="btn btn-orange">{{rooms[3]}}</button>
        <span *ngIf="lugar==3" style="font-size: 20px; font-weight:bold; margin:10px;color: white;  "  >{{rooms[3]}}</span>
    </div>
  </div>

</div> 

<div class="row mt-3 justify-content-center " >
  <div class="col-md-6" >
   
      <div *ngFor="let event of eventshow"  class="caja m-2" >
           <span style="font-size: 20px; font-weight:bold;  ">{{event.name}}</span> 
           <br>
           <div *ngIf="event.speaker" style="font-size: 13px; color: black; margin-top: 10px; ">{{event.speaker}}</div> 
           
          <span> Fecha: {{event.date}}</span> <br>
          <span>Lugar: {{event.lugar}}</span> <br>
         
         
      </div>
  </div>
</div>


