<div style="position: fixed; z-index: 9999; top:0px; left: 0px; width: 100%; z-index: 99999999999;">
    <app-menu [user]="user"></app-menu> 
</div> 

<div class="lienzo">

    <div style="padding: 50px; padding-top: 60px; height: 100%; ">

        <div style="padding-top: 20px;" class="row">
            <div  class="col-md-3 col-12 logcel">
           
            </div>
            <div class="col-md-6 col-12">
            
            </div>
            <div style="text-align: right;" class="col-md-3 celular">
                <!-- <img width="45%" src="../../../assets/imagenes/var.png" /> -->
            </div>
        </div>
    
    
        <div   class="row">
            
            <div class="col-md-7 cajavideo " id="cajavideo" >
                
                <div class="row" style="background-color: transparent;" id="cajavideo"   >
                    <div class="col-9 col-md-12 " *ngIf="!foto">
            
            
                        <video id="video" style="position: absolute; left: 0px; width: 100%; height: auto; z-index: 1;" autoplay playsinline  #video></video> 
                        <video id="videof"   style="position: absolute; left: 0px; width: 100%; height: auto; z-index: 999999999; visibility: hidden; "    controls #videof></video> 
                        <canvas  #canvas2  id="canvas2" style=" position:  absolute; left: 0px; width: 100%; height:auto; z-index: 99999;"   ></canvas>
                       
                       
                    </div>
            
                    <div class="col-9 col-md-12 justify-content-start"  >
                        <canvas #canvasf id="canvasf" style=" position: absolute; left: 0px; width: 100%; height:auto; z-index: 99999;"></canvas>  
                    </div>
                   
                    </div>
    
    
            </div>
            <div class="col-md-3">
                
                <div  *ngIf="grabando2"  style="color: white; font-size: 25px; font-family: avenir;">
                    Grabando.. <br> <br>
                   {{timeleft}} segundos restantes.
                </div>
                
            </div>
            <div class="col-md-1">
               
            </div>
            
        </div>

        <br><br><br><br>

        
        
        
        <div class="row botones_camara">
            <div class="col-md-9 col-12">
              
            </div>
            <div class="col-md-3 col-12" *ngIf="!foto && !grabado" >
                <img style="cursor: pointer;" (click)="tomar()" *ngIf="!grabando" width="40%" src="../../../assets/tomar.png" />
                &nbsp;&nbsp;
                <button *ngIf="!subdoc" (click)="subdoc=true"  class="btn btn_1" >Subir una foto</button>
                &nbsp;&nbsp;
              
                <img  style="cursor: pointer;" (click)="rotar()" *ngIf="!grabando" width="40%" src="../../../assets/girar.png" />
               
                
                <div *ngIf="subdoc"  style="background-color: rgba(255, 255, 255, 0.9); padding: auto; border-radius:8px; ">
                    <br>
                <label  *ngIf="subdoc"   for="myfile" style="color: rgb(255, 0,110);" >Sube tu imagen en png: </label>
                <input  *ngIf="subdoc" id="myfile" name="myfile"  type="file" (change)="onFileChange($event)" placeholder="Sube tu imagen en png" accept=".png" >
            </div>
                
           
           
            </div>
         
            <div class="col-md-3 col-12" *ngIf="foto "  >
                <!-- <button (click)="descargar()"  class="btn btn_1" >Descargar</button>
                &nbsp;&nbsp;
            <button (click)="back()"  class="btn btn_1" >Regresar</button> -->
            <div   style="color: black; font-size: 25px; font-family: avenir;">
              Foto Establecida.
              <br>
              <button (click)="back()"  class="btn btn_1 mr-2" >Volver a cambiar</button>
              <a href="/agenda"><button  class="btn btn_1 mr-2" >Regresar</button></a>
            </div>
            </div>
            <div class="col-md-6 col-12"  *ngIf="grabado"  >
                <!-- <button (click)="tomar()" *ngIf="!grabando"   class="btn btn-orange" >Tomar</button>
                <button (click)="rotar()" *ngIf="!grabando"   class="btn btn-orange" >rotar</button>
        
                <button (click)="startRecording()" *ngIf="!grabando"   class="btn btn-orange" >grabar</button> -->
                <button (click)="back()" *ngIf="grabado"  class="btn btn_1 mr-2" >Regresar</button>
                <button (click)="download()" *ngIf="grabado"  class="btn btn_3" >Desacargar</button>
             
            </div>
        </div>








    </div>

    





    <div class="row justify-content-center" >
        
       <!--  <div class="col-9 col-md-5 justify-content-center"  *ngIf="foto"  >
            <button (click)="descargar()"  class="btn btn_1" >Descargar</button>
            <button (click)="back()"  class="btn btn_1" >Regresar</button>
        </div> -->
        
    </div> 
        
    
    <!-- <div class="row rounded-top shadow text-white justify-content-center"  >
        <div class="col-8 col-md-7 justify-content-center" *ngIf="!foto">


            <video id="video" style="position: absolute; width: 100%; height: auto; z-index: 1;" autoplay  #video></video> 
            <video id="videof"   style="position: absolute; width: 100%; height: auto; z-index: 999999999; visibility: hidden; "  autoplay  controls #videof></video> 
            <canvas  #canvas2  id="canvas2" style=" position: absolute; width: 100%; height:auto; z-index: 99999;"   ></canvas>
    
           
        </div>

        <div class="col-8 col-md-7 justify-content-center"  >
             <canvas #canvasf id="canvasf" style=" position: absolute; width: 100%; height:auto; z-index: 99999;"></canvas>
        </div>
       
        </div> -->




</div>





 
 

<!-- 

    <div style="position: absolute; top:0px; left: 0px; width: 100%;">
        <app-menu [user]="user"  ></app-menu> 
    </div>
    <div class="row justify-content-center" >
        <div class="col-9 col-md-5 justify-content-center"  *ngIf="!foto"  >
            <button (click)="tomar()" *ngIf="!grabando"   class="btn btn-orange" >Tomar</button>
            <button (click)="rotar()" *ngIf="!grabando"   class="btn btn-orange" >rotar</button>
    
            <button (click)="startRecording()" *ngIf="!grabando"   class="btn btn-orange" >grabar</button>
            <button (click)="back()" *ngIf="grabado"  class="btn btn-orange" >Regresar</button>
            <button (click)="download()" *ngIf="grabado"  class="btn btn-orange" >Desacargar</button>
         
        </div>
        <div class="col-9 col-md-5 justify-content-center"  *ngIf="foto"  >
            <button (click)="descargar()"  class="btn btn-orange" >Descargar</button>
            <button (click)="back()"  class="btn btn-orange" >Regresar</button>
        </div>
        
            </div> 
        
    
    <div class="row rounded-top shadow text-white justify-content-center"  >
        <div class="col-8 col-md-7 justify-content-center" *ngIf="!foto"  >
            <video id="video" style="position: absolute; width: 100%; height: auto; z-index: 1;" autoplay  #video></video> 
            <video id="videof"   style="position: absolute; width: 100%; height: auto; z-index: 999999999; visibility: hidden; "  autoplay  controls #videof></video> 
            <canvas  #canvas2  id="canvas2" style=" position: absolute; width: 100%; height:auto; z-index: 99999;"   ></canvas>
    
           
        </div>
        <div class="col-8 col-md-7 justify-content-center"  >
           
            <canvas #canvasf id="canvasf" style=" position: absolute; width: 100%; height:auto; z-index: 99999;"></canvas>
        </div>
       
        </div>
      -->
     