import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { EventsService } from '../../services/events.service';
import { ActivatedRoute } from "@angular/router";
import { Router } from '@angular/router';
import {SocketioService} from "../../services/socketio.service";
import { Subscription } from 'rxjs';
import {AuthService} from "../../services/auth.service"
import { first } from 'rxjs/operators';
@Component({
  selector: 'app-preguntas',
  templateUrl: './preguntas.component.html',
  styleUrls: ['./preguntas.component.css']
})
export class PreguntasComponent implements OnInit {
intervalid;
questions=[];
seting;
question;
user;
id;
  constructor(  
    private authService:AuthService,
    private eventService : EventsService, 
    private route: ActivatedRoute, private router : Router,
    private socketservice: SocketioService,
    private renderer: Renderer2,
    private elRef:ElementRef) { }

  ngOnInit(): void {
    let id=this.route.snapshot.paramMap.get("id")
    this.id=id;
this.loadquestions(id);
this.intervalid = setInterval(() => {
  this.loadquestions(id);
}, 15000);
let user = localStorage.getItem('userInfo')
this.user= JSON.parse(user).user;
this.loaduser();

  }
  ngOnDestroy() {
    if (this.intervalid) {
      clearInterval(this.intervalid);
    }
  }
  loadquestions(id){  this.eventService.preguntas(id)
    .then((response) => {
      //console.log(response);
      var preguntas=response["results"]
      preguntas.forEach(element => {
        //console.log(element);
        var date= new Date(element.time);
  var fecha= date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getUTCFullYear()+" hora: "+date.getHours()+":"+(date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
  element.time=fecha;
      })
   this.questions=preguntas
    })
    .catch((err)=>{
      this.router.navigate(['']);
    })
}
loaduser(){ 

  this.authService.validate(this.user.correo)
  .pipe(first())
  .subscribe(
    (res) => {  
  this.authService.setUserInfo({'user' : res['user']});
  let user = localStorage.getItem('userInfo')
  this.user= JSON.parse(user).user;


    } ,
    (err) => {}



    );

}

select(question){
this.question=question;
this.seting=true;
}

responder(){
var resp= (document.getElementById("resp") as HTMLInputElement).value;
console.log(resp);
this.authService.responder(this.user,this.question,resp,this.id)
.then(()=>{
  this.loadquestions(this.id);
  this.seting=false
})

}

}
