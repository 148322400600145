import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
 
@Injectable({
  providedIn: 'root'
})
export class EmpresaService {


  url="https://selesoluciona.com/eventopragma/empresas";
  constructor(private http : HttpClient) { }

  public validate(nit: string): Observable<object> {
    return this.http.post(this.url+'/auth', {nit})
      .pipe(
        map(result => {
          console.log(result);
          localStorage.setItem('access_token', result["user"].token);
          localStorage.setItem('user', JSON.stringify(result));
          return result;
        })
      );
  }


  public create(user): Observable<object> {
    return this.http.post<{token: string}>(this.url+'/create', {user})
      .pipe(
        map(result => {
          console.log(result);
          localStorage.setItem('access_token', result["user"].token);
          localStorage.setItem('user', JSON.stringify(result));
          
          return result;
        })
      );
  }

  public addElement(item,id){
    console.log(item)
    return this.http.post(this.url+'/newitem', {item,id}).toPromise()
  }
  public deleteItem(id){
    return this.http.post(this.url+'/deleteitem', {"id":id}).toPromise()
  }
  public getItems(id){
    return this.http.post(this.url+'/getitems', {"id":id}).toPromise()
  }
  public addPerson(id,empresa){
    return this.http.post(this.url+'/addperson', {id, empresa}).toPromise()
  }
  public removePerson(id,empresa){
    return this.http.post(this.url+'/removeperson', {id, empresa}).toPromise()
  }
  public getempresas(){
    return this.http.get(this.url+'/empresas').toPromise()
  }
}
