<a href="/"><button style="position: absolute; top: 10px; right: 25px;" class="btn btn-danger" >Volver</button></a>


<hr>
<div class="row mt-3 justify-content-center" >
    <div class="col-md-6" style="max-height: 500px; overflow-y:auto " >
        <div class="row mt-1 justify-content-center" style="overflow-x: hidden;" *ngIf="!contacts">
            <div class="col-11 justify-content-center item" >
 
                
                <h1 style="text-align: center; color: white; margin-top: 25px;">{{empresa.name}}</h1>
                <h5 style="text-align: center; color: white; margin-top: 5px;">{{empresa.descripcion}}</h5>
                <div *ngIf="user._id==id || user.rol=='admin'" class=" justify-content-center " style="text-align: center;">

                   <button class="btn btn-orange m-1" (click)="downloadAsPDF()"> Descargar Escarapela</button> <br>
                   <button class="btn btn-orange m-1" (click)="contacts=true">Ver contactos </button> <br>
                   <a href="/lector"> <button class="btn btn-orange m-1">Leer escarapela</button></a> <br>
                   <button   class="btn btn-orange m-1" (click)="edato=true" >Editar datos</button> <br>
                   <button class="btn btn-orange m-1" (click)="add=true;tipoagregar='3';" >Agregar personal Montaje</button>
                   <button class="btn btn-orange m-1" (click)="add=true;tipoagregar='4';" >Agregar expositores Stand</button>
                   
                
                    <div *ngIf="empresa.personas" >
                        Número de personas agregadas: {{empresa.personas.length}} <br>

                        <button class="btn btn-orange " *ngIf="!lPerson" (click)="verPersonas()" >Ver</button>
                        <div *ngIf="lPerson" class="row justify-content-center " >
                            <button class="btn btn-orange m-1 w-100" (click)="descargar()" >Descargar personas</button>
                            <div class="col-5" *ngFor="let persona of personas" >
                                <app-profile  [user]="persona" >
                                </app-profile>
                                <button class="btn btn-danger" (click)="deletePerson(persona._id)" >Eliminar</button>
                            </div>

                        </div>
                    </div>
                    <div class="row mt-4 justify-content-center" >
                        <div #escarapela id="escarapela" *ngIf="user" style="display:hidden ;" class="col-md-4 col-11">
                            <app-profile [user]="user" [Vqr]="true">
                            </app-profile>
                         </div>
                    </div>
  
                </div>
                <br><br><br>
            </div>
           
         
         
         
         </div>
    </div>

<div class="col-md-6" style="max-height: 500px; overflow-y:auto " >
    <!-- <button (click)="downloadItems()">Descargartodo</button> -->
    <div class="row justify-content-center" >
        <div style="border-bottom: 0px solid #333; padding: 10px; " *ngFor="let it of items" class="col-md-10 m-2 item ">
            <div *ngIf="it.type=='link'" style="text-align: center;" >
                <a href="{{it.url}}" target="_blank" rel="noopener noreferrer">
                    <button class="btn btn-primary" 
                         style="font-size: 25px; 
                            font-weight: bold;
                            margin-top: 100px;
                    "
                    >
                        {{it.name}}
                    </button>
                </a>
            </div>
            <div *ngIf="it.type=='imagen' ">
                <h3 style="text-align: center;" >{{it.name}} </h3>
               <img #img style="cursor: pointer;" (click)="openPopImg(it)" width="90%" src="{{it.url}}" /><br>
                
           
            </div>
            <div *ngIf="it.type=='video' " class="embed-responsive embed-responsive-16by9" >
                <h3 style="text-align: center;" >{{it.name}} </h3>
                <iframe width="100%" class="embed-responsive-item embed-responsive-16by9" height="250px"  allowfullscreen   [src]="it.url | safe" > </iframe> 
              
            </div>
            <div *ngIf="it.type=='documento'" style="cursor: pointer;" (click)="openPopDoc(it)">
                
                <h3 style="text-align: center;" >{{it.name}} </h3>
                
                    <iframe [src]="it.url | safe" width="90%"  height="200px" frameborder="0"></iframe> 
                    <br>
                 <a href="{{it.url}}">Descargar</a> 
            </div>
            <button class="btn btn-danger" (click)="delete(it._id)" *ngIf="user._id==id" >Eliminar</button>
    
        </div>
    </div>
    
    <div class="row  m-4 justify-content-center ">
       
        <div class="col-md-10" *ngIf="user._id==id" >
            
            <div  class="caja_formulario">
                <h2>Ingrese los archivos de planilla e inventario de equipos</h2>
                <form [formGroup]="bitacoraForm" (ngSubmit)="bitacora()" >
                 
                    <div class="form-group">
                        <label>Nombre:</label>
                        <input  type="text" class="form-control input-form" placeholder="" formControlName="name"  [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                        <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                            <div *ngIf="f.name.errors.required">Ingresa un nombre</div>
                            <div *ngIf="f.name.errors.minlength">El nombre debe tener al menos 3 caracteres</div>
                         </div>
                    </div> 
                    <br>
                    <!-- <div class="form-group">
                        <label>Tipo:</label>
                        <select #mySelect (change)='onOptionsSelected(mySelect.value)'  formControlName="type" class="form-control">
                            <option value="">-seleccionar-</option>
                            <option value="imagen">Imagen</option>
                            <option value="video">Video</option>
                            <option value="documento">Documento</option>
                            <option value="link">Enlace</option>
                          </select>
                        <div *ngIf="submitted && f.type.errors" class="invalid-feedback">
                            <div *ngIf="f.type.errors.required">Selecciona un tipo</div>
                            
                        </div>
                    </div>  -->
                    
                    <div *ngIf="type == 'video' || type == 'link'" class="form-group">
                        <label>Url:</label>
                        <input  type="text" class="form-control input-form" placeholder="" formControlName="url"  >
                        <div *ngIf="submitted && f.url.errors" class="invalid-feedback">
                            <div *ngIf="f.url.errors.required">Ingresa uns url</div>
                            <div *ngIf="f.url.errors.minlength">El nombre debe tener al menos 3 caracteres</div>
                        </div>
                    </div> 
                    
                    <div   class="form-group">
                        <label>Archivo</label> 
                        <input formControlName="file" accept=".pdf" id="file" type="file" class="form-control" (change)="onFileChange($event)">
                        <div *ngIf="f.file.touched && f.file.invalid" class="alert alert-danger">
                            <div *ngIf="f.file.errors.required">El archivo es necesario</div>
                        </div>
                    </div>
                    <br>
                    
                    <input type="submit" value="Guardar" class="btn btn-primary">
    
                </form>
                <br>
                
              </div>
        </div>
    </div>
</div>

</div>


         <div *ngIf="contacts"  class="row mt-1 justify-content-center pop">
            <div class="col-md-12">
               <div style="float: right;">
                  <span style="font-size: 30px; cursor: pointer; color: white; font-weight: bolder;  "
                     (click)="contacts=false">X</span>
               </div>
               <app-contactos [id]="id" >
         
               </app-contactos>
            </div>
         </div>
 

<div class="pop" *ngIf="edato" >  
    <button style="position: absolute; top:10px; right:5px " class="btn btn-danger" (click)="edato=false" >X</button>
    <div class="row justify-content-center" >
        <div class="col-md-10" >
            <h1>Cambie los datos visibles de su empresa</h1>
        </div>
    </div>
    <div  class="row mt-4 justify-content-center " >
        <div class="col-md-10">
            <div class="caja_formulario">
    
                <form [formGroup]="registerForm" (ngSubmit)="register2()" >  
                    <div class="form-group">
                        <h3>Ingrese el logo de su empresa (PNG)</h3>     
                        <input class="form-control" type="file" (change)="onFileChangeEdit($event)" placeholder="png, jpg" formControlName="img">
                        </div>
                    <div class="form-group">
                        
                        <h3>Ingrese el nombre de la empresa</h3>
                        <input type="text" class="form-control" placeholder="Nombre" formControlName="name"
                            [ngClass]="{ 'is-invalid': submitted && f2.name.errors }">
                        <div *ngIf="submitted && f2.name.errors" class="invalid-feedback">
                            <div *ngIf="f2.name.errors.required">Ingrese su nombre</div>
                            <div *ngIf="f2.name.errors.minlength">El nombre debe tener al menos 4 caracteres</div>
                        </div>
                    </div>
                    <div class="form-group">
                        
                        <h3>Ingrese la descripción la empresa</h3>
                        <textarea cols="30" class="form-control"  formControlName="descripcion"
                        [ngClass]="{ 'is-invalid': submitted && f2.descripcion.errors }" rows="4"></textarea>
                        <!-- <input type="text" class="form-control" placeholder="Nombre" formControlName="descripcion"
                            [ngClass]="{ 'is-invalid': submitted && f2.descripcion.errors }"> -->
                       
                    </div>
                    
                   
                <input type="submit" value="Confirmar" class="btn btn-orange">
                
            </form>
                
            </div>
        </div>
    </div>

</div>


<div class="pop" *ngIf="add"  >
    <button style="position: absolute; top:10px; right:5px " class="btn btn-danger" (click)="cancelBuscar()" >X</button>
    <div class="row mt-5 justify-content-center " >
        <div *ngIf="!reperson" class="col-md-6" >
            <button  class="btn btn-orange" (click)="reperson=true;" >Registrar Persona</button>
            <button class="btn btn-primary mb-1"  (click)="cargador=true" >Carga masiva de usuarios</button>
        </div>
        <div *ngIf="cargador" >
            <app-useruploader (active)="onActive($event)" [empresa]="empresa" [tipo]="tipoagregar" ></app-useruploader>
        </div>
        <div class="col-md-6" *ngIf="reperson" >
            <div  class="row justify-content-center " >
                <div class="col-md-12">
                    <div class="caja_formulario">
            
                        <form [formGroup]="registerFormPerson" (ngSubmit)="registerp()" >  
                            <div class="form-group">
                                <label style="color: white; margin-bottom:1px; "  >Nombre:</label>
                                <input type="text" class="form-control"  formControlName="name"  [ngClass]="{ 'is-invalid': submitted && f3.name.errors }">
                                <div *ngIf="submitted && f3.name.errors" class="invalid-feedback">
                                    <div *ngIf="f3.name.errors.required">Ingrese su nombre</div>
                                    <div *ngIf="f3.name.errors.minlength">El nombre debe tener al menos 3 caracteres</div>
                                </div>
                            </div> 
                            <div class="form-group">
                                <label>Número de documento:</label>
                                <input type="text" class="form-control" placeholder="" formControlName="doc"
                                    [ngClass]="{ 'is-invalid': submitted && f3.doc.errors }">
                                <div *ngIf="submitted && f3.doc.errors" class="invalid-feedback">
                                    <div *ngIf="f3.doc.errors.required">Ingrese su número de documento</div>
                                    <div *ngIf="f3.doc.errors.minlength">El documento debe tener al menos 5 caracteres</div>
                                </div>
                            </div>
                          
                            <div class="form-group">
                                <label style="color: white; margin-bottom:1px; "  >Empresa:</label>     
                                <input class="form-control" type="text"  [readonly]="true" formControlName="company" 
                                 [ngClass]="{ 'is-invalid': submitted && f3.company.errors }">
                                <div *ngIf="submitted && f3.company.errors" class="invalid-feedback ">
                                    <div *ngIf="f3.company.errors.required">ingrese su empresa</div>
                                 
                                </div> 
                            </div>
                           
                            <div class="form-group">
                             <label style="color: white; margin-bottom:1px; "  >Cargo:</label>     
                             <input class="form-control" type="text"  formControlName="position" 
                              [ngClass]="{ 'is-invalid': submitted && f3.position.errors }">
                             <div *ngIf="submitted && f3.position.errors" class="invalid-feedback ">
                                 <div *ngIf="f3.position.errors.required">ingrese su cargo</div>
                              
                             </div> 
                         </div>
                         <div class="form-group">
                          <label style="color: white; margin-bottom:1px; "  >Celular:</label>     
                          <input class="form-control" type="text"  formControlName="phone"
                            [ngClass]="{ 'is-invalid': submitted && f3.phone.errors }">
                          <div *ngIf="submitted && f3.phone.errors" class="invalid-feedback ">
                              <div *ngIf="f3.phone.errors.required">ingrese su celular</div>
                           
                          </div> 
                      </div>
                          
                        <div class="form-group">
                            <label style="color: white; margin-bottom:1px; "  >Correo electrónico:</label>     
                            <input class="form-control" type="email"  formControlName="email"
                              [ngClass]="{ 'is-invalid': submitted && f3.email.errors }">
                            <div *ngIf="submitted && f3.email.errors" class="invalid-feedback ">
                                <div *ngIf="f3.email.errors.required">ingrese su email</div>
                                <div *ngIf="f3.email.errors.email">ingrese un correo valido</div>
                            </div> 
                        </div>
                        
                     
                           
                       
                        
                        <input type="hidden" class="form-control" formControlName="rol" value="staff"  >
                        <input type="submit" value="Ingresar" class="btn btn-orange">
                       
                        
                    </form>
                        
                    </div>
                </div>
            </div>
        </div>




    </div>
    <!-- <div  *ngIf="!reperson" class="row m-3 justify-content-center" >
        <div class="col-md-10" >
            <h1  >Ingrese el código o cedula de la persona que desea agregar a su empresa</h1>
            <br>
    
        </div>
    </div>
    <div  *ngIf="!reperson" class="row m-5 justify-content-center" >
        <div *ngIf="!yaPerson" class="col-md-10" >
            <input type="text" id="cedula" #cedula>
            <button class="btn btn-orange" (click)="buscarPerson()" >Buscar</button>
        </div>

        <div *ngIf="yaPerson" class="col-md-10" >
            <app-profile  [user]="person" >
            </app-profile>
            <br>
            <button class="btn btn-orange" (click)="addPerson()"  >Agregar a mi empresa</button>
        </div>
    </div> -->

    
</div>



<div class="pop2" *ngIf="imgPop">
    <button style="position: absolute; top:10px; right:5px " class="btn btn-danger" (click)="imgPop=false" >X</button>
  <div style="text-align: center; " >
    <img src="{{popSrc}}" width="55%" style="margin: auto;" alt="">
  </div>
 

</div>

<div class="pop2" *ngIf="docPop">
    <button style="position: absolute; top:10px; right:5px " class="btn btn-danger" (click)="docPop=false" >X</button>
  <div style="text-align: center;" >
    <iframe [src]="popSrc | safe" width="80%" height="580px"  style="margin: auto;"  frameborder="0"></iframe> 
  </div>


</div>