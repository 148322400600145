import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { EventsService } from "../../services/events.service";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';
@Component({
  selector: 'app-memorias',
  templateUrl: './memorias.component.html',
  styleUrls: ['./memorias.component.css']
})
export class MemoriasComponent implements OnInit {


  eventForm: FormGroup;
  events;
  user;
  vIngresos = false;
  privados = [];
  miercoles = [];
  jueves = [];
  viernes = [];
  dia = "jueves";
  lugar = 0;
  rooms = ["Salón INTELIGENCIA ARTIFICIAL", "Salón INTELIGENCIA CREATIVA", "Salón INTELIGENCIA HUMANA"];

  pat = true;
  patrocinadores = [

    'p1.png',
    'p2.png',
    'p3.png',
    'p4.png',
    'p5.png',
    'p6.png',
    'p7.png',
    'p8.png',
    'p9.png',
    'p10.png',
    'p11.png',
    'p12.png',
    'p13.png',
    'p14.png',
    'p15.png',
    'p16.png',
    'p17.png',
    'p18.png',
    'p19.png',
    'p20.png',
    'p21.png',
    'p22.png',
    'p23.jpg',
    'p24.png',
    'p25.png',
    'p26.png',
    'p27.png',
    'p28.png',
    'p29.png',
    'p30.png',
    'p31.png',


  ]
  eventshow: any;
  ingresosPop: any;
  constructor(
    private eventosService: EventsService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    // this.deleteEvent("635befa0a253c67f95106d23")
    this.loaduser();
    this.getEvents();
    this.eventForm = new FormGroup({
      name: new FormControl('', Validators.required),
      date: new FormControl('', Validators.required),
      show: new FormControl('', Validators.required),
      dia: new FormControl('', Validators.required),
      lugar: new FormControl('', Validators.required),
      id: new FormControl("0", [Validators.required,]),
    })
  }
  loaduser() {
    this.authService.loaduser()
      .then((response) => {
        console.log(response);
        this.authService.setUserInfo({ 'user': response['user'] });
        let user = localStorage.getItem('userInfo')
        this.user = JSON.parse(user).user;


      })
      .catch((err) => {
        location.href = "/login"
        console.log(err)
      })
  }


  getEvents() {
    this.miercoles = [];
    this.jueves = [];
    this.viernes = [];
    this.privados = [];
    var now = new Date();
    var n = now.getUTCHours();
    if (n < 5) {
      n = 24 + n;
    }
    function compare(a, b) {
      if (a.evento.date < b.evento.date) {

        return -1;
      }
      if (a.evento.date > b.evento.date) {
        return 1;
      }
      return 0;
    }


    now.setHours(n - 5);
    console.log(now);
    //this.eventosService.getAll()
    this.eventosService.getAllMemoras()
      .then((r: any) => {
        console.log(r)
        this.events = [];
        var events = r
        events.sort(compare);
        events.forEach(element => {
          //console.log(element);
          if (element.evento && element.evento.date) {
            var date = new Date(element.evento.date);
            //var fecha= date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getUTCFullYear()+" hora: "+date.getHours()+":"+(date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
            var fecha = " Hora: " + date.getHours() + ":" + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
            element.evento.date = fecha;
            if (now.getTime() > date.getTime() - (300 * 1000)) {
              element.isnow = true
            }
            if (element.evento.pay != "premium") {
              this.events.push(element)
              switch (element.evento.day) {
                case "miercoles":
                  this.miercoles.push(element);
                  break;
                case "jueves":
                  this.jueves.push(element);
                  break;
                case "viernes":
                  this.viernes.push(element);
                  break;
                default:
                  break;
              }
            }
            else {
              this.privados.push(element);

            }

          }
        });
        this.cambioDia("miercoles")
        this.cambioLugar(0)
      })
  }
  cambioDia(dia) {
    this.dia = dia;
    switch (dia) {
      case "miercoles":
        this.events = this.miercoles;
        break;
      case "jueves":
        this.events = this.jueves;
        break;
      case "viernes":
        this.events = this.viernes;
        break;
      default:
        break;
    }
    this.cambioLugar(this.lugar);
  }

  cambioLugar(lugar) {
    this.lugar = lugar;
    this.eventshow = this.events.filter((r) => { return r.evento.room == lugar })
  }

  newEvent() {
    this.eventosService.create(this.eventForm.value)
      .then((response) => {
        //console.log(response);
        var emptyUser = {
          name: "",
          date: "",
          id: "0"
        };
        this.eventForm.reset(emptyUser);
        this.getEvents();
      })
      .catch((err) => {
        console.log(err);
      })
  }
  edit(event: any) {

    var evento: Object = {
      id: event._id,
      name: event.name,
      date: event.date,
      dia: event.dia,
      lugar: event.lugar,
      show: event.show,


    }
    this.eventForm.reset(evento);
  }


  deleteEvent(id) {



    this.eventosService.deleteMemoria(id)
      .then((r) => {
        this.getEvents();
      })
  }
  register(event) {
    this.eventosService.register(event, this.user)
      .then((r) => {
        console.log(r);
        this.loaduser();
      })
  }

  getRegisters(event) {
    this.eventosService.registrados(event)
      .then((r: any) => {
        console.log(r)
        let csvContent = "data:text/csv;charset=utf-8,";
        r["results"].forEach(element => {
          let row = element.user.name + "," + element.user.correo;
          csvContent += row + "\r\n";
        });
        saveAs(csvContent, "registrados_" + r["results"][0].eventName + ".csv");
      })

  }


  popIngresos(event) {
    this.eventosService.getIngresoEvent(event)
      .then((r: any) => {
        console.log(r)
        this.ingresosPop = r;
        this.vIngresos = true;
      })
  }

  verIngresos(event) {
    this.eventosService.getIngresoEvent(event)
      .then((r: any) => {
        console.log(r)
        let csvContent = "data:text/csv;charset=utf-8,";
        r.forEach(element => {
          let row = element.user.name + "," + element.user.email + "," + element.user.doc + "," + element.user.company + "," + new Date(element.hora).toLocaleString();
          csvContent += row + "\r\n";
        });
        saveAs(csvContent, "ingresos_.csv");
      })

  }

}
