<div>
    <!-- <button class="btn btn-primary" (click)="modal_contactos = true">Mis contactos</button> -->
    <!-- <button class="btn btn-primary" (click)="limpiar()">Limpiar</button> -->
</div>

<br><br>
<div style="text-align: center;">
    <h2>NETWORKING</h2>
</div>


<div class="p-4">


    <div style="padding: 0px; padding-top: 0px;">

        <div>
            <div class="row">
                <div *ngFor="let contacto of mis_contactos" class="col-md-3">

                    <div style="background-color: white; color: #333; padding: 20px">
                    
                    
                    <div *ngIf="user._id != contacto.contacto._id && !contacto.aceptado">
                        {{contacto.contacto.name}}<br>
                        <div *ngIf="contacto.aceptado">
                            {{contacto.contacto.email}}<br>
                        </div>
                        <div>
                         <strong style="color: orange;">PENDIENTE</strong>  
                        </div>
                    </div>

                    <div *ngIf="user._id == contacto.contacto._id && !contacto.aceptado">
                       <strong> {{contacto.user.name}}</strong> de {{contacto.user.company}} te ha contactado<br>
                        <div *ngIf="contacto.aceptado">
                            {{contacto.contacto.email}}<br>
                        </div>
                        <br>
                        <div>
                            {{contacto.mensaje}}
                        </div>
                        <br>
                        <div>
                            <button (click)="estadoContacto(contacto._id)" class="btn btn-primary">ACEPTAR CONTACTO</button>
                        </div>
                    </div>


                    <div *ngIf="contacto.aceptado">

                        <div *ngIf="user._id == contacto.contacto._id">
                          <strong>{{contacto.user.name}}</strong>  <br>
                        <div *ngIf="contacto.aceptado">
                            {{contacto.user.email}}<br>
                            {{contacto.user.phone}}<br>
                        </div>
                        </div>

                        <div *ngIf="user._id == contacto.user._id">
                           <strong>{{contacto.contacto.name}}</strong><br>
                        <div *ngIf="contacto.aceptado">
                            {{contacto.contacto.email}}<br>
                            {{contacto.user.phone}}<br>
                        </div>
                        </div>

                        

                        
                    </div>

                    
                </div>

            </div>
                
            </div>
        </div>

        
      
    </div>


    <hr>



    <div class="row">
        <div class="col-md-3 mb-4" *ngFor="let unw of usuariosNetworking">
            <div style="background-color: white; color: #333; padding: 20px">
                <div>
                  <strong>NOMBRE:</strong>   {{unw.user.name}}<br>
                  <strong>EMPRESA:</strong>   {{unw.user.company}}<br>
                  <strong>CARGO:</strong>   {{unw.user.position}}<br>       
                </div>
                <div *ngIf="unw._id != user._id">
                    <button *ngIf="!unw.contacto" class="btn btn-secondary" (click)="contactar(unw.user.name, unw.user._id, unw.user.email)">AGREGAR A CONTACTOS</button>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="d-flex justify-content-center align-items-center" *ngIf="modal_contactar"
    style="z-index: 999; position: absolute; top: 0px; left: 0px; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.5);">
  
    <div style="background-color: #fff; max-width: 600px;">
  
      <div class="d-flex justify-content-between align-items-center p-2">
        <div style="font-size: 21px; padding-left: 5px;">Solicitud de contacto</div>
        <button (click)="modal_contactar = false" class="btn">X</button>
      </div>
  
      <div style="padding: 30px; padding-top: 0px;">
       Se enviará una solictud de contacto a <strong>{{nombre_contacto}}</strong> <br><br>
       <textarea placeholder="Agregar mensaje" [(ngModel)]="mensaje_contacto" class="form-control"></textarea>
       <br>
       <button class="btn btn-primary" (click)="enviar_contacto()">ENVIAR</button>
      </div>
  
    </div>
  
  </div>




  <div class="d-flex justify-content-center align-items-center" *ngIf="modal_contactos"
    style="z-index: 999; position: absolute; top: 0px; left: 0px; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.5);">
  
    <div style="background-color: #fff; max-width: 600px;">
  
      <div class="d-flex justify-content-between align-items-center p-2">
        <div style="font-size: 21px; padding-left: 5px;">Mis contacto</div>
        <button (click)="modal_contactos = false" class="btn">X</button>
      </div>
  
      <div style="padding: 30px; padding-top: 0px;">

        <div>
            <div class="row">
                <div *ngFor="let contacto of mis_contactos" class="col-md-12">
                    
                    <div *ngIf="user._id != contacto.contacto._id && !contacto.aceptado">
                        {{contacto.contacto.name}}<br>
                        <div *ngIf="contacto.aceptado">
                            {{contacto.contacto.email}}<br>
                        </div>
                        <div>
                            PENDIENTE
                        </div>
                    </div>

                    <div *ngIf="user._id == contacto.contacto._id && !contacto.aceptado">
                        {{contacto.user.name}}<br>
                        <div *ngIf="contacto.aceptado">
                            {{contacto.contacto.email}}<br>
                        </div>
                        <br>
                        <div>
                            {{contacto.mensaje}}
                        </div>
                        <div>
                            <button (click)="estadoContacto(contacto._id)" class="btn btn-primary">ACEPTAR CONTACTO</button>
                        </div>
                    </div>


                    <div *ngIf="contacto.aceptado">

                        <div *ngIf="user._id == contacto.contacto._id">
                            {{contacto.user.name}}<br>
                        <div *ngIf="contacto.aceptado">
                            {{contacto.user.email}}<br>
                        </div>
                        </div>

                        <div *ngIf="user._id == contacto.user._id">
                            {{contacto.contacto.name}}<br>
                        <div *ngIf="contacto.aceptado">
                            {{contacto.contacto.email}}<br>
                        </div>
                        </div>

                        

                        
                    </div>

                    <hr>
                </div>


            </div>
        </div>

        
      
    </div>
  
    </div>
  
  </div>