import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import {FormsModule,ReactiveFormsModule} from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { ConferenciaComponent } from './components/conferencia/conferencia.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { SafePipe } from './safe.pipe';
import { ChatComponent } from './components/chat/chat.component';
import { ElemdisplayDirective } from './components/elemdisplay.directive';
import { PreguntasComponent } from './components/preguntas/preguntas.component';
import { VotacionesComponent } from './components/votaciones/votaciones.component';
import { RegistroPagoComponent } from './components/registro-pago/registro-pago.component';
import { GoogleChartsModule } from 'angular-google-charts';
import { OpenviduSessionModule } from 'openvidu-angular';
import {MatTabsModule} from '@angular/material/tabs';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import 'hammerjs';
import { TallerComponent } from './components/taller/taller.component';
import { MuestraComponent } from './components/muestra/muestra.component';
import { StandComponent } from './components/stand/stand.component';
import { LandingComponent } from './components/landing/landing.component';

import { CuentaComponent } from './components/cuenta/cuenta.component';

import { ConferencistasComponent } from './components/conferencistas/conferencistas.component';
import { UsuariosComponent } from './components/usuarios/usuarios.component';
import { MonitorComponent } from './components/monitor/monitor.component';
import { AgendaComponent } from './components/agenda/agenda.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MemoriasComponent } from './components/memorias/memorias.component';
import { MiagendaComponent } from './components/miagenda/miagenda.component';
import { VizualizadorComponent } from './components/vizualizador/vizualizador.component';
import { BitacoraComponent } from './components/bitacora/bitacora.component';
// import { ChatPublicoComponent } from './components/chat-publico/chat-publico.component';
import { NotasComponent } from './components/notas/notas.component';
import { SalanetComponent } from './components/salanet/salanet.component';
import { SalaComponent } from './components/sala/sala.component';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";
// import { FotosComponent } from './components/fotos/fotos.component';
import { JwtModule } from '@auth0/angular-jwt';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './services/auth.guard';
import { SolicitudesComponent } from './components/solicitudes/solicitudes.component';
import { MenuComponent } from './components/menu/menu.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { EuserComponent } from './components/euser/euser.component';
import { HomeVirtualComponent } from './components/home-virtual/home-virtual.component';
import { LectorUsuarioComponent } from './components/lector-usuario/lector-usuario.component';
import { LectorEventosComponent } from './components/lector-eventos/lector-eventos.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ContactosComponent } from './components/contactos/contactos.component';
import { EmpresaComponent } from './components/empresa/empresa.component';
import { EmpresaProfileComponent } from './components/empresa-profile/empresa-profile.component';
import { RegistroEmpresaComponent } from './components/registro-empresa/registro-empresa.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { UseruploaderComponent } from './components/useruploader/useruploader.component';
import { EmpresasComponent } from './components/empresas/empresas.component';
import { MessagesComponent } from './components/messages/messages.component';
import { CortesiasComponent } from './components/cortesias/cortesias.component';
import { RegisterCortesiasComponent } from './components/register-cortesias/register-cortesias.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FormularioComponent } from './components/formulario/formulario.component';
export function tokenGetter() {
  return localStorage.getItem('access_token');
} 

import { StarRatingModule } from 'angular-star-rating';
import { NetworkingComponent } from './components/networking/networking.component';
import { ComentariosComponent } from './components/comentarios/comentarios.component';
import { ImpresionComponent } from './components/impresion/impresion.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ConferenciaComponent,
    LoginComponent,
    RegisterComponent,
    SafePipe,
    ChatComponent,
    ElemdisplayDirective,
    PreguntasComponent,
    VotacionesComponent,
    RegistroPagoComponent,
    TallerComponent,
    MuestraComponent,
    StandComponent,
    LandingComponent,

    CuentaComponent,

    ConferencistasComponent,

    UsuariosComponent,

    MonitorComponent,

    AgendaComponent,

    MemoriasComponent,

    MiagendaComponent,

    VizualizadorComponent,

    BitacoraComponent,

    // ChatPublicoComponent,

    NotasComponent,

    SalanetComponent,

    SalaComponent,
      SolicitudesComponent,
      MenuComponent,
      AvatarComponent,
      EuserComponent,
      HomeVirtualComponent,
      LectorUsuarioComponent,
      LectorEventosComponent,
      ProfileComponent,
      ContactosComponent,
      EmpresaComponent,
      EmpresaProfileComponent,
      RegistroEmpresaComponent,
      UseruploaderComponent,
      EmpresasComponent,
      MessagesComponent,
      CortesiasComponent,
      RegisterCortesiasComponent,
      FormularioComponent,
      NetworkingComponent,
      ComentariosComponent,
      ImpresionComponent,
      // FotosComponent

  ],
  imports: [
    RecaptchaModule,  //this is the recaptcha main module
    RecaptchaFormsModule, //this is the module for form incase form validation
    RecaptchaV3Module,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    GoogleChartsModule,
    OpenviduSessionModule,
    NgxQRCodeModule,
    FormsModule,
    BrowserAnimationsModule,
    NgbModule,
     MatTabsModule,
    NgbModule,
    CarouselModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: ["34.226.112.131:8180","selesoluciona.com:8180","selesoluciona.com"],
        disallowedRoutes: [],
      },
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    StarRatingModule.forRoot(),


  ],
  providers: [    AuthService,
    AuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
