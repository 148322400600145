import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { ConferenciaComponent } from './components/conferencia/conferencia.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
//import { AuthGuardService as AuthGuard } from './services/auth-guard.service';
import { AuthGuard } from './services/auth.guard';
import { ChatComponent } from './components/chat/chat.component';
import { PreguntasComponent } from "./components/preguntas/preguntas.component"
import { VotacionesComponent } from "./components/votaciones/votaciones.component"
import { RegistroPagoComponent } from './components/registro-pago/registro-pago.component';
import { TallerComponent } from './components/taller/taller.component';
import { MuestraComponent } from './components/muestra/muestra.component';
import { StandComponent } from './components/stand/stand.component';
import { LandingComponent } from './components/landing/landing.component';
import { UsuariosComponent } from './components/usuarios/usuarios.component';
import { CuentaComponent } from './components/cuenta/cuenta.component';

import { ConferencistasComponent } from './components/conferencistas/conferencistas.component';
import { MonitorComponent } from './components/monitor/monitor.component';
import { AgendaComponent } from './components/agenda/agenda.component';
import { MiagendaComponent } from './components/miagenda/miagenda.component';
import { VizualizadorComponent } from './components/vizualizador/vizualizador.component';
import { SalanetComponent } from './components/salanet/salanet.component';
import { SalaComponent } from './components/sala/sala.component';
// import { FotosComponent } from './components/fotos/fotos.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { EuserComponent } from './components/euser/euser.component';
import { HomeVirtualComponent } from './components/home-virtual/home-virtual.component';
import { LectorUsuarioComponent } from './components/lector-usuario/lector-usuario.component';
import { LectorEventosComponent } from './components/lector-eventos/lector-eventos.component';
import { EmpresaComponent } from './components/empresa/empresa.component';
import { EmpresaProfileComponent } from './components/empresa-profile/empresa-profile.component';
import { RegistroEmpresaComponent } from './components/registro-empresa/registro-empresa.component';
import { EmpresasComponent } from './components/empresas/empresas.component';
import { MessagesComponent } from './components/messages/messages.component';
import { MemoriasComponent } from './components/memorias/memorias.component';
import { CortesiasComponent } from './components/cortesias/cortesias.component';
import { RegisterCortesiasComponent } from './components/register-cortesias/register-cortesias.component';
import { FormularioComponent } from './components/formulario/formulario.component';
import { NetworkingComponent } from './components/networking/networking.component';
import { ComentariosComponent } from './components/comentarios/comentarios.component';

const routes: Routes = [
  { path: 'memorias', component: MemoriasComponent, canActivate: [AuthGuard] },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'escarapela', component: HomeVirtualComponent, canActivate: [AuthGuard] },
  { path: 'lector', component: LectorUsuarioComponent, canActivate: [AuthGuard] },
  { path: 'validacion/:id', component: LectorEventosComponent },
  { path: 'agenda', component: AgendaComponent, canActivate: [AuthGuard] },
  { path: 'cuenta', component: CuentaComponent, canActivate: [AuthGuard] },
  { path: 'comentarios', component: ComentariosComponent, canActivate: [AuthGuard] },
  { path: 'cortesias', component: CortesiasComponent, canActivate: [AuthGuard] },
  { path: 'registro/:id', component: RegisterCortesiasComponent },
  { path: "register-empresa", component: RegistroEmpresaComponent },
  { path: "envio-notificaciones", component: FormularioComponent, canActivate: [AuthGuard] },
  { path: 'empresap/:id', component: EmpresaProfileComponent, canActivate: [AuthGuard] },
  { path: 'empresa', component: EmpresaComponent },
  
  { path: 'empresas', component: EmpresasComponent, canActivate: [AuthGuard] },
  { path: 'miagenda', component: MiagendaComponent, canActivate: [AuthGuard] },
  { path: "avatar", component: AvatarComponent, canActivate: [AuthGuard] },
  { path: "profile", component: EuserComponent, canActivate: [AuthGuard] },
  { path: 'sala/:id', component: SalaComponent, canActivate: [AuthGuard] },
  { path: 'salas', component: SalanetComponent, canActivate: [AuthGuard] },
  //  { path: 'fotos', component: FotosComponent,canActivate : [AuthGuard] },
  { path: 'conferencia/:id', component: ConferenciaComponent, canActivate: [AuthGuard] },
  { path: 'preguntas/:id', component: PreguntasComponent, canActivate: [AuthGuard] },
  { path: 'taller/:id', component: TallerComponent, canActivate: [AuthGuard] },
  { path: 'stand/:id', component: StandComponent, canActivate: [AuthGuard] },
  { path: 'votaciones/:id', component: VotacionesComponent, canActivate: [AuthGuard] },
  { path: 'validador', component: MuestraComponent },
  { path: 'monitor/:id', component: MonitorComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: "register", component: RegisterComponent },
  { path: "usuarios", component: UsuariosComponent },
  { path: "inicio", component: LoginComponent },
  { path: "visualizador", component: VizualizadorComponent },
  { path: "conferencistas", component: ConferencistasComponent },
  { path: "", component: HomeVirtualComponent, canActivate: [AuthGuard] },
  { path: "registerp", component: RegistroPagoComponent },
  { path: "cuenta", component: CuentaComponent },
  { path: "networking", component: NetworkingComponent },
  { path: 'mensajes', component: MessagesComponent, canActivate: [AuthGuard] },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
