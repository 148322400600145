import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms'
import {EventsService} from '../../../app/services/events.service'

@Component({
  selector: 'app-bitacora',
  templateUrl: './bitacora.component.html',
  styleUrls: ['./bitacora.component.css']
})
export class BitacoraComponent implements OnInit {

  bitacoraForm: FormGroup;
  submitted = false;
  type = "imagen";
  items = [] ;
  d1=[];
  d2=[];
  d3=[];
  d4=[];
  d5=[];
  d6=[];
  day = 1;
  user;
  pop;
  popSrc;
  response = false;
  constructor(private service : EventsService, private cd:ChangeDetectorRef) { }

  ngOnInit(): void {
    let user = localStorage.getItem('userInfo')
    this.user= JSON.parse(user).user;
    console.log(this.user);
    this.bitacoraForm = new FormGroup({
      name: new FormControl('', Validators.required),
      type: new FormControl('', Validators.required),
      file: new FormControl('', [Validators.required]),
      url: new FormControl('', [Validators.required]),
      day: new FormControl('', [Validators.required]),
      fileSource: new FormControl('', [Validators.required])
    })

    this.getBitacora()
  }

  selectDay(day)
  {
    this.day = day;
  }

  bitacora(){
    //const formData = new FormData();
    //formData.append('file', this.bitacoraForm.get('fileSource').value);
    this.submitted = true;
    
    //console.log(this.bitacoraForm.value)
    this.service.bitacora(this.bitacoraForm.value).then((r)=>{
      console.log(r["item"]);
      this.items.push(r["item"])
      this.bitacoraForm.reset();
      this.response = true; // this - is in component object context
      setTimeout(function () {
        console.log('hide');
        this.response = false; // here... this has different context
      }, 2000);
    })

    

    if (this.bitacoraForm.invalid) { 
      return;
    }
  } 
 
  closepop(){
   this.pop=false;
  }
openpop(src){
  this.pop=true;
this.popSrc=src;
}

  getBitacora(){
    this.service.getBitacora().then((r)=>{
     // console.log(r["items"])
     this.d1=[];
     this.d2=[];
     this.d3=[];
     this.d4=[];
     this.d5=[];
     this.d6=[];
      var items=r["items"]
     items.forEach(item=> {
       switch (item.day){
case "1":
  this.d1.push(item);
  break;
  case "2":
  this.d2.push(item);
  break;
  case "3":
  this.d3.push(item);
  break; 
  case "4":
  this.d4.push(item);
  break;
  case "5":
  this.d5.push(item);
  break;
  case "6":
    this.d6.push(item);
    break;

       }
     });
   
      //console.log(this.items)
    })
  }

  
  onOptionsSelected(value:string){
    console.log("the selected value is " + value);
    this.type = value;
}

  get f() { return this.bitacoraForm.controls; }


  onFileChange(event) {

    /* if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.bitacoraForm.patchValue({
        fileSource: file
      });
    } */


    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
   
        this.bitacoraForm.patchValue({
         fileSource: reader.result
        });
        
        // need to run CD since file load runs outside of zone
        this.cd.markForCheck();
      };

    }




  }

delete(id){
   console.log(id);
  
    this.service.deleteBitacora(id)
    .then((response) => {
      console.log(response);
  this.getBitacora();
    }) 
    .catch((err)=>{
     console.log(err);
  })
}


downloadImage(img, name) {

  const imgUrl = img.src;
  const imgName = imgUrl.substr(imgUrl.lastIndexOf('/') + 1);
  const ext = imgName.substr(imgName.lastIndexOf('.') + 1);
  const nameImg = "Ruta2021."+ext

  //console.log(imgUrl, imgName, ext)

  this.service.downloadImage(imgUrl).then((res: any)=>{
    const file = new Blob([res], {type: res.type});
    
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(file);
      return;
    }

    const blob = window.URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = blob;
    link.download = nameImg;

    
    link.dispatchEvent(new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window
    }));

    setTimeout(() => { 
      window.URL.revokeObjectURL(blob);
      link.remove();
    }, 100); 
  
  
  })
}
  
  


}
