<h1>Votaciones</h1>


<div *ngFor="let question of questions" class="bg-white w-75 container" >
  <br>
  <br>
    <google-chart 
    [title]="question.title"
    [type]="type"
    [data]="question.data"
   [options]="options"
   [width]="width"
   [height]="height">
 </google-chart>
 <br>
 <button (click)="downques(question)" >Descargar votacion</button>
 <button class="btn btn_1" (click)="openpop(question)" >ampliar</button>
<hr>

</div>
<div class="pop" *ngIf="pop">
         
  
      
  <div class="row justify-content-end" style="padding-top: 1px; margin-top: 0px;" >

    
    <div class="col-md-10 col-8 mb-0  " style=" text-align: end; margin-bottom: 10px;">
  <h1 (click)="closepop()" style="color: #243971; font-weight: bolder; cursor: pointer;" >X</h1>  </div>
  </div>

  <div class="row justify-content-center mr-4 mt-1" >
   
    <div class="col-md-8 col-5 btn-boton" style="  padding: 10px; border-radius: 10px;">
      <google-chart 
     
      [type]="type"
      [data]="pull.data"
      [options]="options"
      [width]="width"
      [height]="height">
    </google-chart>
  </div>
  <div class="col-md-3 mt-5 ml-1 mr-2">
<h1 style="font-size: 30px; font-weight: bold;white-space: pre-wrap;word-wrap: break-word" >{{pull.title}}</h1>
  </div>

</div>
</div>
