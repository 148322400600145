
<div class="lienzo">
    <div class="row mt-1 justify-content-center ">
        
            <div class="col-md-2 ml-5 col-3" style="text-align:center ;">
               <img  src="../../../assets/Captura.PNG" width="100%" alt="">
            </div>
       
         
    
    </div>
    
    <div  class="row mt-4 justify-content-center " >
        <div class="col-md-4">
            <div class="caja_formulario">
    
                <form [formGroup]="registerForm" (ngSubmit)="register2()" >
                    
                    <div class="form-group">
                        <h3>Ingrese el nombre de la empresa</h3>
                        <input type="text" class="form-control" placeholder="Nombre" formControlName="name"
                            [ngClass]="{ 'is-invalid': submitted && f2.name.errors }">
                        <div *ngIf="submitted && f2.name.errors" class="invalid-feedback">
                            <div *ngIf="f2.name.errors.required">Ingrese su nombre</div>
                            <div *ngIf="f2.name.errors.minlength">El nombre debe tener al menos 4 caracteres</div>
                        </div>
                    </div>
                    <div class="form-group">
                        
                        <h3>Ingrese la descripción la empresa</h3>
                        <textarea cols="30" class="form-control"  formControlName="descripcion"
                        [ngClass]="{ 'is-invalid': submitted && f2.descripcion.errors }" rows="4"></textarea>
                        <!-- <input type="text" class="form-control" placeholder="Nombre" formControlName="descripcion"
                            [ngClass]="{ 'is-invalid': submitted && f2.descripcion.errors }"> -->
                       
                    </div>
                    
                    <div class="form-group">
                        <h3>Ingrese el NIT de la empresa</h3>
                        <input class="form-control" type="text" placeholder="NIT" formControlName="nit"
                            [ngClass]="{ 'is-invalid': submitted && f2.nit.errors }">
                        <div *ngIf="submitted && f2.nit.errors" class="invalid-feedback ">
                            <div *ngIf="f2.nit.errors.required">ingrese su documento</div>
            
                        </div>
                    </div>
                    <div class="form-group">
                        <h3>Ingrese clave de ingreso</h3>
                        <input class="form-control" type="text" formControlName="clave"
                            [ngClass]="{ 'is-invalid': submitted && f2.clave.errors }">
                        <div *ngIf="submitted && f2.clave.errors" class="invalid-feedback ">
                            <div *ngIf="f2.clave.errors.required">ingrese su clave</div>
            
                        </div>
                    </div>
                <input type="submit" value="Ingresar" class="btn btn-orange">
                
            </form>
                
            </div>
        </div>
    </div>
    <br><br><br>
</div>

