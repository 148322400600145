<div class="fondo">
    <div class="row justify-content-center" style="text-align: center">
        <div class="col-md-2"></div>
        <div class="col-md-5 mt-5 mb-3 col-6">
            <img src="../../../assets/images/newfolder/LogoLAD.png" width="50%" alt="" />
            <br /><br />
            <h1 style="color: white; font-size: 200%">
                Un evento para quienes ven en 360
            </h1>
        </div>
        <div class="col-md-2"></div>
    </div>

    <div class="row mt-3 mb-5 justify-content-center">
        <div class="col-md-4 caja">
            <form [formGroup]="registerForm" (ngSubmit)="register2()">
                <div class="row justify-content-center">
                    <div class="col-md-9">
                        <h1 style="text-align: center; color: white; font-size: 150%">
                            Asegura tu lugar en el Latam <br />
                            Architecture Day
                        </h1>

                        <div class="form-group col-md-12 mb-2">
                            <label style="font-size: 13px; text-align: left; color: #ffffff">Nombre*</label>
                            <input type="text" class="form-control" placeholder="" formControlName="name"
                                [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                <div *ngIf="f.name.errors.required">Ingrese sus nombres</div>
                                <div *ngIf="f.name.errors.minlength">
                                    El nombre debe tener al menos 3 caracteres
                                </div>
                            </div>
                        </div>

                        <div class="form-group col-md-12 mb-2">
                            <label style="font-size: 13px; text-align: left; color: #ffffff">Número de documento*</label>
                            <input class="form-control" type="text" placeholder="" formControlName="doc"
                                [ngClass]="{ 'is-invalid': submitted && f.doc.errors }" />
                            <div *ngIf="submitted && f.doc.errors" class="invalid-feedback">
                                <div *ngIf="f.doc.errors.required">ingrese su doc</div>
                                <div *ngIf="f.doc.errors.minlength">
                                    El documento debe tener al menos 5 caracteres
                                </div>
                            </div>
                        </div>

                        <div class="form-group col-md-12 mb-2">
                            <label style="font-size: 13px; text-align: left; color: #ffffff">Empresa*</label>
                            <input class="form-control" type="text" placeholder="" formControlName="company"
                                [ngClass]="{ 'is-invalid': submitted && f.company.errors }" />
                            <div *ngIf="submitted && f.company.errors" class="invalid-feedback">
                                <div *ngIf="f.company.errors.required">ingrese su empresa</div>
                            </div>
                        </div>

                        <div class="form-group col-md-12 mb-2">
                            <label style="font-size: 13px; text-align: left; color: #ffffff">Cargo*</label>
                            <input class="form-control" type="text" placeholder="" formControlName="position"
                                [ngClass]="{ 'is-invalid': submitted && f.position.errors }" />
                            <div *ngIf="submitted && f.position.errors" class="invalid-feedback">
                                <div *ngIf="f.position.errors.required">ingrese su cargo</div>
                            </div>
                        </div>

                        <div class="form-group col-md-12 mb-2">
                            <label style="font-size: 13px; text-align: left; color: #ffffff">Celular*</label>
                            <input class="form-control" type="text" placeholder="" formControlName="phone"
                                [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" />
                            <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                                <div *ngIf="f.phone.errors.required">ingrese su telefono</div>
                            </div>
                        </div>
    
                        <div class="form-group col-md-12 mb-2">
                            <label style="font-size: 13px; text-align: left; color: #ffffff">Correo*</label>
                            <input class="form-control" type="email" placeholder="" formControlName="email"
                                [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">ingrese su email</div>
                                <div *ngIf="f.email.errors.email">ingrese un correo valido</div>
                            </div>
                        </div>

                        
                        <div class="form-group col-md-12 mb-2">
                            <label>País:</label>
                            <select formControlName="pais" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.pais.errors }" >
                                <option value="">Seleccione</option>
                                <option value="{{pais}}" *ngFor="let pais of paises; let i = index ">
                                    {{pais}}
                                </option>
                            </select>
                            <div *ngIf="submitted && f.pais.errors" class="invalid-feedback">
                                <div *ngIf="f.pais.errors.required">Seleccione uno</div>
                            
                            </div>
                        </div>

                        

                        <div class="form-group">
                            <input type="checkbox" formControlName="terminos"
                                [ngClass]="{ 'is-invalid': submitted && f.terminos.errors }" />
                            <span style="color: white">Acepto, el tratamiento y uso de mis datos personales</span>
                            <div *ngIf="submitted && f.terminos.errors" class="invalid-feedback">
                                <div *ngIf="f.terminos.errors.required">
                                    Por favor acepte el tratamiento de datos personales
                                </div>
                            </div>
                        </div>
    
                        <input type="hidden" class="form-control" formControlName="rol" value="usuario" />
                        <input  style="background-color: #be22c7; color:white" type="submit" value="Ingresar" class="btn btn-orange w-100" />
                        <div style="margin-top: 10px; justify-content: center">
                            <a target="_blank" href="/autorizo.html">Política de tratamiento y uso de datos personales</a>
                        </div>
                        <br />
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="position-relative fixed-bottom">
        <img class="imag" src="../../../assets/images/newfolder/Footer.png" />
    </div>


</div>