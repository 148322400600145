import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { EventsService } from "../../services/events.service";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import  Swal from 'sweetalert2'; 
import { saveAs } from 'file-saver';
@Component({
  selector: 'app-muestra',
  templateUrl: './muestra.component.html',
  styleUrls: ['./muestra.component.css']
})
export class MuestraComponent implements OnInit {
  eventForm: FormGroup; 
events;
user;
vIngresos=false;
privados=[];
miercoles=[];
jueves=[];
viernes=[];
dia="jueves";
lugar=0;
rooms=["Sala Débora Arango","Sala Coco Chanel","Sala Marie Curie","El Chiringuito "]
  eventshow: any;
  ingresosPop: any;
  constructor(
    private eventosService:EventsService,
    private authService:AuthService
  ) { }

  ngOnInit(): void {
   // this.loaduser();
    this.getEvents();
    this.eventForm = new FormGroup({
      name: new FormControl('', Validators.required),
      date: new FormControl('', Validators.required),
      show: new FormControl('', Validators.required),
      dia: new FormControl('', Validators.required),
      lugar: new FormControl('', Validators.required),
      id: new FormControl("0", [Validators.required,]),
    })
  }
  loaduser() {
    this.authService.loaduser()
      .then((response) => {
        console.log(response);
        this.authService.setUserInfo({ 'user': response['user'] });
        let user = localStorage.getItem('userInfo')
        this.user = JSON.parse(user).user;

       
      })
      .catch((err) => {
          location.href = "/login"
        console.log(err)
      })
  }


getEvents(){
  this.miercoles=[];
  this.jueves=[];
  this.viernes=[];
  this.privados=[];
  var now= new Date();
  var n = now.getUTCHours();
  if(n<5){
    n=24+n; 
  }
  function compare( a, b ) {
    if ( a.date < b.date ){
      
      return -1;
    }
    if ( a.date > b.date ){
      return 1;
    }
    return 0;
  }


 now.setHours(n-5);
 console.log(now);
 this.eventosService.getAll()
 .then((r)=>{
  console.log(r)
   this.events=[];
   var events=r["events"]
   events.sort( compare );
   events.forEach(element => {
    //console.log(element);
    var date= new Date(element.date);
//var fecha= date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getUTCFullYear()+" hora: "+date.getHours()+":"+(date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
var fecha= " Hora: "+date.getHours()+":"+(date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
element.date=fecha;
if(now.getTime( ) > date.getTime()-(300*1000)){
element.isnow=true
}
if(element.pay!="premium"){
  this.events.push(element)
  switch (element.day) {
      case "jueves":
        this.jueves.push(element);
        break;
         case "viernes":
      this.viernes.push(element);
      break;
    default:
      break;
  }
}
else{
  this.privados.push(element);

}


  });
  this.cambioDia("jueves")
  this.cambioLugar(0)
 })
}

newEvent(){
  this.eventosService.create(this.eventForm.value)
  .then((response) => {
    //console.log(response);
    var emptyUser={
      name:"",
      date:"",
      id:"0"
    };
    this.eventForm.reset(emptyUser );
    this.getEvents();
  })
  .catch((err)=>{
   console.log(err);
})
}
edit(event: any) {

  var evento: Object = {
    id: event._id,
    name: event.name,
    date: event.date,
    dia: event.dia,
    lugar: event.lugar,
    show: event.show,
   

  }
  this.eventForm.reset(evento);
}
deleteEvent(id){
  this.eventosService.delete(id)
  .then((r)=>{
    this.getEvents();
  })
}
register(event){
  this.eventosService.register(event,this.user)
  .then((r)=>{
    console.log(r);
    this.loaduser();
  })
}

getRegisters(event){
  this.eventosService.registrados(event)
  .then((r:any)=>{
    console.log(r)
    let csvContent = "data:text/csv;charset=utf-8,";
    r["results"].forEach(element => {
      let row = element.user.name+","+element.user.correo;
      csvContent += row + "\r\n";
    });
    saveAs(csvContent,"registrados_"+r["results"][0].eventName+".csv");
  })

}


popIngresos(event){
  this.eventosService.getIngresoEvent(event)
  .then((r:any)=>{
    console.log(r)
  this.ingresosPop=r;
  this.vIngresos=true;
  })
}

verIngresos(event){
  this.eventosService.getIngresoEvent(event)
  .then((r:any)=>{
    console.log(r)
    let csvContent = "data:text/csv;charset=utf-8,";
    r.forEach(element => {
      let row = element.user.name+","+element.user.email+","+element.user.doc+","+element.user.company+","+ new Date(element.hora).toLocaleString();
      csvContent += row + "\r\n";
    });
    saveAs(csvContent,"ingresos_.csv");
  })

}
cambioDia(dia){
  this.dia=dia;
  switch (dia) {
      case "jueves":
        this.events=this.jueves;
        break;
         case "viernes":
          this.events=this.viernes;
      break;
    default:
      break;
  }
  this.cambioLugar(this.lugar);
}

cambioLugar(lugar){
  this.lugar=lugar;
  this.eventshow=this.events.filter((r)=>{return r.room== lugar})

}


}
