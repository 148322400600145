<h1>Preguntas</h1>
<div *ngIf="seting" class="bg-dark text-white w-50 container mt-5" style="position: fixed; top: 20%; left:25%;" >
    {{question.question}} <br>
    <textarea id="resp" cols="20" rows="5"></textarea>
   <br>
   <button class="btn btn-primary" (click)="responder()" >
       Enviar
   </button>
   <button class="btn btn-danger" (click)="seting=false" >
  Cerrar
</button>
    </div>
<div class="bg-dark w-75 container" >
    <ul>
        <li class="text-white" *ngFor="let question of questions">
            pregunta por {{question.user.name}}: {{question.question}} {{question.time}} 
        <button  (click)="select(question)" class="btn btn-primary" >Responder</button>
        <br>
       <div *ngIf="question.respuesta" >Respuesta:  {{question.respuesta}} </div> 
        </li>
    
    </ul>
</div>