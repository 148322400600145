import { Component, OnInit,Input, } from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations'
 
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  @Input() user ={"name":"Usuario Desconocido","correo":"test@test.com","rol":"usuario","puntos":"0","photoavatar":"https://selesoluciona.com:8351/fotosmarcos/profile.png"};
  testImage;
  showimage;
  constructor() { }

  ngOnInit(): void {
    this.showimage=false;
    this.testImage=this.user.photoavatar;
    this.testImagei();

  }


testImagei() {
  if(this.user.photoavatar){
    var tester=new Image();
    tester.onload=()=>{
      console.log(this.showimage);
      this.showimage=true;
      console.log(this.showimage); };
    tester.onerror=()=>{
      this.showimage=false;
      console.log(this.showimage); }
    tester.src=this.testImage;}
    else{
      this.showimage=false;
    }
}

 



}
