import { Component, OnInit,ElementRef, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-registro-pago',
  templateUrl: './registro-pago.component.html',
  styleUrls: ['./registro-pago.component.css']
})
export class RegistroPagoComponent implements OnInit {
  submitted = false;
  registerForm: FormGroup;
validationForm: FormGroup;
validate;
foto1Url;
foto2Url;
foto1;
foto2;
fotos;
videoWidth = 0;
videoHeight = 0;
@ViewChild('video', { static: false }) videoElement: ElementRef;
@ViewChild('canvas', { static: false }) canvas: ElementRef;

  constructor(private authService : AuthService, private router : Router,   private renderer: Renderer2) {
this.validationForm=new FormGroup({
  'correo': new FormControl('', [
    Validators.required,
    Validators.email
  ]),
})

    this.registerForm = new FormGroup({
      'name': new FormControl('', [
        Validators.required,
        Validators.minLength(4)
      ]),

      'correo': new FormControl('', [
        Validators.required,
        Validators.email
      ]),
      'id': new FormControl('', [
        Validators.required,
        Validators.min(1111)
      ]),
      'ciudad': new FormControl('', [
        Validators.required,
        Validators.minLength(3)
      ]),
      'rol': new FormControl('', [
        Validators.required,
        Validators.minLength(3)
      ]),
      'pay': new FormControl('', [
        Validators.required        
      ]),

    });
 

   }

  ngOnInit(): void {
  }

get f() { return this.registerForm.controls; }

register(){
  this.submitted = true;
  console.log(this.registerForm.controls);
  if (this.registerForm.invalid) {
    console.log("no enviado ");
    return;
}
console.log("enviado ");
  this.authService.createP(this.registerForm.value,this.foto1Url,this.foto2Url)
  .then((response) => {
    console.log(response);
    this.authService.setUserInfo({'user' : response['user']});
    this.router.navigate(['login']);
  })
  .catch((err)=>{
    this.router.navigate(['register']);
})

}

validation(){
this.authService.findPago(this.validationForm.value["correo"])
.then((response=>{
  this.validate=true;
  this.foto1=true;
  var user:Object ={
    correo:this.validationForm.value["correo"],
    rol:"usuario",
    pay:"premium"
  }
  this.registerForm.reset(user);
  this.activeVideo();
 })
)
.catch((err)=>{
  console.log(err);
  this.validate=true;
  this.foto1=true;
  var user:Object ={
    correo:this.validationForm.value["correo"],
    rol:"usuario",
    pay:"premium"
  }
  this.registerForm.reset(user);
  this.activeVideo();
})
}

handleError(error) {
  console.log('Error: ', error);
}

attachVideo(stream) {

  this.renderer.setProperty(this.videoElement.nativeElement, 'srcObject', stream);
  this.renderer.listen(this.videoElement.nativeElement, 'play', (event) => {
    this.videoHeight = this.videoElement.nativeElement.videoHeight;
    this.videoWidth = this.videoElement.nativeElement.videoWidth;
});

}

activeVideo(){
     
      var  constraints = {
        video: {
            
            width: { ideal: 1280 },
            height: { ideal: 720 }
        }
    };
    if (!!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) { 
      navigator.mediaDevices.getUserMedia(constraints).then(this.attachVideo.bind(this)).catch(this.handleError);
         } else {
             alert('Sorry, camera not available.');
         }



}

tomar(){
  this.renderer.setProperty(this.canvas.nativeElement, 'width', this.videoWidth);
  this.renderer.setProperty(this.canvas.nativeElement, 'height', this.videoHeight);
  this.canvas.nativeElement.getContext('2d').drawImage(this.videoElement.nativeElement, 0, 0);
  var data= this.canvas.nativeElement.toDataURL().replace(/\s/g, '+').replace(/^data:image\/png;base64,/, '');
  this.authService.sendfoto(data)
  .then((response)=>{
    console.log(response["url"]);
    this.foto1Url=response["url"];
this.foto1=false;
this.foto2=true;
  })
  .catch((err)=>{
alert("no encontramos tu rostro en la foto");
  })

}
tomar1(){
  this.renderer.setProperty(this.canvas.nativeElement, 'width', this.videoWidth);
  this.renderer.setProperty(this.canvas.nativeElement, 'height', this.videoHeight);
  this.canvas.nativeElement.getContext('2d').drawImage(this.videoElement.nativeElement, 0, 0);
  var data= this.canvas.nativeElement.toDataURL().replace(/\s/g, '+').replace(/^data:image\/png;base64,/, '');
  this.authService.sendfoto(data)
  .then((response)=>{
    console.log(response["url"]);
    this.foto2Url=response["url"];
this.foto2=false;
this.fotos=true;
this.validate=false;
  })
  .catch((err)=>{
    alert("no encontramos tu rostro en la foto");
  })

}


}

