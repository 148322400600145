<div class="d-flex justify-content-center mb-5 text-white">
    <h2>Bienvenido, Por favor ingrese sus datos</h2>
    
</div>

<div class="container w-50 bg-dark p-2 rounded-top shadow text-white" *ngIf="!validate && !fotos" >
    <form [formGroup]="validationForm" (ngSubmit)="validation()" >  
 
    <div class="form-group">
        <h3>Ingrese su correo electronico</h3>     
        <input class="form-control" type="email" placeholder="Correo electrónico" formControlName="correo"  [ngClass]="{ 'is-invalid': submitted && f.correo.errors }">
        <div *ngIf="submitted && f.correo.errors" class="invalid-feedback ">
            <div *ngIf="f.correo.errors.required">ingrese su email</div>
            <div *ngIf="f.correo.errors.email">ingrese un correo valido</div>
        </div> 
    </div> 
    <input type="submit" value="Validar" class="btn btn-info">
    </form>
</div>
<div class="container w-50 bg-dark p-2 rounded-top shadow text-white" *ngIf="validate && !fotos"  >
<video  width="400" height="225" autoplay #video></video> <br>
<button (click)="tomar()" *ngIf="foto1">Tomar foto 1</button>
<button (click)="tomar1()" *ngIf="foto2">Tomar foto 2</button>
<canvas #canvas style="display:none; "></canvas>
</div>

<div class="container w-50 bg-dark p-2 rounded-top shadow text-white" *ngIf="fotos" >
<form [formGroup]="registerForm" (ngSubmit)="register()" >  
    <div class="form-group">
    <h3>Ingrese su nombre completo</h3>
    <input type="text" class="form-control" placeholder="Nombre" formControlName="name"  [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
        <div *ngIf="f.name.errors.required">Ingrese su nombre</div>
        <div *ngIf="f.name.errors.minlength">El nombre debe tener al menos 4 caracteres</div>
    </div>
</div> 
<div class="form-group">
    <h3>Ingrese su correo electronico</h3>     
    <input disabled class="form-control" type="email" placeholder="Correo electrónico" formControlName="correo"  [ngClass]="{ 'is-invalid': submitted && f.correo.errors }">
    <div *ngIf="submitted && f.correo.errors" class="invalid-feedback ">
        <div *ngIf="f.correo.errors.required">ingrese su email</div>
        <div *ngIf="f.correo.errors.email">ingrese un correo valido</div>
    </div> 
</div> 
<div class="form-group">
    <h3>Ingrese su documento de identidad</h3>
    <input class="form-control" type="number" placeholder="Documento de identidad" formControlName="id" [ngClass]="{ 'is-invalid': submitted && f.id.errors }">
    <div *ngIf="submitted && f.id.errors" class="invalid-feedback ">
        <div *ngIf="f.id.errors.required">ingrese su documento</div>
        <div *ngIf="f.id.errors.min">ingrese un documento valido</div>
    </div> 
</div>
<div class="form-group">
    <h3>Ingrese su ciudad de vivienda</h3>
    <input type="text" class="form-control" placeholder="Ciudad" formControlName="ciudad"  [ngClass]="{ 'is-invalid': submitted && f.ciudad.errors }">
    <div *ngIf="submitted && f.ciudad.errors" class="invalid-feedback ">
        <div *ngIf="f.ciudad.errors.required">ingrese su ciudad</div>
        <div *ngIf="f.ciudad.errors.minlength">ingrese una ciudad valida</div>
    </div> 
</div>
<input type="hidden" class="form-control" formControlName="rol" value="usuario">
<input type="hidden" class="form-control" formControlName="pay" value="premium">
    <input type="submit" value="Ingresar" class="btn btn-info">
</form>
</div>
