

  <div class="row justify-content-center">
     
      <div style="padding: 0px;" class="col-md-8">
        <div style=" width: 100%; padding: 10px; border-radius: 15px;"  class="fondopant row justify-content-center">
  
      
          <div class="col-4  m-1 col-md-2 caja_numero">
            <div >
              <div class="numero_contador" id="dias" ></div>
             <div class="texto_contador">Días</div>
  
            </div>
            
          </div>
         
          <div  class="col-4 m-1  col-md-2 caja_numero ">
            <div >
              <div class="numero_contador" id="horas"></div>
             <div class="texto_contador">Horas</div>
            </div>
        
          </div>
         
          <div class="col-4 m-1  col-md-2 caja_numero ">
            <div >
              <div class="numero_contador" id="minutos"></div>
             <div class="texto_contador">Minutos</div>
            </div>
            
          </div>
         
          <div class="col-4  m-1 col-md-2 caja_numero ">
            <div>
              <div class="numero_contador" id="segundos"></div>
             <div class="texto_contador" >Segundos</div>
            </div>
            
          </div>
  
         </div>
      </div>
    
  </div>













