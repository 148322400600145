<div *ngIf="!log" >
    <div class="row justify-content-center">
        <div class="col-md-3 col-8 pc">
            <img src="../../../assets/mascartagena/kv.jpg" width="90%" alt="">
        </div>
        <div class="col-md-1">

        </div>
        <div class="col-md-4 col-10">
            <img src="../../../assets/images/newfolder/LogoLAD.png"  width="100%" />
            <div class="caja_formulario form-signin">

                
                    <h1>Ingresa con el NIT de la empresa (sin dígito de verificación) </h1>
        
                    <form [formGroup]="icompanyForm" (ngSubmit)="ingresoc()">
                        <div class="form-group">
        
                            <input class="form-control" type="text" placeholder="NIT" formControlName="nit"
                                [ngClass]="{ 'is-invalid': submitted && f3.nit.errors }">
                            <div *ngIf="submitted && f3.nit.errors" class="invalid-feedback ">
                                <div *ngIf="f3.nit.errors.required">ingrese su documento</div>
        
                            </div>
                        </div>
                        <br>
                        <input type="submit" value="Ingresar" class="btn btn-orange">
                    </form>
                

            </div>
        </div>
    </div>


    
           
<br>
<!-- <a href="../../../assets/tutorial/Guiaempresa/index.html" target="_blank" rel="noopener noreferrer" class="btn btn-boton mr-3">TUTORIAL EMPRESAS </a> -->
        </div>
   



<div *ngIf="log" >

    
<div class="row mt-1 ml-5 justify-content-start ">
    <div class="col-md-2 ml-5 col-6">
       <img  src="../../../assets/Captura.PNG" width="100%" alt="">
    </div>

 </div>
 
 
 <div class="row mt-5 justify-content-center" style="overflow-x: hidden;" *ngIf="!contacts">
 
    <div #escarapela id="escarapela" *ngIf="user" class="col-md-3 col-10">
       <app-profile [user]="user" [Vqr]="true">
       </app-profile>
    </div>
    <div class="col-md-3 justify-content-center " style="text-align: center;">
       <button class="btn btn-orange m-1" (click)="downloadAsPDF()"> Descargar Escarapela</button> <br>
       <button class="btn btn-orange m-1" (click)="contacts=true">Ver contactos </button> <br>
       <a href="/lector"> <button class="btn btn-orange m-1">Leer escarapela</button></a> <br>

    </div>
 
 
 
 </div>
 <div *ngIf="contacts" class="row mt-5 justify-content-center">
    <div class="col-md-8">
       <div style="float: right;">
          <span style="font-size: 30px; cursor: pointer; color: white; font-weight: bolder;  "
             (click)="contacts=false">X</span>
       </div>
       <app-contactos>
 
       </app-contactos>
    </div>
 </div>

</div>