import { Component } from '@angular/core';
import Swal from 'sweetalert2';
import {NotificaService} from '../../services/notifica.service'
@Component({
  selector: 'app-formulario',
  templateUrl: './formulario.component.html',
  styleUrls: ['./formulario.component.css']
})
export class FormularioComponent {
  constructor(
    private notiService: NotificaService,
  ){

  }
  formData: { titulo: string; contenido: string } = {
    titulo: '',
    contenido: '',
  };

  onSubmit() {
    if (this.formData.titulo && this.formData.contenido) {
      console.log('Título:', this.formData.titulo);
      console.log('Contenido:', this.formData.contenido);
      var data={
        title:this.formData.titulo,
        body:this.formData.contenido
      }
      this.notiService.enviar(data)
      .then((res)=>{
        Swal.fire("enviado con exito");
        this.formData.titulo="";
        
        this.formData.contenido="";
      })
      .catch((err)=>{
        console.log(err);
       
      })
    }
  }
}
