<div class="lienzo">
  
</div>
<div class="card caja_formulario" *ngIf="qrvalue" style="width: 100%; text-align: center">
  <div class="card-back" [ngClass]="{
      azul:
        user.ticket &&
        (user.ticket.type == '2' ||
          user.ticket.type == '2' ||
          user.ticket.type == '2'),
      naranja:
        user.ticket && (user.ticket.type == '2' || user.ticket.type == '1'),
      verde:
        user.ticket &&
        (user.ticket.type == '3' ||
          user.ticket.type == '4' ||
          user.ticket.type == '11'),
      blanco: user.ticket && user.ticket.type == '5',
      morada: user.ticket && user.ticket.type == '7',
      roja: user.ticket && user.ticket.type == '6',
      morado2: user.ticket && user.ticket.type == '13',
      negra:
        user.ticket && (user.ticket.type == '8' || user.ticket.type == '13')
    }">
    <div *ngIf="Vqr" style="margin-top: 2%; margin-left: 5%; margin-bottom: 14px">

      <img src="../../../assets/images/newfolder/LogoLAD.png" class="mt-3" width="80%" />
    </div>

    <div style="width: 100%; border-radius: 10px; overflow: hidden;">
      <ngx-qrcode [elementType]="elementType" [value]="qrvalue" cssClass="aclass" [errorCorrectionLevel]="correctionLevel" alt="Qr code" *ngIf="Vqr">
      </ngx-qrcode>
    </div>
    <div class="card-body">
      <h5 style="font-size: 22px; font-weight: 600; color: white" class="card-title mb-2">
        {{ user.name | uppercase }}
      </h5>
      <h5 style="font-size: 20px; font-weight: lighter; color: white;" class="card-title mt-2 mb-1">
        {{ user.email | uppercase }}
      </h5>
      <img style="margin-top: 10px;" src="../../../assets/images/newfolder/LineaDivisoraEscarapela.png" alt="">
      <p style="font-size: 16px; display: block; line-height: 26px;margin-top:10px" class="card-text">
        <span *ngIf="!user.nit" style="font-weight: 600">
          <span style="font-size: 18; font-weight: lighter; ">{{ user.position }}
            <br>
            {{ user.company }}
          </span> 
          <br />
          <div *ngIf="!Vqr">{{ user.phone }} <br /></div>
        </span>
        <span *ngIf="user.nit">
          <a href="/empresap/{{ user._id }}">
            <button *ngIf="user.nit && !Vqr" class="btn btn-orange">
              Visitar
            </button></a>
          <br />
          <span *ngIf="user.nit && !Vqr" style="font-size: 15px">Perfil de empresa</span>
        </span>
      </p>
    </div>
  </div>
</div>