import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AuthService } from "../../services/auth.service";
import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Component({
  selector: 'app-contactos',
  templateUrl: './contactos.component.html',
  styleUrls: ['./contactos.component.css']
})
export class ContactosComponent implements OnInit {

  user;
  contacts=[];
    companies:any[]=[];
    empresa: any;
    @Input() id=""
    constructor(private authService:AuthService) { }
  
    ngOnInit(): void {
      let user = localStorage.getItem('userInfo')
      this.user = JSON.parse(user).user;
      if(!this.user.nit){
        this.loaduser();
      }
      else{
      this.loadCompany();
      }
      
    }
    loadCompany(){
      this.authService.loadcompany(this.id)
      .then((r)=>{
        console.log(r);
        this.user=r["user"];
        this.loadContacts();
        
      })
    }
    loaduser() {
      this.authService.loaduser()
        .then((response) => {
          console.log(response);
          this.authService.setUserInfo({ 'user': response['user'] });
          let user = localStorage.getItem('userInfo')
          this.user = JSON.parse(user).user;
          this.loadContacts();
          this.loadEmpresas();
          
      
         
        })
        .catch((err) => {
           //location.href = "/login"
          console.log(err)
        })
    }
  
  loadEmpresas(){
    if(this.user.empresas){
      this.user.empresas.forEach(element => {
        this.authService.loadcompany(element)
    .then((r)=>{
      this.companies.push(r["user"]);
    })
        
      });
    
    }
 
  }
  
  loadContacts(){
    this.user.contactos.forEach(element => {
      this.authService.loadcontact(element)
  .then((r:any)=>{
    var user=r["user"]
    let comentarios =  this.user.contactosComment.filter(item=> item.id == user._id);
    console.log("com",comentarios)
    user.comentarios=comentarios.map(item=>{return item.comment})
    this.contacts.push(user);
  })
      
    });
  
  }
  downloadContacts(){
    var  contacts_=this.contacts.map((contact)=>{return {"nombre":contact.name,"Empresa":contact.company,"Cargo":contact.position,"celular":contact.phone}})
    this.exportAsExcelFile(contacts_,"contactos")
  }
  
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
    FileSaver.saveAs(data, fileName + '_export_' + new  Date().getTime() + EXCEL_EXTENSION);
  }
  
  }
  