import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
 url="https://selesoluciona.com/eventopragma";
  constructor(private http : HttpClient) { }

  public isAuthenticated() : Boolean {
    let userData = localStorage.getItem('userInfo')
    if(userData && JSON.parse(userData)){
      return true;
    }
    return false;
  }

  public responder(user,question,respuesta,event){
    return this.http.post(this.url+'/responder', {"user":user,"question":question,"respuesta":respuesta,"event":event}).toPromise()
  }



  public useredit(nuser){
    return this.http.post(this.url+'/useredit', {nuser}).toPromise()
  }
  public usereditAdmin(nuser){
    return this.http.post(this.url+'/usereditadmin', {nuser}).toPromise()
  }
  public upfotoavatar(foto,user){
    return this.http.post(this.url+'/upfotoavatar', {foto,user}).toPromise()
  }
  public setUserInfo(user){
    localStorage.setItem('userInfo', JSON.stringify(user));
  }
  public sendToken(token){
    return this.http.post(this.url+'/token_validate', {recaptcha: token})
  }
  
  public aprobar(correo){
    return this.http.post(this.url+'/aprobeuser', {correo}).toPromise()
  }
  // public validate(email) {
  //   return this.http.post(this.url+'/auth', {'correo' : email}).toPromise()
  // }
  public loaduser(){
    return this.http.get(this.url+"/loaduser").toPromise()
  }
  public validate(correo: string): Observable<object> {
    return this.http.post<{token: string}>(this.url+'/auth', {correo})
      .pipe(
        map(result => {
          console.log(result);
          // localStorage.setItem('access_token', result["user"].token);
          // localStorage.setItem('user', JSON.stringify(result));
          return result;
        })
      );
  }
  public validateid(doc: string): Observable<object> {
    return this.http.post<{token: string}>(this.url+'/authdoc', {doc})
      .pipe(
        map(result => {
          console.log(result);
          // localStorage.setItem('access_token', result["user"].token);
          // localStorage.setItem('user', JSON.stringify(result));
          return result;
        })
      );
  }


  // public create(user){
  //   return this.http.post(this.url+'/create', {user}).toPromise()
  // }

 public create(user): Observable<object> {
    return this.http.post<{token: string}>(this.url+'/createi', {user})
      .pipe(
        map(result => {
          console.log(result);
          // localStorage.setItem('access_token', result["user"].token);
          // localStorage.setItem('user', JSON.stringify(result));
          
          return result;
        })
      );
  }
  public createAuto(user,ticket){
    return this.http.post(this.url+'/createauto', {user,ticket}).toPromise()
  }
  

public addTicket(ticket){
  return this.http.post(this.url+'/addticket', {ticket}).toPromise()
}
public deleteTicket(id){
  return this.http.post(this.url+'/deleteticket', {id}).toPromise()
}
public aprobeTicket(id,ticket){
  return this.http.post(this.url+'/aprobeticket', {id,ticket}).toPromise()
}




  public setrol(rol,correo){
    return this.http.post(this.url+'/setrol', {rol,correo}).toPromise()
  }
  public deleteuser(correo){
    return this.http.post(this.url+'/deleteuser', {correo}).toPromise()
  }
  public getTickets(){
    return this.http.get(this.url+'/tickets').toPromise()
  }
  public getusers(){
    return this.http.get(this.url+'/users').toPromise()
  }
  public validateP(foto,email){
    return this.http.post(this.url+'/photovalidate', {"foto":foto,"correo":email}).toPromise()
  }
  
  public createP(user,f1,f2){
    return this.http.post(this.url+'/create', {"user":user,"foto1":f1,"foto2":f2}).toPromise()
  }
  public findPago(email){
    return this.http.post(this.url+'/findpago', {email}).toPromise()
  }
  public geturl(){
    return this.http.get(this.url+'/conferenceurl', ).toPromise()
  }
  public findConfer(correo){
    return this.http.post(this.url+'/findconfer', {correo}).toPromise()
  }
public sendfoto(data){
  return this.http.post(this.url+"/foto1",{data}).toPromise()
}
public upfoto(foto,user){
  return this.http.post('https://selesoluciona.com:8366/upfoto', {foto,user}).toPromise()
}
public loadcontact(id){
  return this.http.post(this.url+"/loadcontact",{id}).toPromise()
}
public loadcontactid(id){
  return this.http.post(this.url+"/loadcontactid",{id}).toPromise()
}
public addContact(id,comment:any=""){
  return this.http.post(this.url+"/addcontact",{id,comment}).toPromise()
}
public addCompany(id,comment:any=""){
  return this.http.post(this.url+"/addcompany",{id,comment}).toPromise()
}
public getStatus(){
  return this.http.get(this.url+'/status',).toPromise()
}
public loadcompany(id){
  return this.http.post(this.url+"/empresas/loadempresa",{id}).toPromise()
}
public getPatreon(){
  return this.http.get(this.url+'/getpatrocinadores',).toPromise()
}

public estadoNetworking(id, nw){
  return this.http.post(this.url+"/estadoNetworking",{"id":id, "networking":nw }).toPromise()
}

public estadoContactoNetworking(id_solicitud){
  return this.http.post(this.url+"/estadoContactoNetworking",{"id":id_solicitud}).toPromise()
}

public usuariosNetworking(){
  return this.http.get(this.url+'/usuariosNetworking').toPromise()
}

public contactosNetworking(id){
  return this.http.get(this.url+'/contactosNetworking/'+id).toPromise()
}

public limpiarNetworking(){
  return this.http.get(this.url+'/limpiarNetworking').toPromise()
}

public contactarNetworking(id_user, id_contacto, emailcontacto, mensaje){
  return this.http.post(this.url+'/contactarNetworking', {"id_contacto":id_contacto, "emailcontacto":emailcontacto, "id_user":id_user, "mensaje":mensaje}).toPromise()
}
public  newCert(certificado){
  return this.http.post(this.url+'/newCert', {certificado}).toPromise()
}
public getCertificados(){
  return this.http.get(this.url+'/getCertificados', ).toPromise()
}


public newCortesia(cortesia){
  return this.http.post(this.url+"/newCortesia",{cortesia}).toPromise()
}
public getCortesia(id){
  return this.http.post(this.url+"/getCortesia",{id}).toPromise()
}
public deleteCortesia(id){
  return this.http.post(this.url+"/deleteCortesia",{id}).toPromise()
}
public getAllCortesia(){
  return this.http.get(this.url+"/getallCortesia",).toPromise()
}
public verifyCortesia(id){
  return this.http.post(this.url+"/verifyCortesia",{id}).toPromise()
}
public removefromcortesia(cortesia,id){
  return this.http.post(this.url+"/deleteusercorteria",{cortesia,id}).toPromise()
}

public newComentario(comentario){
  return this.http.post(this.url+"/newComentario",{comentario}).toPromise()
}
public getComentarios(){
  return this.http.get(this.url+"/getComentarios",).toPromise()
}

}
