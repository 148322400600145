<div class="lienzo">
    <div class="row">
        <div class="col-md-12 text-center">
            <h1 class="mt-3" style="color: white; font-size:200%">¡BIENVENIDOS AL LATAM ARCHITECTURE DAY!</h1>
        </div>
    </div>
    <div class="row justify-content-center align-content-center align-items-center" style="overflow-x: hidden"
        *ngIf="!contacts">
        <div *ngIf="!estado" class="col-md-3 mt-5">
            <div #escarapela id="escarapela" *ngIf="user">
                <app-profile [user]="user" [Vqr]="true"> </app-profile>
            </div>
        </div>

        <div *ngIf="!estado" class="col-md-3 justify-content-center mt-5" style="text-align: center">
            <a routerLink="/agenda"> <button class="btn btn-orange w-100 m-1">Ver agenda</button></a> <br>

            <button class="btn btn-orange w-100 m-1" (click)="downloadAsPDF()">
                Descargar Escarapela
            </button>
            <button routerLink="/usuarios" *ngIf="user.rol=='admin'"
                class="btn btn-orange w-100 m-1">Administración</button><br>
            <!--  <button routerLink="/memorias" class="btn btn-orange w-100 m-1">
                Ver conferencias
            </button> -->
            <br />
            <!--  <button
                routerLink="/comentarios"
                class="btn btn-orange w-100 m-1 rainbow">
                Lanza tu idea para +CTG2024
            </button> -->


            <a href="/lector"> <button class="btn btn-orange w-100 m-1">Agregar contacto</button></a> <br>

            <button class="btn btn-orange w-100 m-1" (click)="contacts = true">
                Ver mis contactos
            </button>
            <br />

            <!--  <button class="btn btn-orange w-100 m-1">Ver conferencias</button><br>
       -->

            <button *ngIf="certificado" class="btn btn-orange w-100 m-1" (click)="asistencia = true">
                Descargar certificado de asistencia
            </button>
            <br />

            <!--  <a href="/profile">
                <button class="btn btn-orange m-1 w-100">Editar mis
                    datos</button></a>
            <br />  -->

            <!-- <button class="btn btn-orange m-1 w-100" *ngIf="showAddToHomeScreenButton"  (click)="addToHomeScreen()" >Agregar a mi pantalla principal</button> -->

            <a href="{{ transmisionUrl }}"><button class="btn btn-orange w-100 m-1" *ngIf="transmisionUrl != null">
                    Ver transmision
                </button></a>
        </div>

        <div *ngIf="estado" class="col-md-12 justify-content-center mt-5" style="text-align: center;">
            
            <!-- <h1 style="font-weight: lighter; font-size:250%; color:white">Faltan:</h1>
            <app-cuenta></app-cuenta> -->

            <button *ngIf="certificado" class="btn btn-orange  m-1" (click)="asistencia = true">
                Descargar certificado de asistencia
            </button>

        </div>

        


    </div>
    <div *ngIf="contacts" class="row mt-5 justify-content-center">
        <div class="col-md-8">
            <div style="float: right">
                <span style="
            font-size: 30px;
            cursor: pointer;
            color: white;
            font-weight: bolder;
          " (click)="contacts = false">X</span>
            </div>
            <app-contactos> </app-contactos>
        </div>
    </div>
    <br /><br>

    <!--     <div class="pc">
        <div
            style="
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: white;
        color: #822be4;
      ">
            <div class="row w-100 justify-content-md-center p-2">
                <div class="col-md-2 col-pie mr-4">
                    <img
                        src="../../../assets/mascartagena/Logo1 Economia Creativa.png"
                        style="margin-top: 5px"
                        width="100%"
                        width="100%" />
                </div>

                <div class="col-md-4 col-pie">
                    <h3 style="color: #822be4; margin: 1px; text-align: center">
                        <a
                            style="font-size: 16px; color: #822be4; font-weight: 600"
                            href="https://wa.link/szz3nr"><i
                                class="bi bi-whatsapp"></i> +57 314 4792686</a>
                    </h3>
                    <h3
                        style="
              font-size: 16px;
              color: #822be4;
              text-align: center;
              margin: 1px;
              font-weight: 600;
            ">
                        <i class="bi bi-envelope"></i>
                        inscripciones@mascartagena.co <br />
                    </h3>
                </div>
                <div class="col-md-3 col-pie">
                    <img
                        src="../../../assets/mascartagena/Firma-camino-1.jpg"
                        style="background-color: white; border-radius: 10px"
                        width="100%" />
                </div>
            </div>
        </div>
    </div>
    <div class="cel">
        <div style="background-color: white; color: #822be4">
            <div class="row w-100 justify-content-md-center p-2">
                <div class="col-md-2 col-pie mr-4">
                    <img
                        src="../../../assets/mascartagena/Logo1 Economia Creativa.png"
                        style="margin-top: 5px"
                        width="100%"
                        width="100%" />
                </div>

                <div class="col-md-4 col-pie">
                    <h3 style="color: #822be4; margin: 1px; text-align: center">
                        <a
                            style="font-size: 16px; color: #822be4; font-weight: 600"
                            href="https://wa.link/szz3nr"><i
                                class="bi bi-whatsapp"></i> +57 314 4792686</a>
                    </h3>
                    <h3
                        style="
              font-size: 16px;
              color: #822be4;
              text-align: center;
              margin: 1px;
              font-weight: 600;
            ">
                        <i class="bi bi-envelope"></i>
                        inscripciones@mascartagena.co <br />
                    </h3>
                </div>
                <div class="col-md-3 col-pie">
                    <img
                        src="../../../assets/mascartagena/Firma-camino-1.jpg"
                        style="background-color: white; border-radius: 10px"
                        width="100%" />
                </div>
            </div>
        </div>
    </div>
</div> -->
    <br>
    <br><br>
    <br>
    <div class="fixed-bottom">

        <img class="imag" src="../../../assets/images/newfolder/Footer.png" />
    </div>

</div>

<div *ngIf="popuser" class="pop" style="z-index: 99999;">
    <!-- <button style="position: absolute; top:15px;right:15px; width: 30px;" (click)="popuser=false"  class="btn btn-danger" >X</button> -->
    <div class="row justify-content-center">
        <div class="col-md-4 mt-4">
            <h1 style="text-align: center; color: white">
                Bienvenid@ al <br> LATAM Architecture Day
            </h1>
            <h2 style="text-align: center; color: white">
                Por favor completa tus datos para obtener tu escarapela
            </h2>
            <div class="caja_formulario">
                <form [formGroup]="userForm" (ngSubmit)="send()">
                    <div class="form-group">
                        <label style="color: white; margin-bottom: 1px">Nombre:</label>
                        <input type="text" class="form-control" formControlName="name"
                            [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                        <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                            <div *ngIf="f.name.errors.required">Ingrese su
                                nombre</div>
                            <div *ngIf="f.name.errors.minlength">
                                El nombre debe tener al menos 3 caracteres
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label style="color: white; margin-bottom: 1px">Empresa:</label>
                        <input class="form-control" type="text" formControlName="company"
                            [ngClass]="{ 'is-invalid': submitted && f.company.errors }" />
                        <div *ngIf="submitted && f.company.errors" class="invalid-feedback">
                            <div *ngIf="f.company.errors.required">ingrese su
                                empresa</div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label style="color: white; margin-bottom: 1px">Cargo:</label>
                        <input class="form-control" type="text" formControlName="position"
                            [ngClass]="{ 'is-invalid': submitted && f.position.errors }" />
                        <div *ngIf="submitted && f.position.errors" class="invalid-feedback">
                            <div *ngIf="f.position.errors.required">ingrese su
                                cargo</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label style="color: white; margin-bottom: 1px">Celular:</label>
                        <input class="form-control" type="text" formControlName="phone"
                            [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" />
                        <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                            <div *ngIf="f.phone.errors.required">ingrese su
                                celular</div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label style="color: white;">País:</label>
                        <select formControlName="pais" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.pais.errors }">
                            <option value="">Seleccione</option>
                            <option value="{{pais}}" *ngFor="let pais of paises; let i = index ">
                                {{pais}}
                            </option>
                        </select>
                        <div *ngIf="submitted && f.pais.errors" class="invalid-feedback">
                            <div *ngIf="f.pais.errors.required">Seleccione uno</div>

                        </div>
                    </div>

                    <input type="submit" value="Confirmar" class="btn btn-orange w-100" />
                </form>
            </div>
        </div>
    </div>
</div>










<div *ngIf="asistencia" class="pop2">
    <br><br><br>
    <div class="popmenu" (click)="asistencia = false" style="margin-top: 30px;"><span style="color: #fff; font-size: 35px;">X</span> </div>
    <div class="row m-5 justify-content-center">
        <div class="col-md-3" style="text-align: center">
            <button class="btn btn-primary" style="font-size: 18px" (click)="downCert()">
                Descargar certificado
            </button>
        </div>
    </div>

    <div class="row mt-2 justify-content-center">
        <div class="col-md-8 col-11" style="text-align: center">
            <div style="
          max-width: 100%;
          overflow: auto;
          max-height: 700px;
          text-align: center;
          border: 0px solid white;
        ">
                <div #certificadoasistencia id="certificadoasistencia"
                    style="width: 800px; position: relative; height: max-content" *ngIf="user">
                    <img *ngIf="user.ticket.type == 1" src="../../../assets/certificado-presencial.jpg" width="100%" />

                    <img *ngIf="user.ticket.type == 2" src="../../../assets/certificado-virtual.jpg" width="100%" />

                    <div style="
              position: absolute;
              top: 45%;
              left: -5px;
              font-size: 32px;
              font-weight: 500;
              color: white;
              width: 100%;
              text-align: center;
              
            ">
                        {{ user.name | uppercase }} {{ user.apellido | uppercase
                        }}
                    </div>
                    <!-- <div
                        style="position:absolute; top:38%; left:-50px; font-size:22px;font-weight: 500; color:white;  width:100%; text-align:center">
                        CC: {{user.doc | uppercase}} </div> -->
                </div>
            </div>
        </div>
    </div>
</div>