import { Component, OnInit,ElementRef, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { saveAs } from 'file-saver';
import { HostListener } from "@angular/core";
import  Swal from 'sweetalert2'; 
import { first } from 'rxjs/operators';
@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.css']
})
export class AvatarComponent implements OnInit {

 

  videoWidth = 0;
videoHeight = 0;
cameras=[];
camera = 0;
foto;
marco;
subdoc;
img;
user;
grabado;
grabando2;
screenHeight: number;
screenWidth: number;
timeleft = 19;
 mediaRecorder;
 recordedBlobs=[];
 sourceBuffer;
 stream;
grabando;
  @ViewChild('video', { static: false }) videoElement: ElementRef;
  @ViewChild('canvasf', { static: false }) canvasf: ElementRef;
  @ViewChild('canvas2', { static: false }) canvas2: ElementRef;
  constructor( private authService : AuthService, private router : Router,   private elRef:ElementRef,  private renderer: Renderer2) { 
    this.getScreenSize();
  }
  @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
          this.screenHeight = window.innerHeight;
          this.screenWidth = window.innerWidth;
          if(this.screenWidth>this.screenHeight){
            this.marco= "./assets/marco_1920x1080_02.png";
            this.activeVideo();
          }else{
            this.marco="./assets/marco_1920x1080_02.png";
            this.activeVideo();
          }
         
    }
  ngOnInit(): void {
   
    this.cameras=[];
    this.recordedBlobs=[];
  this.foto=false;
    
  
    let user = localStorage.getItem('userInfo')
    this.user= JSON.parse(user).user;
    console.log(this.user);
    this.loaduser();
    this.getcameras();
  }

  // loaduser(){
  //   let user = localStorage.getItem('userInfo')
  //   this.user= JSON.parse(user).user;
  
  // }
  loaduser(){
    this.authService.loaduser()
    .then((response)=>{
      this.authService.setUserInfo({'user' : response['user']});
      let user = localStorage.getItem('userInfo')
      this.user= JSON.parse(user).user;
    })
    .catch(()=>{
      location.href="/login"
    })
  }
  attachVideo(stream) {

    
 
    this.renderer.setProperty(this.videoElement.nativeElement, 'srcObject', stream);
    this.renderer.listen(this.videoElement.nativeElement, 'play', (event) => {
      this.videoHeight = this.videoElement.nativeElement.videoHeight;
      this.videoWidth = this.videoElement.nativeElement.videoWidth;      
      var videotag_width = this.videoElement.nativeElement.offsetWidth;
var videotag_height = this.videoElement.nativeElement.offsetHeight;
// this.elRef.nativeElement.querySelector('#canvas2').style.width= videotag_width.toString()+"px";
// this.elRef.nativeElement.querySelector('#canvas2').style.height= videotag_height.toString()+"px";
this.elRef.nativeElement.querySelector('#cajavideo').height=videotag_height;
this.elRef.nativeElement.querySelector('#cajavideo').style.height=videotag_height;
this.elRef.nativeElement.querySelector('#canvas2').width=videotag_width;
this.elRef.nativeElement.querySelector('#video').muted="true";
this.elRef.nativeElement.querySelector('#canvas2').height=videotag_height;
 this.img = new Image;
       this.img.onload = ()=> {
         this.elRef.nativeElement.querySelector('#canvas2').getContext('2d').drawImage(this.img, 0, 0, videotag_width, videotag_height);
       }
       this.img.src =this.marco;
  });
  this.doLoad();
  
  }

  

gotDevices(deviceInfos) {
  console.log("ordenando camaras");
      for (let i = 0; i !== deviceInfos.length; ++i) {
        var name="";
   
        var  deviceInfo = deviceInfos[i];
        if (deviceInfo.kind == 'videoinput') {
          console.log(deviceInfo);
          name = deviceInfo.deviceId;
          this.cameras.push(name);
         
        } else {
          console.log('Some other kind of source/device ');
        }
      }
      
      }
      
    
    
      getcameras(){
        console.log("leyendo camaras");
        navigator.mediaDevices.enumerateDevices().then(this.gotDevices.bind(this)).catch(this.handleError);
        this.activeVideo();
      }
  
  rotar(){
    if(this.camera<this.cameras.length-1){
        this.camera=this.camera+1;
        this.activeVideo();
    }
    else{
        this.camera=0;
        console.log(this.cameras[this.camera]);
        this.activeVideo();
    }
    
}

  activeVideo(){
    const videoSource = this.cameras[this.camera];
    var constraints = {
      audio: true,
      video: {       width: 1280,
       height: 720, deviceId: videoSource ? {exact: videoSource} : undefined}
    };
      
      if (!!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) { 
        navigator.mediaDevices.getUserMedia(constraints).then(this.attachVideo.bind(this)).catch(this.handleError);
           } else {
               alert('Sorry, camera not available.');
           }
  
  
  
  }
  handleError(error) {
    setTimeout( ()=> {
      this.activeVideo();
      console.log("recargando");
    }, 2000);
    console.log('Error: ', error);
  
  
  }
  
  
  tomar(){
    this.foto=true;
   
      this.renderer.setProperty(this.canvasf.nativeElement, 'width', this.videoWidth);
      this.renderer.setProperty(this.canvasf.nativeElement, 'height', this.videoHeight);
      this.canvasf.nativeElement.getContext('2d').drawImage(this.videoElement.nativeElement, 0, 0);
      // var img = new Image;
      //   img.onload = ()=> {
          this.canvasf.nativeElement.getContext('2d').drawImage(this.img, 0, 0, this.videoWidth,this.videoHeight);
        
      //  }
      //   img.src = this.marco;
       var data= this.elRef.nativeElement.querySelector('#canvasf').toDataURL().replace(/\s/g, '+').replace(/^data:image\/png;base64,/, '');
      
  
    this.authService.upfotoavatar(data,this.user)
    .then((response)=>{
      console.log(response);
 this.loaduser();
    })
    .catch((err)=>{
 
    })
  
  }
  descargar(){
    var data= this.canvasf.nativeElement.toDataURL("image/png").replace("image/png", "image/octet-stream");
    saveAs(data,"recuerdo.png");
 this.foto=false;
 this.canvasf.nativeElement.getContext('2d').clearRect(0, 0, this.videoWidth,this.videoHeight);
 this.activeVideo();
  }
back(){
  this.activeVideo();
  this.canvasf.nativeElement.getContext('2d').clearRect(0, 0, this.videoWidth,this.videoHeight);
  this.foto=false;
  this.grabando=false;
  this.grabado=false;
  this.elRef.nativeElement.querySelector('#videof').style.visibility= "hidden";
}


  computeFrame() {     
    var video = this.elRef.nativeElement.querySelector('#video');
    var videotag_width = this.videoElement.nativeElement.offsetWidth;
    var videotag_height = this.videoElement.nativeElement.offsetHeight;
    this.elRef.nativeElement.querySelector('#canvas2').getContext("2d").drawImage(video, 0, 0, videotag_width,videotag_height);

  
     this.elRef.nativeElement.querySelector('#canvas2').getContext("2d").drawImage(this.img, 0, 0, videotag_width,videotag_height);

    //console.log(w);
    //console.log(h);

    return;
  }

  timerCallback () {
   
    
    var video = this.elRef.nativeElement.querySelector('#video');
      
    if (video.paused || video.ended) {
      return;
    }
   this.computeFrame();
   
    setTimeout(()=> {
        this.timerCallback();
      }, 0);
  }

  doLoad () {

   this.stream= this.elRef.nativeElement.querySelector('#canvas2').captureStream(12); // frames per second
console.log('Started stream capture from canvas element: ', this.stream);

    var video = this.elRef.nativeElement.querySelector('#video');
   var c1 = this.elRef.nativeElement.querySelector('#canvas2')
   var ctx1 = c1.getContext("2d");
  

    video.addEventListener("play", ()=> {
       
        this.timerCallback();
      }, false);
  }


  startRecording() {

  }
  

   download(){

}
   

onFileChange(event) {
  let reader = new FileReader();

  if (event.target.files.length > 0) {
    const file = event.target.files[0];
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.authService.upfotoavatar(reader.result,this.user)
      .then((response)=>{
        console.log(response);
   this.loaduser();
   this.subdoc=false;
      })
      .catch((err)=>{
   
      })
    };

  }
}


}
