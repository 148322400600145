<div class="row justify-content-center" *ngFor="let sol of user.contactos"  style="background-color: white; border: black; border-radius: 10px;" >
  
    <div class="col-md-4">
       
            <img *ngIf="sol.photoavatar" src={{sol.photoavatar}}  width="95%" style="border-radius: 40%;" alt="">
            <img *ngIf="!sol.photoavatar" src="https://selesoluciona.com:8351/fotosmarcos/profile.png"  width="80%" style="border-radius: 40%;"  alt="">

    </div>
    <div class="col-md-8 justify-content-start " >
        <h4 style="color: black;margin: 1px; ">{{sol.name}}</h4>
        <h4 style="color: black;margin: 1px; ">{{sol.correo}}</h4>
        <button class="btn btn-primary mt-0 mb-2"  *ngIf="!user.contactosenv.includes(sol.correo)" (click)="contactar(sol)">Contactar</button>
    </div>

</div>