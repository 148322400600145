import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-euser',
  templateUrl: './euser.component.html',
  styleUrls: ['./euser.component.css']
})
export class EuserComponent implements OnInit {
  userForm: FormGroup;
  user;
 submitted;
 testImage;
  showimage;
  constructor(private authService : AuthService, ) {
    
    this.userForm = new FormGroup({
       
      'name': new FormControl('', [
        Validators.required,
       
      ]),
      'doc': new FormControl('', [
        Validators.required,
       
      ]),
  
      'company': new FormControl('', [
        Validators.required,
        ]),
        'position': new FormControl('', [
          Validators.required,
          ]),
          'phone': new FormControl('', [
            Validators.required,
            ]),
            'email': new FormControl('', [
              Validators.required,
              ]),
   

    })
   }

  ngOnInit(): void {
    let user = localStorage.getItem('userInfo')
    this.user= JSON.parse(user).user;
this.loaduser();
this.showimage=false;
this.testImage=this.user.photoavatar;

  }

  get f() { return this.userForm.controls; }


  loaduser() {
    console.log("cargando usuario")
    this.authService.loaduser()
      .then((response) => {
        console.log("user: ",response); 
        if(response!=null){
         
          this.authService.setUserInfo({ 'user': response['user'] });
          let user = localStorage.getItem('userInfo')
          this.user = JSON.parse(user).user;
  
          this.edituser();
          // this.testImagei();
        }
      else{
        location.href = "/login"
      }
       
      })
      .catch((err) => {
           location.href = "/login"
        console.log(err)
      })
  }

 
 send(){
  this.authService.useredit(this.userForm.value)
  .then((response)=>{
    console.log(response);
    Swal.fire("Los datos fueron actualizados correctamente")
    .then((r)=>{
      location.href = "/"
    })

  })
  .catch((err)=>{

  })

  }
  testImagei() {
    if(this.user.photoavatar){
      var tester=new Image();
      tester.onload=()=>{
        console.log(this.showimage);
        this.showimage=true;
        console.log(this.showimage); };
      tester.onerror=()=>{
        this.showimage=false;
        console.log(this.showimage); }
      tester.src=this.testImage;}
      else{
        this.showimage=false;
      }
  }
 

edituser(){
  var evento:Object ={
   name:this.user.name,
   doc:this.user.doc,
   company:this.user.company, 
   position:this.user.position, 
   phone:this.user.phone, 
   email:this.user.email, 
   

  }
  this.userForm.reset(evento);
}

} 
