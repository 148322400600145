import { Component, OnInit,} from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { saveAs } from 'file-saver';
import Swal from 'sweetalert2';
import { first } from 'rxjs/operators';
@Component({ 
  selector: 'app-cortesias',
  templateUrl: './cortesias.component.html',
  styleUrls: ['./cortesias.component.css']
})

export class CortesiasComponent implements OnInit {
  cortesias=[];
  user;
  cortesiaForm;
  submitted: boolean;
  constructor(private authService:AuthService) { }

  ngOnInit(): void {
    this.cortesiaForm=new FormGroup({
      'nombre': new FormControl('', [
        Validators.required,       
      ]),
      'cupos': new FormControl('', [
        Validators.required,       
      ]),
      'tipo': new FormControl('', [
        Validators.required,       
      ]),
    })
    this.loaduser();
  }
  copiar(id){
 
    /* Copy the text inside the text field */
   navigator.clipboard.writeText("https://lad.pragma.co/registro/"+id);
 }

  get f() { return this.cortesiaForm.controls; }

  newCortesia(){
    this.submitted=true;
    if(this.cortesiaForm.invalid){
      return 
    }
    this.authService.newCortesia(this.cortesiaForm.value)
    .then((r)=>{
      this.getAlCortesias();
    })
  }

getAlCortesias(){
  this.authService.getAllCortesia()
  .then((r:any)=>{

    this.cortesias=r.cortesia
    console.log(this.cortesias)
    this.verify()
  })
}

verify() {
  this.cortesias.forEach(cortesia=> {
    cortesia.registrados.forEach(user => {
        this.authService.validateid(user.doc)
        .pipe(first())
  .subscribe(
   (res:any)=>{
   if(!res.user.ticket)
    console.log("user",res)}
   ,(err)=>{
    console.log(err)
    console.log("a borrar",user)
    this.authService.removefromcortesia(cortesia._id,user._id)
    .then(()=>{
      this.getAlCortesias();
    })
   }
  )
  
    });
    
  });  
}

delete(id){
  this.authService.deleteCortesia(id)
  .then((r)=>{
    this.getAlCortesias();
  })
}

  loaduser() {
    console.log("cargando usuario")
    this.authService.loaduser()
      .then((response) => {
        console.log("user: ",response); 
        if(response!=null){
         
          this.authService.setUserInfo({ 'user': response['user'] });
          let user = localStorage.getItem('userInfo')
          this.user = JSON.parse(user).user;
          this.getAlCortesias();
        }
      else{
        location.href = "/login"
      }
       
      })
      .catch((err) => {
           location.href = "/login"
        console.log(err)
      })
  }

}
