import { Component, OnInit,Input,Output,EventEmitter, ElementRef} from '@angular/core';
import { rejects } from 'assert';
import { NgxCsvParser } from 'ngx-csv-parser';
import { NgxCSVParserError } from 'ngx-csv-parser';
import { AuthService } from '../../services/auth.service';
import { first } from 'rxjs/operators';
import { EmpresaService } from "../../services/empresa.service";
@Component({
  selector: 'app-useruploader',
  templateUrl: './useruploader.component.html',
  styleUrls: ['./useruploader.component.css']
})
export class UseruploaderComponent implements OnInit {
  csvRecords: any[] = [];
  header = false;
  delimiter=",";
  file;
  foto1Url="nofoto";
  cargando;
  cargados;
  @Input() empresa:any;
  @Input() tipo:any;
  @Output() active = new EventEmitter<boolean>();
  constructor(
    private ngxCsvParser: NgxCsvParser, private authService : AuthService,
    private elRef:ElementRef,
    private empresaService:EmpresaService,
  ) { }

  ngOnInit(): void {
    console.log("empresa",this.empresa)
  }


  onChange(value){
    this.delimiter=value;
    this.file=false;
  }
    // Your applications input change listener for the CSV File
    fileChangeListener($event: any): void {
this.file=true;
      // Select the files from the event
      const files = $event.srcElement.files;
  
      // Parse the file you want to select for the operation along with the configuration
      this.ngxCsvParser.parse(files[0], { header: this.header, delimiter: this.delimiter })
        .pipe().subscribe((result: Array<any>) => {
  
        //  console.log('Result', result);
        result.forEach(element => {
          this.csvRecords.push({"datos":element,"estado":""})
        });
          
          
        }, (error: NgxCSVParserError) => {
          console.log('Error', error);
        });
  
    }

cargarre(){
  this.cargando=true;
  this.csvRecords.forEach((usr,i) => {
    if(usr.datos.length==6){
      var usrObj={
      
        "name":usr.datos[0],
        "email":usr.datos[1],        
        "doc":usr.datos[2],
        "phone":usr.datos[3],
        "position":usr.datos[4],
         "company":this.empresa.name,
         "rol":"staff"
      }
  
  
  
    }
    else{
      if(this.csvRecords.length==i+1){
        this.cargados=true;
        this.cargando=false;
           }
      this.csvRecords[i].estado="error"
    }
 
});
}
promise(usrObj,i){
  return new Promise((resolve,reject)=>{
    this.authService.create(usrObj)

    .pipe(first())
    .subscribe(
      (res:any) =>{



        var ticket={
          user:res.user._id,
          ready:true,
          type:this.tipo
        }
        this.authService.addTicket(ticket)
        .then((r)=>{
         // Swal.fire("Los datos se cargaron de forma correcta.");
       
        })
         
          
       
          this.empresaService.addPerson(res["user"]._id,this.empresa._id)
         
          .then((r)=>{
          
        if(this.csvRecords.length==i+1){
          this.cargados=true;
          this.cargando=false;
             }





       this.csvRecords[i].estado="cargado"
       resolve("cargado")
    
          });
        



      },
      
      (err) =>{
        console.log(err);
        if(this.csvRecords.length==i+1){
          this.cargados=true;
          this.cargando=false;
             }
        this.csvRecords[i].estado="error"
        resolve("error")
      })
      }
      
      
      )

}


async cargar(){
  for (let i = 0; i < this.csvRecords.length; i++) {
    var usr=this.csvRecords[i];
    //console.log(this.csvRecords[i])
    if(usr.datos.length==5 && !isNaN(usr.datos[2])){
      console.log();
      var usrObj={
        "name":usr.datos[0],
        "email":usr.datos[1],        
        "doc":usr.datos[2],
        "phone":usr.datos[3],
        "position":usr.datos[4],
         "company":this.empresa.name,
         "rol":"staff"
      }
  
   var resutl= await this.promise(usrObj,i);
    console.log(resutl)
}
else{
  console.log("error");
  if(this.csvRecords.length==i+1){
    this.cargados=true;
    this.cargando=false;
       }
  this.csvRecords[i].estado="error"
}
  }

}
exit() {
  this.active.emit(false);

}

marcaruno(){
  this.file=true;
  var result=this.elRef.nativeElement.querySelector('#marcauno').value;

 var result=result.split("\n")
result.forEach(el=>{
  el=el.split("\t");
  el=el.slice(0,10)
  this.csvRecords.push({"datos":el,"estado":""})
})
//  console.log(result)
//  return




}

}

