import { Component, ElementRef, OnInit, ViewChild, HostListener } from '@angular/core';
import { AuthService } from "../../services/auth.service";
//import {jsPDF} from 'jspdf';
import html2canvas from 'html2canvas';
//declare var jsPDF: any;
import jsPDF from 'jspdf';
import { SwPush } from '@angular/service-worker'
import { FormGroup, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { NotificaService } from '../../services/notifica.service'
import { EventsService } from 'src/app/services/events.service';

@Component({
  selector: 'app-home-virtual',
  templateUrl: './home-virtual.component.html',
  styleUrls: ['./home-virtual.component.css', './home.scss']
})
export class HomeVirtualComponent implements OnInit {
  @ViewChild('escarapela', { static: false }) escarapela: ElementRef;
  @ViewChild('escarapelainvitado', { static: false }) escarapelainvitado: ElementRef;
  @ViewChild('certificadoasistencia', { static: false }) certificadoasistencia: ElementRef;
  paises = [
    "Colombia",
    "Afganistán",
    "Albania",
    "Alemania",
    "Andorra",
    "Angola",
    "Anguila",
    "Antártida",
    "Antigua y Barbuda",
    "Arabia Saudita",
    "Argelia",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaiyán",
    "Bélgica",
    "Bahamas",
    "Bahrein",
    "Bangladesh",
    "Barbados",
    "Belice",
    "Benín",
    "Bhután",
    "Bielorrusia",
    "Birmania",
    "Bolivia",
    "Bosnia y Herzegovina",
    "Botsuana",
    "Brasil",
    "Brunéi",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Camboya",
    "Camerún",
    "Canadá",
    "Chad",
    "Chile",
    "China",
    "Chipre",
    "Ciudad del Vaticano",
    "Comoras",
    "República del Congo",
    "República Democrática del Congo",
    "Corea del Norte",
    "Corea del Sur",
    "Costa de Marfil",
    "Costa Rica",
    "Croacia",
    "Cuba",
    "Curazao",
    "Dinamarca",
    "Dominica",
    "Ecuador",
    "Egipto",
    "El Salvador",
    "Emiratos Árabes Unidos",
    "Eritrea",
    "Eslovaquia",
    "Eslovenia",
    "España",
    "Estados Unidos de América",
    "Estonia",
    "Etiopía",
    "Filipinas",
    "Finlandia",
    "Fiyi",
    "Francia",
    "Gabón",
    "Gambia",
    "Georgia",
    "Ghana",
    "Gibraltar",
    "Granada",
    "Grecia",
    "Groenlandia",
    "Guadalupe",
    "Guam",
    "Guatemala",
    "Guayana Francesa",
    "Guernsey",
    "Guinea",
    "Guinea Ecuatorial",
    "Guinea-Bissau",
    "Guyana",
    "Haití",
    "Honduras",
    "Hong kong",
    "Hungría",
    "India",
    "Indonesia",
    "Irán",
    "Irak",
    "Irlanda",
    "Isla Bouvet",
    "Isla de Man",
    "Isla de Navidad",
    "Isla Norfolk",
    "Islandia",
    "Islas Bermudas",
    "Islas Caimán",
    "Islas Cocos (Keeling)",
    "Islas Cook",
    "Islas de Åland",
    "Islas Feroe",
    "Islas Georgias del Sur y Sandwich del Sur",
    "Islas Heard y McDonald",
    "Islas Maldivas",
    "Islas Malvinas",
    "Islas Marianas del Norte",
    "Islas Marshall",
    "Islas Pitcairn",
    "Islas Salomón",
    "Islas Turcas y Caicos",
    "Islas Ultramarinas Menores de Estados Unidos",
    "Islas Vírgenes Británicas",
    "Islas Vírgenes de los Estados Unidos",
    "Israel",
    "Italia",
    "Jamaica",
    "Japón",
    "Jersey",
    "Jordania",
    "Kazajistán",
    "Kenia",
    "Kirguistán",
    "Kiribati",
    "Kuwait",
    "Líbano",
    "Laos",
    "Lesoto",
    "Letonia",
    "Liberia",
    "Libia",
    "Liechtenstein",
    "Lituania",
    "Luxemburgo",
    "México",
    "Mónaco",
    "Macao",
    "Macedônia",
    "Madagascar",
    "Malasia",
    "Malawi",
    "Mali",
    "Malta",
    "Marruecos",
    "Martinica",
    "Mauricio",
    "Mauritania",
    "Mayotte",
    "Micronesia",
    "Moldavia",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Mozambique",
    "Namibia",
    "Nauru",
    "Nepal",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Noruega",
    "Nueva Caledonia",
    "Nueva Zelanda",
    "Omán",
    "Países Bajos",
    "Pakistán",
    "Palau",
    "Palestina",
    "Panamá",
    "Papúa Nueva Guinea",
    "Paraguay",
    "Perú",
    "Polinesia Francesa",
    "Polonia",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Reino Unido",
    "República Centroafricana",
    "República Checa",
    "República Dominicana",
    "República de Sudán del Sur",
    "Reunión",
    "Ruanda",
    "Rumanía",
    "Rusia",
    "Sahara Occidental",
    "Samoa",
    "Samoa Americana",
    "San Bartolomé",
    "San Cristóbal y Nieves",
    "San Marino",
    "San Martín (Francia)",
    "San Pedro y Miquelón",
    "San Vicente y las Granadinas",
    "Santa Elena",
    "Santa Lucía",
    "Santo Tomé y Príncipe",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leona",
    "Singapur",
    "Sint Maarten",
    "Siria",
    "Somalia",
    "Sri lanka",
    "Sudáfrica",
    "Sudán",
    "Suecia",
    "Suiza",
    "Surinám",
    "Svalbard y Jan Mayen",
    "Swazilandia",
    "Tayikistán",
    "Tailandia",
    "Taiwán",
    "Tanzania",
    "Territorio Británico del Océano Índico",
    "Territorios Australes y Antárticas Franceses",
    "Timor Oriental",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad y Tobago",
    "Tunez",
    "Turkmenistán",
    "Turquía",
    "Tuvalu",
    "Ucrania",
    "Uganda",
    "Uruguay",
    "Uzbekistán",
    "Vanuatu",
    "Venezuela",
    "Vietnam",
    "Wallis y Futuna",
    "Yemen",
    "Yibuti",
    "Zambia",
    "Zimbabue",
  ];
  invitado: any;
  cargado: boolean;
  file: any;
  resultados: any;
  intervalid: NodeJS.Timeout;
  transmisionUrl: any = null;

  popuser = false;
  asistencia;
  userForm: any;
  submitted: boolean;
  estado: boolean;

  certificado = false
  evento_presencial = "65d7354e84fbfc3268588ab9"
  evento_virtual = "65d7359e84fbfc3268588aba"


  constructor( private eventosService: EventsService,private authService: AuthService,
    private notiService: NotificaService,
    private swPush: SwPush,
  ) { }
  user;
  addInvitado = false;
  contacts;

  @HostListener('window:beforeinstallprompt', ['$event'])
  onBeforeInstallPrompt(event: Event) {
    console.log('beforeinstallprompt Event fired');
    event.preventDefault();
    this.deferredPrompt = event;
  }
  showAddToHomeScreenButton;
  deferredPrompt: any;

  ngOnInit(): void {
    this.userForm = new FormGroup({
      'name': new FormControl('', [
        Validators.required,

      ]),
      '_id': new FormControl('', [


      ]),
      'company': new FormControl('', [
        Validators.required,
      ]),
      'position': new FormControl('', [
        Validators.required,
      ]),
      'phone': new FormControl('', [
        Validators.required,
      ]),

    })


    try {
      const isAndroid = /Android/i.test(navigator.userAgent);
      if (isAndroid && !window.matchMedia('(display-mode: standalone)').matches) {
        // Mostrar el botón de instalación solo si es Android y no está en el modo "standalone"
        this.showAddToHomeScreenButton = true;
      }
      window.addEventListener('beforeinstallprompt', (e) => {
        try {
          console.log("¡Evento 'beforeinstallprompt' capturado!");
          this.deferredPrompt = e;
          // Aquí puedes agregar cualquier otra lógica que desees ejecutar cuando se dispare el evento
        } catch (error) {
          console.log("Error al manejar el evento 'beforeinstallprompt':", error);
        }
      });
    } catch (error) {

    }


    this.loaduser();
    // this.getFileHorarios();
    this.refresh()
    this.intervalid = setInterval(() => {
      this.refresh();;
    }, 1 * 60 * 1000);

  
    //  this.authService.getCertificados()
    //  .then((r)=>{
    //    console.log("cer",r)
    //  })

  }


  ingresado() {

    console.log("INGRESADO")

    let event 

    if(this.user.ticket.type == 1)
    {
      event = this.evento_presencial
    }
    else if(this.user.ticket.type == 2)
    {
      event = this.evento_virtual
    }

   this.eventosService.getIngresoEvent(event)
      .then((r: any) => {
      
        let res = r.find((i)=>{
             return i.user.email == this.user.email
        })

        if(res){
          this.certificado = true
        }
        
      })
    }


  addToHomeScreen() {
    if (this.deferredPrompt) {
      this.deferredPrompt.prompt();
      this.deferredPrompt.userChoice
        .then((choiceResult: any) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('Usuario aceptó la instalación de la aplicación');
          } else {
            console.log('Usuario canceló la instalación de la aplicación');
          }
          this.deferredPrompt = undefined;
        });
    }
  }

  refresh() {
    this.authService.geturl()
      .then((response) => {
        console.log(response);
        if (response["url1"] != "close") {
          this.transmisionUrl = response["url1"]
        }
        else {
          this.transmisionUrl = null
        }
      })
  }

  getFileHorarios() {
    this.authService.getStatus()
      .then((r) => {
        this.file = r["file"]
        if (this.file != null && this.file != "") {
          console.log("file: ", this.file)
          window.open(this.file, '_blank').focus();
        }
        else {
          Swal.fire("Aún no se ha cargado el archivo", "intentelo de nuevo más tarde")
        }

      })
  }
  getFileResultados() {
    this.authService.getStatus()
      .then((r) => {
        this.resultados = r["resultados"]
        if (this.resultados != null && this.resultados != "") {
          console.log("file: ", this.resultados)
          window.open(this.resultados, '_blank').focus();
        }
        else {
          Swal.fire("Aún no se ha cargado el archivo", "intentelo de nuevo más tarde")
        }

      })
  }

  loaduser() {
    console.log("cargando usuario")
    this.authService.loaduser()
      .then((response) => {
        console.log("user: ", response);
        if (response != null) {

          this.authService.setUserInfo({ 'user': response['user'] });
          let user = localStorage.getItem('userInfo')
          this.user = JSON.parse(user).user;

          this.ingresado()

          console.log("user", this.user.ticket.type)

          if(this.user.ticket.type == 1){
            this.estado = false
          }else{
            this.estado = true
          }

          try {
            this.suscribirse()
          } catch (error) {
            console.log("error suscribiendo")
          }


          if (!this.user.phone || !this.user.company || !this.user.position) {
            this.popuser = true;
            //editar usuaio
            this.userForm.reset(this.user);
          }
          else {
            this.popuser = false;
          }
        }
        else {
          location.href = "/login"
        }

      })
      .catch((err) => {
        location.href = "/login"
        console.log(err)
      })


      
  }

  downCert() {
    console.log("entrooo");
    this.authService.newCert({ user: this.user._id })
    // Extraemos el
    const DATA = this.certificadoasistencia.nativeElement;
    const doc = new jsPDF('p', 'mm', [210, 260]);
    const options = {
      background: '#575b93',
      scale: 2
    };
    html2canvas(DATA, options).then((canvas) => {

      const img = canvas.toDataURL('image/PNG');

      // Add image Canvas to PDF
      const bufferX = 0;
      const bufferY = 0;
      const imgProps = (doc as any).getImageProperties(img);
      const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
      const pdfHeight = ((imgProps.height * pdfWidth) / imgProps.width);
      doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
      return doc;
    }).then((docResult) => {
      docResult.save(`${new Date().toISOString()}_certificado.pdf`);
      this.eventosService.newCert({user:this.user._id})
    });

  }

  downloadAsPDFInv() {
    const DATA = this.escarapelainvitado.nativeElement;
    const doc = new jsPDF('p', 'pt', 'a4');
    const options = {
      background: '#575b93',
      scale: 3
    };
    html2canvas(DATA, options).then((canvas) => {

      const img = canvas.toDataURL('image/PNG');

      // Add image Canvas to PDF
      const bufferX = 15;
      const bufferY = 45;
      const imgProps = (doc as any).getImageProperties(img);
      const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
      return doc;
    }).then((docResult) => {
      docResult.save(`${new Date().toISOString()}.pdf`);
    });
  }

  downloadAsPDF() {
    // Extraemos el
    const DATA = this.escarapela.nativeElement;
    const doc = new jsPDF('p', 'pt', 'a4');
    const options = {
      background: '#575b93',
      scale: 3
    };
    html2canvas(DATA, options).then((canvas) => {

      const img = canvas.toDataURL('image/PNG');

      // Add image Canvas to PDF
      const bufferX = 15;
      const bufferY = 45;
      const imgProps = (doc as any).getImageProperties(img);
      const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
      return doc;
    }).then((docResult) => {
      docResult.save(`${new Date().toISOString()}.pdf`);
    });
  }


  // public downloadAsPDF4() {
  //   let doc = new jsPDF();

  //   const specialElementHandlers = {
  //     '#editor': function (element, renderer) {
  //       return true;
  //     }
  //   };

  //   const escarapela = this.escarapela.nativeElement;

  //   doc.fromHTML(escarapela.innerHTML, 15, 15, {
  //     width: 500,
  //     'elementHandlers': specialElementHandlers
  //   });

  //   doc.save('escarapela.pdf');
  // }



  onCancel(event) {
    this.addInvitado = false;
    this.loaduser();
  }

  get f() { return this.userForm.controls; }

  send() {
    this.submitted = true;
    if (this.userForm.invalid) {
      return
    }
    this.userForm.patchValue({
      _id: this.user._id
    })
    this.authService.useredit(this.userForm.value)
      .then((response) => {
        //console.log(response);
        this.loaduser();
      })
      .catch((err) => {

      })

  }

  descargarCertificado(){

    this.ingresado()
   

  }

  suscribirse() {
    console.log("permiso", Notification.permission)
    console.log("suscribien")
    if (Notification.permission == "default" || Notification.permission == "granted") {
      this.swPush.requestSubscription({ serverPublicKey: "BLvfQsuQLQIfjnOCFbKErwxXpDA_PHL3YY-f5isJOz48d7iNHkKKvScbGsXZ820EY5n6zrhYqnGZ03CAZz2jE5Q" })
        .then((r: any) => {
          console.log("resp", r)
          if (r) {
            this.notiService.suscribirse(r).then((r) => {

            })
          }

        })
        .catch((err) => {
          console.log(err)
        })
    }

  }

  noMemorias() {
    Swal.fire("Este servicio estará disponible despues del evento")
  }
}
