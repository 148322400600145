import { Component, OnInit } from '@angular/core';
import { EventsService } from '../../services/events.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { interval } from 'rxjs';
import { saveAs } from 'file-saver';
import { AuthService } from '../../services/auth.service';
import { ChangeDetectorRef } from '@angular/core'
import Swal from 'sweetalert2';
@Component({
  selector: 'app-comentarios',
  templateUrl: './comentarios.component.html',
  styleUrls: ['./comentarios.component.css']
})
export class ComentariosComponent implements OnInit {

  constructor(private authService: AuthService,
     private eventService: EventsService,
      private router: Router, private cd: ChangeDetectorRef
    ) { }
user;
comentarioForm;
submitted;
comentarios;
  ngOnInit(): void {
    let user = localStorage.getItem('userInfo')
    this.user = JSON.parse(user).user;
    console.log(this.user);
    if(this.user.rol=="admin"){
      this.getComentarios();
    }
    this.comentarioForm = new FormGroup({
      'user': new FormControl("", [Validators.required]),
      'text': new FormControl("", [Validators.required]),
    })
  }

  getComentarios(){
    this.authService.getComentarios()
    .then((r)=>{
     
      this.comentarios=r["comentarios"]
      console.log(this.comentarios)
    })

  }

  get f() { return this.comentarioForm.controls; }

newComentario(){
  this.submitted=true;
  this.comentarioForm.patchValue({
    user:this.user._id
  })
  if(this.comentarioForm.invalid){return}
  this.authService.newComentario(this.comentarioForm.value)
  .then((r)=>{
    Swal.fire("Comentario guardado con exito");
    this.comentarioForm.reset();
    this.submitted=false;
  })
}




}
