import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import  Swal from 'sweetalert2'; 
import { EmpresaService } from "../../services/empresa.service";
import { first } from 'rxjs/operators';
import html2canvas from 'html2canvas';
//declare var jsPDF: any;
import jsPDF from 'jspdf';
@Component({
  selector: 'app-empresa',
  templateUrl: './empresa.component.html',
  styleUrls: ['./empresa.component.css']
})
export class EmpresaComponent implements OnInit {


  icompanyForm;
  submitted;
  log;
  user;
  contacts:Boolean=false;
  @ViewChild('escarapela', {static: false}) escarapela: ElementRef;
  constructor(private authService : AuthService, private empresaService:EmpresaService ) { }

  ngOnInit(): void {

    this.icompanyForm = new FormGroup({
 
      'nit': new FormControl('', [
        Validators.required,
          ]),    
    });

  }
  get f3() { return this.icompanyForm.controls; }
  ingresoc(){
    this.submitted = true;
    if (this.icompanyForm.invalid) {
     console.log("invalido");
     return;
  }
    this.empresaService.validate(this.icompanyForm.get("nit").value)
    .pipe(first())
.subscribe(async (response) => {
      console.log(response);
    
      //validar clave

  const { value: pass } = await Swal.fire({
      title: 'Ingresa la contraseña',
      input: 'text',
      background:"#F0F8FF",
      inputPlaceholder: 'Contraseña',
      confirmButtonColor:"#696969",
      footer: '<a href="/olvido/'+response["user"]["_id"]+'">Olvide mi contraseña</a>'
    })
     
    if (pass) {
      if(pass==response["user"]["clave"]){
    this.user=response['user']
  this.authService.setUserInfo({'user' : response['user']});
  //
  localStorage.setItem('access_token', null);
  //this.log=true;
  location.href="/empresap/"+this.user._id
      }
      else{
        Swal.fire("Contraseña incorrecta");
      }
    }


    },
    (err)=>{

      Swal.fire(     {
        title: "No encontramos tu NIT ",
        text:"Si no te has registrado, por favor registrate",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#89c9b8',
        confirmButtonText: 'Registrarme',
        cancelButtonText: "Reintentar"
        
    }).then((result)=>{
    if(result.value){
   location.href="/register-empresa"
    
      
    }
    else{}
    });

      
  })
  }

  downloadAsPDF() {
    // Extraemos el
    const DATA = this.escarapela.nativeElement;
    const doc = new jsPDF('p', 'pt', 'a4');
    const options = {
      background: '#575b93',
      scale: 3
    };
    html2canvas(DATA, options).then((canvas) => {

      const img = canvas.toDataURL('image/PNG');

      // Add image Canvas to PDF
      const bufferX = 15;
      const bufferY = 45;
      const imgProps = (doc as any).getImageProperties(img);
      const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
      return doc;
    }).then((docResult) => {
      docResult.save(`${new Date().toISOString()}_tutorial.pdf`);
    });
  }

}
