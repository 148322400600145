import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class WhatsappService {
  url= "https://graph.facebook.com/v14.0/108047308770394/messages"
  //url= "http://localhost:1337/api"

  requestOptions
  headers

  //curl -i -X POST https://graph.facebook.com/v14.0/108047308770394/messages -H 'Authorization: Bearer EAAG3UOsl6ccBAG7tLLVEC7ZAgevJ3ZBC7whnxvqsAtXppQCbGrZCZA71gq1gdGUN5QDqcVJMOZAjePvDkzvZCjDKVYc9RADHJErrlrkcgeiiv12VJKSgiWoN0XOt5eir8Vuix0VSrcbmMcZB4HvOutD4CZAk3cD0OZA1skTIYUBPewOWT8vY6gUqa8KkDdGL4Ekz02eMLJT5OUQZDZD' ` -H 'Content-Type: application/json' ` -d '{ \"messaging_product\": \"whatsapp\", \"to\": \"573212272701\", \"type\": \"template\", \"template\": { \"name\": \"hello_world\", \"language\": { \"code\": \"en_US\" } } }'


  //auth_token = "81642d70a0dd6bfa266c661dba6ca8bcc71fd8cfce6d2fc826e6fc89c2846f4f346d9a849f5c09db395fc6743cd702a908063a14e5204dabf7ce734d18f5cabc28445aef9622d438fe27814fbceb22902338d95945f0f6671643656879af8d9623a93de991f3e474e85ce36baa34bdd96a5502b6cab7b1ddf0f4703d2bb7d166";
  auth_token = "EAAG3UOsl6ccBAOOALflywA2zolZCeuuVFuJvUvQCJrdGg8QSHYtxN7yG6jcm2o070r7jDZA7uNcKP6zvKWTo2jebdybsO2v3vYR9QOGPG5jNb2tn6oU47ZC4ILGqUxvOv3vU2WgB9PNtCMs5ftO54aoeDea0T0YURsD0LqgmYLA83GWJFbWXu7hYu1Bwtp4I4ZAbTlWmxQZDZD"
  constructor(private http : HttpClient) {

    this.headers = new HttpHeaders({
      'Content-Type':'application/json',
      'Authorization':`Bearer ${this.auth_token}`
    });
    
    this.requestOptions = { headers: this.headers };

  }
  public newMessage(text,number){

    return this.http.post(this.url,
      {
      "messaging_product": "whatsapp",
      "recipient_type": "individual",
      "to": number,
       "type": "template",
      "template": { 
        "name": "mensajevar",
        "language":{"code":"es"},
        "components":[ { "type": "body", "parameters":[{"type":"text","text":text}]}]
        }
        
      },this.requestOptions ).toPromise()
  }
}
