import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  url = "https://selesoluciona.com/eventopragma";
  constructor(private http: HttpClient) { }
  public changeStatusAuditorio(url) {
    return this.http.post(this.url + '/changeauditorio', { "url": url }).toPromise()
  }
  public buyProducto(correo, producto) {

    return this.http.post(this.url + '/buyproduct', { "correo": correo, "producto": producto }).toPromise()
  }
  public deleteProducto(id) {
    return this.http.post(this.url + '/deleteproduct', { "name": id }).toPromise()
  }
  public getProductos() {
    return this.http.get(this.url + '/products').toPromise()
  }


  public createProducto(product) {
    return this.http.post(this.url + '/newproduct', { product }).toPromise()
  }



  public updateRating(data) {
    return this.http.post(this.url + '/ratingEvento', data ).toPromise()
  }

  public ratingGetByEvent(ide) {
    return this.http.get(this.url + '/ratingGetByEvent/'+ide).toPromise()
  }

  public ratingGetByUser(user) {
    return this.http.get(this.url + '/ratingGetByUser/'+user).toPromise()
  }


  public newTrivia(trivia) {
    return this.http.post(this.url + '/newtrivia', { trivia }).toPromise()
  }
  public closeTrivia(event = null) {
    return this.http.post(this.url + '/closetrivia', { "event": event }).toPromise()
  }

  public contactar(from, to) {
    return this.http.post(this.url + '/addcontacto', { from, to }).toPromise()
  }
  public getAll() {
    return this.http.get(this.url + '/events').toPromise()
  }
  public changestatus(id, status) {
    return this.http.post(this.url + '/changesalastatus', { "id": id, "status": status }).toPromise()
  }
  public deletep(id) {
    return this.http.post(this.url + '/deletepevent', { "id": id }).toPromise()
  }
  public getAllp() {
    return this.http.get(this.url + '/pevents').toPromise()
  }
  public createSalas(event) {
    return this.http.post(this.url + '/newsalas', { event }).toPromise()
  }
  public getAllSalas() {
    return this.http.get(this.url + '/salas').toPromise()
  }

  public loadSala(id) {
    return this.http.post(this.url + '/loadsala', { "id": id }).toPromise()
  }

  public create(event) {
    return this.http.post(this.url + '/newevent', { event }).toPromise()
  }
  public createStand(stand) {
    return this.http.post(this.url + '/newstand', { stand }).toPromise()
  }
  public getStands() {
    return this.http.get(this.url + '/stands').toPromise()
  }
  public newNota(nota) {
    return this.http.post(this.url + '/newnota', { nota }).toPromise()
  }
  public loadNota(email) {
    return this.http.post(this.url + '/loadnota', { "email": email }).toPromise()
  }

  public bitacora(item) {
    console.log(item)
    return this.http.post(this.url + '/newbitacoraitem', { "item": item }).toPromise()
  }


  public getBitacora() {
    return this.http.get(this.url + '/items').toPromise()
  }
  public deleteBitacora(id) {
    return this.http.post(this.url + '/deletebitacora', { "id": id }).toPromise()
  }
  public downloadImage(imgUrl) {
    return this.http.get(imgUrl, { responseType: 'blob' as 'json' }).toPromise()
  }



  public loadStand(id) {
    return this.http.post(this.url + '/loadstand', { "id": id }).toPromise()
  }
  public load(id) {
    return this.http.post(this.url + '/loadevent', { "id": id }).toPromise()
  }
  public delete(id) {
    return this.http.post(this.url + '/deleteevent', { "id": id }).toPromise()
  }
  public deletestand(id) {
    return this.http.post(this.url + '/deletestand', { "id": id }).toPromise()
  }
  public registrados(id) {
    return this.http.post(this.url + '/registers', { "id": id }).toPromise()
  }

  public register(event, user) {
    return this.http.post(this.url + '/registerevent', { "event": event, "user": user }).toPromise()
  }

  public getIngresoEvent(event) {
    return this.http.post(this.url + '/ingresorevent', { event }).toPromise()
  }
  public asistentes(id) {
    return this.http.post(this.url + '/asistentes', { "id": id }).toPromise()
  }

  public ingreso(event, user) {
    return this.http.post(this.url + '/ingreso', { "event": event, "user": user }).toPromise()
  }
  public newingreso(user, eventid) {
    return this.http.post(this.url + '/ingreso', { user, eventid }).toPromise()
  }

  public preguntas(id) {
    return this.http.post(this.url + '/preguntas', { "id": id }).toPromise()
  }
  public pulls(id) {
    return this.http.post(this.url + '/pulls', { "id": id }).toPromise()
  }

  public respuestas(id) {
    return this.http.post(this.url + '/getrespuestas', { "id": id }).toPromise()
  }

  public newMemoria(memoria) {
    return this.http.post(this.url + '/newmemoria', { memoria }).toPromise()
  }
  public deleteMemoria(memoria) {
    return this.http.post(this.url + '/deletememoria', { memoria }).toPromise()
  }
  public getAllMemoras() {
    return this.http.get(this.url + '/allmemoria').toPromise()
  }

  public  newCert(certificado){
    return this.http.post(this.url+'/newCert', {certificado}).toPromise()
  }

  public getCertificados(){
    return this.http.get(this.url+'/getCertificados', ).toPromise()
  }

}
