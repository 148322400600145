import { Component, OnInit, } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import {EventsService} from '../../services/events.service'
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { saveAs } from 'file-saver';
import Swal from 'sweetalert2';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class UsuariosComponent implements OnInit {
  idFiltro: string = '';
  usuariosFiltrados: any[] = [];
  filteri = 0;
  users;
  seting;
  c_user;
  rolForm;
  user;
  submitted;
  aporbarTicket: boolean;
  sTicket: any;
  editUser;
  userForm: FormGroup;
  selectedUser: any;
  editarTicket: boolean;
  constructor(private authService: AuthService, private eventService: EventsService) { }

  ngOnInit(): void {
    console.log("Primer usuario:", );
    let user = localStorage.getItem('userInfo')
    this.user = JSON.parse(user).user;
    console.log(this.user);
    this.getUsers();
    this.rolForm = new FormGroup({

      'rol': new FormControl('', [
        Validators.required,
      ]),

    });

    this.userForm = new FormGroup({

      'name': new FormControl('', [
        Validators.required,

      ]),
      'doc': new FormControl('', [
        Validators.required,

      ]),
      '_id': new FormControl('', [
        Validators.required,

      ]),

      'company': new FormControl('', [
        Validators.required,
      ]),
      'position': new FormControl('', [
        Validators.required,
      ]),
      'phone': new FormControl('', [
        Validators.required,
      ]),
      'email': new FormControl('', [
        Validators.required,
      ]),


    })

  }


  descargarCertificado(){
    this.eventService.getCertificados()
    .then((r:any)=>{
      console.log("certficados",r)
      
    const mappedUsers = r.map(el => ({
      "Nombre": el.user.name?.toUpperCase(),
      "Cedula": el.user.doc?.toUpperCase(),
      "Correo": el.user.email?.toUpperCase(),
     "hora":el.hora,
  
    }));
    this.exportAsExcelFile(mappedUsers, "Certificados-");
    })
  }


  filtrarUsuarios() {
    console.log("hola");
    
    switch (this.filteri) {
      case 0:
        this.usuariosFiltrados = this.users
        return this.usuariosFiltrados = this.filtro().filter(usuario => 
          usuario.user.doc.includes(this.idFiltro)  || usuario.user.name.toLowerCase().includes(this.idFiltro.toLowerCase()));
        break;
      case 1:
        return this.users.filter((el) => { return !el.ready })
        break;
      case 2:
        return this.users.filter((el) => { return el.user.login })
        break;
      case 3:
        return this.users.filter((el) => { return !el.user.login })
        break;
      case 4:
          return this.users.filter((el) => { return el.type=="3" })
          break;
      case 5:
            return this.users.filter((el) => { return el.type=="4" })
            break;
      default:
        
        break;
    }

    //this.usuariosFiltrados = this.filtro().filter(usuario => usuario.user.doc.includes(this.idFiltro));
}

  filterUsers(i) {
    this.filteri = i
  }

  filtro() {
    switch (this.filteri) {
      case 0:
        return this.users
        break;
      case 1:
        return this.users.filter((el) => { return !el.ready })
        break;
      case 2:
        return this.users.filter((el) => { return el.user.login })
        break;
      case 3:
        return this.users.filter((el) => { return !el.user.login })
        break;
        case 4:
          return this.users.filter((el) => { return el.type=="3" })
          break;
        
          case 5:
            return this.users.filter((el) => { return el.type=="4" })
            break;
          
      default:
        
        break;
    }
  }


  editarUser(user) {
    this.editUser = true;
    this.selectedUser = user;
    this.userForm.patchValue(user);
  }
  get f() { return this.userForm.controls; }

  edit() {
    console.log(this.userForm.value)

    this.authService.usereditAdmin(this.userForm.value)
      .then((r) => {
        this.editUser = false;
        this.userForm.reset();
        this.getUsers();
      })

  }


  editar(ticket) {
    this.editarTicket = true;
    this.sTicket = ticket;
  }
  editarticket(e) {
    var type = e.target.value
    this.authService.aprobeTicket(this.sTicket._id, { type: type })
      .then((r) => {
        this.getUsers()
        this.editarTicket = false;
      })
  }

  aprobar2(e) {

    var type = e.target.value
    this.authService.aprobeTicket(this.sTicket._id, { ready: true, type: type })
      .then((r) => {
        this.getUsers()
        this.aporbarTicket = false;
      })

  }

  aprobar(ticket) {
    if (!ticket.type || ticket.type == null) {
      this.aporbarTicket = true;
      this.sTicket = ticket;
    }
    else {
      this.authService.aprobeTicket(ticket._id, { ready: true })
        .then((r) => {
          this.getUsers()
        })
    }
  }

  getUsers() {

    this.authService.getTickets()
      .then((response) => {
        console.log(response);
        this.users = response;
        this.users=this.users.filter((el)=>{return el.user})
        this.filterUsers(0)
      })
      .catch((err) => {
      })
  }
  rol(usr) {
    this.seting = true
    this.c_user = usr;
    console.log(this.c_user);
    var evento: Object = {
      rol: this.c_user.rol,

    }
    this.rolForm.reset(evento);


  }

  delete(usr) {

    Swal.fire({
      title: "Vas a  eliminar al usuario ",
      text: "¿estas seguro?",
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Continuar',
      cancelButtonText: "Cancelar"

    }).then((result) => {

      if (result.value) {

        this.authService.deleteTicket(usr._id)
          .then((response) => {
            console.log(response);
            this.getUsers();
          })
      }
      else {

      }
    });






  }

  setRols() {

    console.log(this.rolForm.value);
    this.authService.setrol(this.rolForm.value, this.c_user.doc)
      .then((response) => {
        console.log(response);
        this.getUsers();
        this.seting = false;
      })
  }
  descargar2() {

    var rows = [];

    let csvContent = "data:text/csv;charset=utf-8,";
    var user = [];
    this.users.forEach((rowArray) => {
      rowArray = rowArray.user;
      user = [rowArray.name, rowArray.email, rowArray.doc, rowArray.company, rowArray.position, rowArray.phone]
      //user= Object.values(rowArray);



      console.log(user);
      let row = user.join(",");

      csvContent += row + "\r\n";
    });
    saveAs(csvContent, "registrados.csv");

  }



  descargar() {
    var users = this.filtro()
    users = users.map((usr) => {
      var entrada;
      switch (usr.type) {
        case "1":
          entrada = "presencial"
          break;
        case "2":
          entrada = "virtual"
          break;
        case "3":
          entrada = "Montaje"
          break;
        case "4":
          entrada = "Expositor"
          break;
        case "5":
          entrada = "Logistica"
          break;
        case "6":
          entrada = "Prensa"
          break;
        case "7":
          entrada = "Conferencistas- Directivos "
          break;
        case "8":
          entrada = "Directivos rueda negocios"
          break;
        case "9":
          entrada = "Asistentes rueda negocios"
          break;
        case "10":
          entrada = "Artistas"
          break;
        case "11":
          entrada = "Asistente Artista"
          break;
        case "12":
          entrada = "Montaje VIP"
          break;
          case "13":
          entrada = "Organizador"
          break;
        default:
          break;
      }
      usr.entrada = entrada
      return usr
    })

    var contacts_ = users.map((contact) => { return { "nombre": contact.user.name, "correo": contact.user.email, "doc": contact.user.doc, "Empresa": contact.user.company, "Cargo": contact.user.position, "celular": contact.user.phone, "entrada": contact.entrada } })
    this.exportAsExcelFile(contacts_, "contactos")
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

}
