<div class="pc">
    <div class="row mt-1 ml-5 justify-content-center ">
        <div class="col-md-2 ml-5 col-6">
            <img src="../../../assets/images/newfolder/LogoLAD.png"
                width="100%" alt>
        </div>
        <div class="col-md-6">
            <h1
                style="text-align: center; margin-top: 20px;  font-size: 28px; font-weight: 500; ">
                Plataforma Digital de Contenidos +CTG 2023
            </h1>
        </div>
        <div class="col-md-3 ml-5 col-6 " style="text-align: end;">
            <a href="/"> <button class="btn btn-danger">Volver</button></a>
        </div>
    </div>

</div>

<div class="cel">
    <div class="row mt-1 justify-content-center  ">
        <div class="col-2">

        </div>
        <div class="col-6">
            <img src="../../../assets/images/newfolder/LogoLAD.png"
                width="100%" alt>
        </div>
        <div class=" col-2 " style="text-align: end;">
            <a href="/"> <button class="btn btn-danger">Volver</button></a>
        </div>
        <div class="col-12">
            <h1
                style="text-align: center; margin-top: 20px;  font-size: 28px; font-weight: 500; ">Memorias
            </h1>
        </div>

    </div>
</div>

<div class="row mt-3 justify-content-center ">
    <div class="col-md-6">

        <div class="m-2" style="float: left;">
          <button *ngIf="dia!='miercoles'" (click)="cambioDia('miercoles')" class="btn btn-orange">Miércoles 4 de
            octubre</button>
          <span *ngIf="dia=='miercoles'"  class="btn btn-miercoles" style="font-size: 20px; font-weight:bold;  ">Miercoles 4 de
            octubre</span>
        </div>
        <div class="m-2" style="float: left;">
          <button *ngIf="dia!='jueves'" (click)="cambioDia('jueves')" class="btn btn-orange">Jueves 5 de octubre</button>
          <span *ngIf="dia=='jueves'" class="btn btn-miercoles"  style="font-size: 20px; font-weight:bold; ">Jueves 5 de octubre</span>
        </div>
        <div class="m-2" style="float: left;">
          <button *ngIf="dia!='viernes'" (click)="cambioDia('viernes')" class="btn btn-orange">Viernes 6 de
            octubre</button>
          <span *ngIf="dia=='viernes'" class="btn btn-miercoles"  style="font-size: 20px; font-weight:bold;  ">Viernes 6 de
            octubre</span>
        </div>
      </div>

</div>
<hr>
<div class="row mt-0 justify-content-center ">
    <div class="col-md-9">
        <div class="m-2" style="float: left;">
            <button *ngIf="lugar!=0" (click)="cambioLugar(0)"
                class="btn btn-orange">{{rooms[0]}}</button>
            <span *ngIf="lugar==0"  class="btn btn-miercoles"
                style="font-size: 20px; font-weight:bold;   text-decoration-color:#f0b152 ;  ">{{rooms[0]}}</span>
        </div>

        <div class="m-2" style="float: left;">
            <button *ngIf="lugar!=1" (click)="cambioLugar(1)"
                class="btn btn-orange">{{rooms[1]}}</button>
            <span *ngIf="lugar==1"  class="btn btn-miercoles"
                style="font-size: 20px; font-weight:bold;  text-decoration-color:#f0b152 ;  ">{{rooms[1]}}</span>
        </div>
        <div class="m-2" style="float: left;">
            <button *ngIf="lugar!=2" (click)="cambioLugar(2)"
                class="btn btn-orange">{{rooms[2]}}</button>
            <span *ngIf="lugar==2"  class="btn btn-miercoles"
                style="font-size: 20px; font-weight:bold;   text-decoration-color:#f0b152 ; ">{{rooms[2]}}</span>
        </div>
        <!-- <div  class="m-2" style="float: left;">
        <button *ngIf="lugar!=3" (click)="cambioLugar(3)" class="btn btn-orange">{{rooms[3]}}</button>
        <span *ngIf="lugar==3" style="font-size: 20px; font-weight:bold; margin:10px;color: white;  "  >{{rooms[3]}}</span>
    </div> -->
    </div>

</div>
<div class="row mt-3 justify-content-center ">
    <div class="col-md-11">
        <div class="row justify-content-center">

            <div *ngFor="let event of eventshow" class="caja m-1 col-md-3">
                <div class="row justify-content-center">
                    <div class="col-12  embed-responsive embed-responsive-16by9">
                        <video class="embed-responsive-item"
                            [src]="event.url| safe" controls
                            controlsList="  nodownload "></video>
                        <!-- <iframe id="iframe" class="embed-responsive-item"  width="98%" frameborder="0" [src]="event.event.url | safe" allowfullscreen style="display: block;"></iframe> -->
                    </div>
                    <div class="col-md-12" *ngIf="event.evento">
                        <span style="font-size: 18px; font-weight:bold;  ">{{event.evento.name}}</span>
                        <br>
                        <div *ngIf="event.evento.speaker"
                            style="font-size: 13px; color: black; margin-top: 10px; ">{{event.evento.speaker}}</div>
                        <span style="color: black;">{{event.evento.date}}</span>
                        <br>
                    </div>

                </div>

                <div *ngIf="user.rol=='admin'">
                    <button (click)="deleteEvent(event._id)"
                        class="btn btn-danger">Eliminar</button>

                </div>

            </div>
        </div>
    </div>
</div>

<br><br><br>

<div class="pop2" *ngIf="pat">
    <div class="row mt-1">
        <div class="col-1" >

        </div>
        <div class="col-10" >
            <h1 style="text-align: center;" >Bienvenido a la plataforma digital de contenidos de +CTG2023</h1>
        </div>
        <div class="col-1">
            <h1 style="font-weight: 500; text-align:center; "><button
                    class="btn btn-danger" (click)="pat=false"
                    style="float: right;">X</button></h1>

        </div>
    </div>
    <div class="row mt-1 justify-content-center bg-white">

        <div class="col-md-1 col-5 m-3 mb-1 mt-1 pat-container"
            *ngFor="let patrocinador of patrocinadores">

            <img class="card-img-top pat vertical-center" width="100%"
                src="../../../assets/patrocinadores2/{{patrocinador}}"
                alt="Card image cap">

        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h1 style="font-weight: 500; text-align:center; "><button
                    class="btn btn-danger" (click)="pat=false">Ver conferencias</button></h1>

        </div>
    </div>

</div>