

<div class="row mt-5 justify-content-center " >

<div class="col-md-6" >
    <h1>Eventos por validar </h1>
    <div *ngFor="let event of privados"  class="caja " >
        - <span style="font-size: 20px; font-weight:bold;  ">{{event.name}}</span> <br>
        <span> Fecha: {{event.date}}</span> <br>
       
        <div  >
           
            <a href="/validacion/{{event._id}}"  class="btn btn-danger" >Validar ingresos</a>

        </div>
       
    </div>
</div>
</div>
<br><br><br>

<div class="pop" *ngIf="vIngresos" >

      <div style="float: right;">
          <span style="font-size: 30px; cursor: pointer; color: white; font-weight: bolder;  "
             (click)="vIngresos=false">X</span>
      
  </div>
  <h2>Total ingresos: {{ingresosPop.length}} </h2>
  <div class="row justify-content-center">
     
      
      <div class="col-md-4 p-2 ingreso m-2" *ngFor="let ingreso of ingresosPop" >
          
          {{ingreso.userid.name}}
          <br>
          {{ingreso.userid.email}}
          <br>
          {{ingreso.userid.doc}}
          <br>
          {{ingreso.userid.company}}
          <br>
          {{ingreso.hora | date:'medium'}}
      </div>
  </div>

</div>