import { Component, OnInit, ElementRef, } from '@angular/core';
import { EventsService } from '../../services/events.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { interval } from 'rxjs';
import { saveAs } from 'file-saver';
import {SocketioService} from "../../services/socketio.service";
import { AuthService } from '../../services/auth.service';
import {ChangeDetectorRef} from '@angular/core'
import  Swal from 'sweetalert2'; 
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-salanet',
  templateUrl: './salanet.component.html', 
  styleUrls: ['./salanet.component.css']
})
export class SalanetComponent implements OnInit {
  createSala: FormGroup;
salas=[];
user;
event;
solicitudes;
intervalid;
sub: Subscription;
existingUsrs=[];
  constructor(private authService : AuthService, 
    private socketservice: SocketioService,
    private elRef:ElementRef,
    private eventService : EventsService, private router: Router, private cd: ChangeDetectorRef) {
    this.createSala= new FormGroup({
    
      'number': new FormControl('', [
        Validators.required,
        ]),
        'video': new FormControl('', [
          Validators.required,
          ]),
          'url': new FormControl('', [
            Validators.required,
            ]),
         
       
     
    });
  

   }
   ngOnDestroy() {
    this.socketservice.close();
     }
  ngOnInit(): void {
    let user = localStorage.getItem('userInfo')
    this.user= JSON.parse(user).user;
this.loaduser();

      
   const manage=this.manage;
   this.sub= this.socketservice.setupSocketConnection()
  .subscribe({
   next(_mesage) {
     //console.log('Mensage recibido: ', _mesage);
    manage(_mesage);  
   }
  });
  this.loadAllSalas();
    this.intervalid = setInterval(() => {
      this.loadAllSalas();
    }, 15000);
    this.event={"_id":"salasnet"};
    this.joinRoom(this.user,this.event);
  }

  loaduser(){
    this.authService.loaduser()
    .then((response)=>{
      this.authService.setUserInfo({'user' : response['user']});
      let user = localStorage.getItem('userInfo')
      this.user= JSON.parse(user).user;
    })
    .catch(()=>{
      location.href="/login"
    })
  }

  manage=(msg)=>{
    const existingUsers=(usrs)=>{
    if(true){
      var usuarios=[];
      console.log(usrs);
      usrs.forEach(element => {
        if(element.correo != this.user.correo){
          var id=element.correo.replace("@", "11");
          id=id.replace(/\./g, "11");
          element.id=id;
          usuarios.push(element);
        }
      });
     this.existingUsrs=usuarios;}
      }
    const addUser=(usr)=>{
    if(this.user.correo != usr.correo){
      var id=usr.correo.replace("@", "11");
      id=id.replace(/\./g, "11");
      usr.id=id;
  var usuario ={"name":usr.name ,"id":usr.id, "correo":usr.correo,"rol":usr.rol,"room":usr.room,"user":usr}
  this.existingUsrs.push(usuario);
    } 
    }
    const userLeft =(usr)=>{
      var i=0;
      console.log("userleft"+usr.correo);
      this.existingUsrs.forEach(us=>{
  if(us.correo==usr.correo){
  this.existingUsrs.splice(i, 1);
  }
  i=i+1;
      })
    }
  
  const reciveChat =(msg)=>{
  try{
  //  this.audio.play();
 
  // console.log(msg);
  // if (this.user.rol=="admin"){
    var id=msg.from.replace("@", "11");
    id=id.replace(/\./g, "11");
    
    var place = this.elRef.nativeElement.querySelector('#chat_txt'+id);
    this.elRef.nativeElement.querySelector('#li'+id).innerHTML=msg.fromName+" (1)";
     place.appendChild(document.createTextNode(msg.fromName+": "));
    place.appendChild(document.createTextNode(msg.text));
    place.appendChild(document.createElement("br"));
    place.scrollTop = place.scrollHeight;
  // }
  // else if(this.user.rol=="usuario"){
  //   var place = this.elRef.nativeElement.querySelector('#chat_txtadmin1');
  //   this.elRef.nativeElement.querySelector('#btnChat').innerHTML="Solicitar videollamada (1)";
  //   place.appendChild(document.createTextNode(msg.fromName+": "));
  //   place.appendChild(document.createTextNode(msg.text));
  //   place.appendChild(document.createElement("br"));
  //   place.scrollTop = place.scrollHeight;
  
  //}
}
  catch(err){}
  
  }
  const callin=(msg)=>{
  let user=this.user;
    Swal.fire(     {
      title: "Estas recibiendo una invitación de "+msg.from,
      text:"¿que deseas hacer?",
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      cancelButtonText: "Rechazar"
      
  }).then((result)=>{
    var mesg={};
  if(result.value){
 
   mesg={
    "id":"callResponse",
    "response":"accept",
    "to":msg.emailfrom,
    "from":user.name,
    "emailfrom":user.correo
  }
  this.socketservice.send(mesg);
  }
  else{
    mesg={
      "id":"callResponse",
      "response":"refuse",
      "to":msg.emailfrom,
      "from":user.name
    }
    this.socketservice.send(mesg);
  }
     });
  
  
  }
  const callResponse=(msg)=>{
  if(msg.response=="accept"){
   
 this.irsala(msg.sala);

  }
  else if(msg.response=="refuse"){
  
  Swal.fire(msg.from+"ha rechazado tu llamada ...");
  
  }
  }
  
  const colgar=(msg)=>{
  //   this.ovSession.disconnect();
  // this.session=false;
  }
  
  const recivePull=(msg)=>{
    // this.activePull=true;
    //   //this.elRef.nativeElement.querySelector('#pull').value=msg.pull;
    //   this.pull_txt=msg.pull;
    
    }
    const activebSalas=()=>{
    //  this.activesalas=true;
    }
    const inactivebSalas=()=>{
      //this.activesalas=false;
    }
    const recivePanic=()=>{
      location.reload();
    }
    const closePull=()=>{
     // this.activePull=false;
    }
    const invitein=(msg)=>{
      let user=this.user;
      Swal.fire(     {
        title: "Estas recibiendo una invitacion de "+msg.from,
        text:"¿que deseas hacer?",
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        cancelButtonText: "Rechazar"
        
    }).then((result)=>{
      if(result.value){
    location.href=msg.url;
      }
      else{}
    })
    
    }

    const accesResponse=(msg)=>{
      if(msg.response=="accept"){
      location.href=msg.url;}
      else{
        Swal.fire("Lo sentimos, en este momento los participantes de esta sala se encuentran ocupados");
      }

    }
  
      var p_msg=JSON.parse(msg);
      console.log(p_msg);
    switch (p_msg.id){
      case "recivePanic":
          recivePanic();
          break;
          case "activeSalas":
            activebSalas();
            break;
            case "inactiveSalas":
              inactivebSalas();
              break;
      case "newUser":
        addUser(p_msg.user);
        //console.log(p_msg);
        break; 
      case "existingUsers":
        //console.log(p_msg.users);
        existingUsers(p_msg.users);
        break;
        case "userLeft":
          userLeft(p_msg.user);
          break;
        case "reconnect":
          this.joinRoom(this.user,this.event);
          break;
          case "inInvite":
            invitein(p_msg);
            break;
        case "reciveChat":
        reciveChat(p_msg);
        break;
        case "recivePull":
          recivePull(p_msg);
          break;
         case "closePull":
          closePull();
            break;
         case "callin":
              callin(p_msg);
              break;
      case "callResponse":
        callResponse(p_msg);
        break;
        case "colgar":
          colgar(p_msg);
          break;
          case "accesResponse":
            accesResponse(p_msg);
            break;
   
    }
  }

contactar(to){
  if(this.user.contactosenv){
    this.user.contactosenv.push(to.correo)
  }
  else{
    this.user.contactosenv=[to.correo]
  }
  this.eventService.contactar(this.user,to);
}

    joinRoom(usr,event){
      this.socketservice.joinRoom(usr,event);
      }
      
  irsala(sala){
    this.eventService.getAllSalas()
    .then((response)=>{
    this.salas=response["salas"];
    var ss=this.salas.find(x => x._id ===sala._id);
    console.log("sala",ss);
       if(ss.status=='open'){
       location.href="/sala/"+ss._id
     }
     else{
       Swal.fire("Esta sala ha sido cerrada. si lo desesas puedes solicitar acceso");
     
     }
         });
  
  
  }

  newEvent(){
    this.eventService.createSalas(this.createSala.value)
    .then((response) => {
      console.log(response);
      var empty={
        number:"",
       
      };
      this.createSala.reset(empty );
      this.loadAllSalas();
    })
    .catch((err)=>{
     console.log(err);
  })
  }


  private loadAllSalas() {
    
      this.eventService.getAllSalas()
      .then((response)=>{
      this.salas=response["salas"];
      console.log("salas",this.salas);
           });
  
  }
  cambiarEstado(id,status){
    var newstatus;
    if(status=="close"){
      newstatus="open"
    }
    else{
      newstatus="close"
    }
    this.eventService.changestatus(id,newstatus)
    .then((response)=>{
   this.loadAllSalas();
    })
    .catch((err)=>{
    
    })
  
  }

solicitar(id){

  var message = {
    id : 'callAccess', 
    name : this.user.name,
    from:this.user.correo,
    event:id
  };
  this.socketservice.send(message);
}


  closeChatAdmin(correo){
    var div = this.elRef.nativeElement.querySelector('#chat'+correo);
    div.style.display="none";
    
  }
  
  openChatAdmin(correo){
    console.log(correo);
    var div = this.elRef.nativeElement.querySelector('#chat'+correo);
    div.style.display="block";
    var text=this.elRef.nativeElement.querySelector('#li'+correo).innerHTML
   var ind= text.search("(1)");
    if(ind==-1){
      this.elRef.nativeElement.querySelector('#li'+correo).innerHTML=text;
    }
    else {
      var ftext=text.slice(0,ind-1);
      this.elRef.nativeElement.querySelector('#li'+correo).innerHTML=ftext;
    }
    
  }

  inviteuser(user){
    console.log("call to "+user);
  
           var message = {
            id : 'calluser',
            name : this.user.name,
            from:this.user.correo,
             to: user.user.ss
          };
          this.socketservice.send(message);
           Swal.fire("llamando a: "+user.name+" ...");
          setTimeout(()=>{Swal.close()},3000);
        
    }
}
