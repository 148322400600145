import { Component, OnInit, Output, EventEmitter, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import Swal from 'sweetalert2';

import QrScanner from 'qr-scanner';
import { AuthService } from '../../services/auth.service';
import { EventsService } from '../../services/events.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
@Component({
  selector: 'app-lector-eventos',
  templateUrl: './lector-eventos.component.html',
  styleUrls: ['./lector-eventos.component.css']
})
export class LectorEventosComponent implements OnInit {

  data: boolean=false;
  manilla: any;
  result: any
  cameras:any;
  qrScanner: any;
  @ViewChild('video1', { static: false}) videoElement: ElementRef;
  inter=null;
  ipersonForm: FormGroup;
  submitted: boolean;
  id: any;
  event: any;
  almuerzos=["1","7","10","12"];
  mostrarImp: boolean;
  constructor(
    private authService: AuthService,
    private changeDetectorRef: ChangeDetectorRef,
   
    private route: ActivatedRoute, 
    private eventoService:EventsService
  ) { }


  ngOnInit(): void {
    this.id=this.route.snapshot.paramMap.get("id")
    this.getEvent();
    this.ipersonForm = new FormGroup({

      'id': new FormControl('', [
        Validators.required,
          ]),    
    });
    this.inter=setInterval(()=>{
      console.log("i ")
    if(this.videoElement.nativeElement){
      this.iniciar();
    } 
    },100)
    
  }


  getEvent(){
    this.eventoService.load(this.id)
    .then((r:any)=>{
      this.event=r.event;
      console.log(this.event);
      
    })
  }

  get f4() { return this.ipersonForm.controls; }
  changeInput(event){

    console.log( this.ipersonForm.controls['id'].value)
    //this.ipersonForm.controls['id'].value= this.ipersonForm.controls['id'].value.replace(/^0+/, '');
    var value=this.ipersonForm.controls['id'].value.replace(/\s/g, '');
    value=value.replace(/^0+/, '');
    this.ipersonForm.controls['id'].patchValue(value)
    }
    
  title = 'qr';
  camaras:any = [] ;
  desiredDevice:any = "";
  selectedCamera(id){
    console.log(id)
    this.qrScanner.setCamera(id);
  }



  iniciar(){
clearInterval(this.inter);
    this.qrScanner = new QrScanner(this.videoElement.nativeElement,(result) => {
     
      //console.log("RES", this.result.data)
      
      if (result) {
       
        if(!this.data){
          this.scanSuccessHandler(result.data);
          this.qrScanner.stop()
          
          this.qrScanner.destroy()
        }

        //this.destroy();
        
       // this.ngOnInit();
        // return
  
      }
   
    }, {
      highlightScanRegion: true,
      highlightCodeOutline: true });
  
    this.qrScanner.start()
    QrScanner.listCameras().then((r)=>{
      console.log(r)
      this.cameras = r
    })
  }
  cancelar(){
    location.reload()
  }
  marcar(){
    this.eventoService.newingreso(this.manilla,this.id)
    .then((r)=>{
      console.log(r)
      if(r==null){
        Swal.fire("Error ")
      .then(()=>{location.reload()})
      }
      else{
        if(r["already"]){
          Swal.fire("Ya se habia registrado su ingreso")
          .then(()=>{
            
            this.changeDetectorRef.detectChanges();
          })
        }
        else{
          Swal.fire("Ingreso registrado correctamente")
          .then(()=>{
            
            this.changeDetectorRef.detectChanges();
          })
        }
       
      }
      
    })
    .catch((er)=>{
      console.log(er)
      Swal.fire("Error ")
      .then(()=>{location.reload()})
    })
  }
  newIngreso(user){
    this.manilla=user;
    console.log("validando",this.id)
    this.eventoService.newingreso(this.manilla,this.id,)
    .then((r)=>{
      console.log(r)
    
      if(r==null){
        Swal.fire("Error ")
      .then(()=>{location.reload()})
      }
      else{
        if(r["already"]){
          Swal.fire("Ya se habia registrado su ingreso")
          .then(()=>{
            this.data=true;
            this.changeDetectorRef.detectChanges();
            location.reload();
          })
        }
        else{
          Swal.fire("Ingreso registrado correctamente")
          .then(()=>{
            this.data=true;
            this.changeDetectorRef.detectChanges();
            location.reload();
          })
        }
       
      }
      
    })
    .catch((er)=>{
      console.log(er)
      Swal.fire("Error ")
      .then(()=>{location.reload()})
    })
  
  
  }
  
  ingresop(){
    this.submitted = true;
    if (this.ipersonForm.invalid) {
     console.log("invalido");
     return;
  }
  this.authService.loadcontactid(this.ipersonForm.get("id").value)
.then((response)=>{
  console.log("usuario:",response)
if(response["user"]!= null){

 
//this.newIngreso(response["user"]);
this.manilla=response["user"];
this.data=true;

}
else{
  Swal.fire("Error ")
  .then(()=>{location.reload()})
}

})
.catch((err)=>{
  console.log(err)
  Swal.fire("Error ")
  .then(()=>{location.reload()})
})

  }  


  scanSuccessHandler(e:string){
    console.log("lectura: ",e)
   this.data=true;
this.authService.loadcontact(e)
.then((response)=>{
  console.log("usuario:",response)
if(response["user"]!= null){

  this.manilla=response["user"];
  this.data=true;
  this.changeDetectorRef.detectChanges();

}
else{
  Swal.fire("Error leyendo código")
  .then(()=>{location.reload()})
}

})
.catch((err)=>{
  console.log(err)
  Swal.fire("Error leyendo código")
  .then(()=>{location.reload()})
})
    
 
  }

  camerasFoundHandler(e:any){
    console.log(e)
    this.camaras = e;
  }

  
  seleccionarCamara(deviceValue:any) {
    console.log(deviceValue);
    if(deviceValue != ""){
      this.desiredDevice = deviceValue
    }
}


termina(){
  if(this.event.almuerzo){
    if(  this.almuerzos.includes(this.manilla.ticket.type)|| (this.manilla.ticket.type=="2" && this.manilla.ticket.buyfor)){
      this.newIngreso(this.manilla)
    }
    else{
      Swal.fire("Tu inscripción no contempla este servicio")
      .then(()=>{location.reload()})
    }
    
  }
  else{
    this.newIngreso(this.manilla)
  }
 
}

addContact(){
  this.authService.addContact(this.manilla._id)
  .then((r)=>{
    console.log(r)
    Swal.fire("Agregado correctamente")
    .then((r)=>{
      location.href="/"
    })
  })
  .catch((err)=>{
    console.log(err);
    Swal.fire("Error agregando usuario")
    .then((r)=>{location.reload()})
  })
}


download(){
  this.marcar();

  console.log("usrimp",this.manilla);
  //this.newIngreso(this.manilla)
  this.mostrarImp=true;
  this.changeDetectorRef.detectChanges()
setTimeout(()=>{
  let DATA: any = document.querySelector("#escarapela");
  console.log(DATA);
  var divContents = document.querySelector("#escarapela").innerHTML;
  var a = window.open('', '', 'height=700, width=1000');
  a.document.write('<html>');
  a.document.write(divContents);
  a.document.write('</body></html>');
   a.document.close();
  a.print();
  a.close()

   this.mostrarImp = false;
   this.changeDetectorRef.detectChanges()
  location.reload();
},1000)


}

download2(){
  this.marcar();
  this.mostrarImp=true;
  //this.newIngreso(this.manilla)
  this.changeDetectorRef.detectChanges()
  setTimeout(()=>{
    

    let DATA: any = document.querySelector("#escarapela");
    console.log(DATA);
    
    html2canvas(DATA,{scale:4}).then((canvas)=> {
      canvas.getContext('2d');
      var HTML_Width = canvas.width;
      console.log("can",canvas.width,canvas.height);
      var HTML_Height = canvas.height;
      var top_left_margin = 0;
      var PDF_Width:any = HTML_Width+(top_left_margin*2);
      var PDF_Height:any = (PDF_Width*1.5);
      var canvas_image_width =HTML_Height;
      var canvas_image_height =  HTML_Width;
      
      var totalPDFPages = Math.ceil(HTML_Height/PDF_Height)-1;
   
      
      var imgData = canvas.toDataURL("image/png", 1.0);
      let pdf = new jsPDF('l', 'cm',  [10,8]);
          pdf.addImage(imgData, 'PNG', 0, 0,10,8);
      //var a=window.open(pdf.output('bloburl'), '_blank')
      var ram=Math.floor(Math.random() * 101);
      pdf.autoPrint({variant: 'non-conform'});
        pdf.save("escarapela-"+this.manilla._id+ram+".pdf");
       // location.reload();
      })

    this.mostrarImp = false;
   
    this.changeDetectorRef.detectChanges()
},1000)
}

}
