<div class="container" >
    <form [formGroup]="createNota" novalidate="">  
        <div class="row p-2">
            <textarea  id="textArea" #textArea formControlName="text" style="width: 100%" rows="6"></textarea>
        </div>
        <div class="row m-1" >
            <button class="btn btn-form2 mr-2" (click)="newNota()">Guardar</button>
            <button class="btn btn-form2" (click)="download()">Descargar</button>
        </div>
    </form>
</div>