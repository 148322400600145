<div style="width: 100%; background-color: white; ">
    <div style="width: 100%; margin: 0; z-index: 9999999; background-color: transparent;"  class="row">
        <div class="col-12 barra p-1 m-0">
            
                    
           <img *ngIf="showimage" src={{user.photoavatar}} style="width: 60px;border-radius: 40%;  " alt="foto de perfil" width="7%">
         
        
           <img *ngIf="!showimage" src="https://selesoluciona.com:8351/fotosmarcos/profile.png" alt="foto de perfil" width="30px" style="border-radius: 50%;" >
         <a href="/"> <img src="../../../assets/botones/—Pngtree—vector house icon_4013710.png" width="40px" style="margin: 0; cursor: pointer;" alt=""> </a>
            <span  class="titulo" style="float: right; color: black; font-size: large; font-weight: bold;"><strong>Bienvenido</strong> {{user.name}} <span  *ngIf="user.puntos"> - Tienes {{user.puntos}} puntos disponibles</span></span>


        </div>
        <div class="col-12 m-0 ">
            <a href="/profile" >Editar perfil</a>
        </div>
    </div>
    
 
</div>
