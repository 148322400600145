import { Component, OnInit, ElementRef, Input,} from '@angular/core';
import { EventsService } from '../../services/events.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { interval } from 'rxjs';
import { saveAs } from 'file-saver';
import {SocketioService} from "../../services/socketio.service";
import { AuthService } from '../../services/auth.service';
import {ChangeDetectorRef} from '@angular/core'
import  Swal from 'sweetalert2'; 
import { first } from 'rxjs/operators';
@Component({
  selector: 'app-solicitudes',
  templateUrl: './solicitudes.component.html',
  styleUrls: ['./solicitudes.component.css']
})
export class SolicitudesComponent implements OnInit {
  @Input() user ={"name":"Soporte","correo":"na@gmal","rol":"usuario","contactos":[],"contactosenv":[]};
  constructor(private authService : AuthService, 
    private socketservice: SocketioService,
    private elRef:ElementRef,
    private eventService : EventsService, private router: Router, private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.loaduser();
  }
  loaduser(){
    this.authService.loaduser()
    .then((response)=>{
      this.authService.setUserInfo({'user' : response['user']});
      let user = localStorage.getItem('userInfo')
      this.user= JSON.parse(user).user;
    })
    .catch(()=>{
      location.href="/login"
    })
  }
  contactar(to){
    if(this.user.contactosenv){
      this.user.contactosenv.push(to.correo)
    }
    else{
      this.user.contactosenv=[to.correo]
    }
    this.eventService.contactar(this.user,to);
  }
    
}
