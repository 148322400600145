import { Component, OnInit } from '@angular/core';
import { EventsService } from '../../services/events.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { interval } from 'rxjs';
import { saveAs } from 'file-saver';
import { AuthService } from '../../services/auth.service';
import { ChangeDetectorRef } from '@angular/core'
import Swal from 'sweetalert2';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  intervalid;
  user;
  emptyUser;
  createEvent: FormGroup;
  events = [];
  pevents = [];
  premium = [];
  enabled;
  rooms = ["Salón INTELIGENCIA ARTIFICIAL", "Salón INTELIGENCIA CREATIVA", "Salón INTELIGENCIA HUMANA"]
  questions: any;
  url1: any;
  jueves: any;
  viernes: any;
  privados: any;
  dia: any;
  lugar: any;
  eventshow: any[];
  miercoles: any[];
  constructor(private authService: AuthService, private eventService: EventsService, private router: Router, private cd: ChangeDetectorRef) {
    this.createEvent = new FormGroup({
      'img': new FormControl("", []),
      'imgSrc': new FormControl("", []),
      'id': new FormControl("0", [Validators.required,]),
      'name': new FormControl('', [
        Validators.required,
        Validators.minLength(5),
      ]),

      'speaker': new FormControl('', [
        Validators.required,

      ]),
      'day': new FormControl('', [

      ]),
      'room': new FormControl('', [

      ]),

      'date': new FormControl('', [
        Validators.required,
        Validators.minLength(5),
      ]),
      'tipo': new FormControl('', [

        Validators.minLength(2),
      ]),
      'almuerzo': new FormControl('', [


      ]),
      'pay': new FormControl('', [
        Validators.required,
        Validators.minLength(2),
      ]),
      'iframeSrc': new FormControl('', [

        Validators.minLength(3),
      ]),
      'meet': new FormControl('', [

        Validators.minLength(3),
      ]),
      'externoSrc': new FormControl('', [

        Validators.minLength(10),
      ]),
    });



  }

  ngOnInit(): void {
    this.enabled = false;
    let user = localStorage.getItem('userInfo')
    this.user = JSON.parse(user).user;
    console.log(this.user);
    this.getEvents();
    this.loadurls();
    this.loadpast();
    this.intervalid = setInterval(() => {
      this.getEvents();
    }, 50 * 1000);

  }
  ngOnDestroy() {
    if (this.intervalid) {
      clearInterval(this.intervalid);
    }
  }
  loadurls() {
    this.authService.geturl()
      .then((response) => {
        console.log(response);
        this.url1 = response["url1"];

      })
  }
  changeState(url) {
    this.eventService.changeStatusAuditorio(url)
      .then((response) => {
        this.ngOnInit();
      }

      )
  }


  onFileChange(event) {
    let reader = new FileReader();

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {

        this.createEvent.patchValue({
          imgSrc: reader.result
        });

        // need to run CD since file load runs outside of zone
        this.cd.markForCheck();
      };

    }
  }

  getEvents() {
    this.miercoles = [];
    this.jueves = [];
    this.viernes = [];
    this.privados = [];
    var now = new Date();
    var n = now.getUTCHours();
    if (n < 5) {
      n = 24 + n;
    }
    function compare(a, b) {
      if (a.date < b.date) {

        return -1;
      }
      if (a.date > b.date) {
        return 1;
      }
      return 0;
    }


    now.setHours(n - 5);
    console.log(now);
    this.eventService.getAll()
      .then((r) => {
        console.log(r)
        this.events = [];
        var events = r["events"]
        events.sort(compare);
        events.forEach(element => {
          //console.log(element);
          var date = new Date(element.date);
          element.dates = element.date;
          var fecha = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getUTCFullYear() + " hora: " + date.getHours() + ":" + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
          element.date = fecha;

          if (now.getTime() > date.getTime() - (300 * 1000)) {
            element.isnow = true
          }
          if (element.pay != "premium") {
            this.events.push(element)
            switch (element.day) {
              case "miercoles":
                this.miercoles.push(element);
                break;
              case "jueves":
                this.jueves.push(element);
                break;
              case "viernes":
                this.viernes.push(element);
                break;
              default:
                break;
            }
          }
          else {
            this.privados.push(element);

          }


        });
        this.cambioDia("miercoles")
        this.cambioLugar(0)
      })
  }
  cambioDia(dia) {
    this.dia = dia;
    switch (dia) {
      case "miercoles":
        this.events = this.miercoles;
        break;
      case "jueves":
        this.events = this.jueves;
        break;
      case "viernes":
        this.events = this.viernes;
        break;
      default:
        break;
    }
    this.cambioLugar(this.lugar);
  }

  cambioLugar(lugar) {
    this.lugar = lugar;
    this.eventshow = this.events.filter((r) => { return r.room == lugar })

  }

  private loadAllEvents() {
    var now = new Date();
    var n = now.getUTCHours();
    if (n < 5) {
      n = 24 + n;
    }
    function compare(a, b) {
      if (a.date < b.date) {

        return -1;
      }
      if (a.date > b.date) {
        return 1;
      }
      return 0;
    }


    now.setHours(n - 5);
    console.log(now);
    this.eventService.getAll()
      .then((response) => {
        console.log(response);
        var events = response["events"];
        events.sort(compare);
        var eventos = []
        var premium = [];
        events.forEach(element => {
          //console.log(element);
          var date = new Date(element.date);
          var fecha = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getUTCFullYear() + " hora: " + date.getHours() + ":" + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
          element.date = fecha;
          if (now.getTime() > date.getTime() - (300 * 1000)) {
            element.isnow = "true"
          }
          //console.log(date.getDate());
          eventos.push(element);
          if (element.pay == "premium") {
            premium.push(element);
          }
        });
        this.events = response["events"];
        this.premium = premium;
      });

  }

  loadpast() {
    this.eventService.getAllp()
      .then((response) => {
        this.pevents = response["events"];
      })

  }
  async memoria(event) {

    const { value: url } = await Swal.fire({
      title: 'Ingresa la url de iframe',
      input: 'text',
      background: "#F0F8FF",
      inputPlaceholder: 'Contraseña',
      confirmButtonColor: "#696969",

    })

    if (url) {
      var memoria = {
        evento: event._id,
        url: url
      }
      console.log(memoria)
      this.eventService.newMemoria(memoria)
        .then((r) => { Swal.fire("creada correctamente") })
    }
  }

  newEvent() {

    this.createEvent.patchValue({
      tipo: "iframe"
    })
    this.eventService.create(this.createEvent.value)
      .then((response) => {
        console.log(response);
        this.emptyUser = {
          name: "",
          date: "",
          id: "0"
        };
        this.createEvent.reset(this.emptyUser);
        this.loadAllEvents();
      })
      .catch((err) => {
        console.log(err);
      })
  }
  removeEvent(id) {
    Swal.fire({
      title: "Estas seguro que deseas archivar el evento? ",
      text: "",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Archivar',
      cancelButtonText: "Cancelar"

    }).then((result) => {
      if (result.value) {

        this.eventService.delete(id)
          .then((response) => {
            console.log(response);
            this.loadAllEvents();
            this.loadpast()
          })
          .catch((err) => {
            console.log(err);
          })
      }
      else { }
    });
  }

  removepEvent(id) {
    Swal.fire({
      title: "Estas seguro que deseas eliminar el evento? ",
      text: "Se perderan todos los datos",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar',
      cancelButtonText: "Cancelar"

    }).then((result) => {
      if (result.value) {

        this.eventService.deletep(id)
          .then((response) => {
            console.log(response);
            this.loadAllEvents();
            this.loadpast()
          })
          .catch((err) => {
            console.log(err);
          })
      }
      else { }
    });
  }


  registrados(id, name) {
    this.eventService.registrados(id)
      .then((response) => {
        console.log(response["results"]);
        var rows = [];
        response["results"].forEach(element => {
          rows.push(element.user);
        });

        let csvContent = "data:text/csv;charset=utf-8,";
        var user = [];
        rows.forEach((rowArray) => {
          delete rowArray.votos;
          delete rowArray.events;
          delete rowArray.aprobe;
          delete rowArray.contactos;
          delete rowArray.contactosenv;
          delete rowArray.compras;
          delete rowArray._id;
          delete rowArray._v;
          delete rowArray.rol;
          delete rowArray.pay;
          user = Object.values(rowArray);



          console.log(user);
          let row = user.join(",");
          csvContent += row + "\r\n";
        });
        saveAs(csvContent, "registrados_" + name + ".csv");

      })
      .catch((err) => {
        console.log(err);
      })
  }


  asistentes(id, name) {
    this.eventService.asistentes(id)
      .then((response) => {
        console.log(response["results"]);
        var rows = [];
        response["results"].forEach(element => {
          rows.push(element.user);
        });

        let csvContent = "data:text/csv;charset=utf-8,";
        var user = [];
        rows.forEach((rowArray) => {
          delete rowArray.votos;
          delete rowArray.events;
          delete rowArray.contactos;
          delete rowArray.contactosenv;
          delete rowArray.aprobe;
          delete rowArray.compras;
          delete rowArray._id;
          delete rowArray._v;
          delete rowArray.rol;
          delete rowArray.pay;
          user = Object.values(rowArray);



          console.log(user);
          let row = user.join(",");

          csvContent += row + "\r\n";
        });
        saveAs(csvContent, "asistentes_" + name + ".csv");

      })
      .catch((err) => {
        console.log(err);
      })
  }


  edit(event) {
    var evento: Object = {
      id: event._id,
      name: event.name,
      date: event.dates,
      pay: event.pay,
      speaker: event.speaker,
      day: event.day,
      room: event.room,
      iframeSrc: event.eventSrc,
      externoSrc: event.eventSrc

    }
    this.createEvent.reset(evento);
  }

  logout() {
    localStorage.removeItem('userInfo');
    this.router.navigate(['inicio']);
  }

  ingresar(event, id) {
    this.eventService.ingreso(event, this.user)
      .then((response) => {
        console.log(response);
        var events = this.user.events
        var index = events.indexOf(id);
        if (index > -1) {
          this.user.events.splice(index, 1);
        }
        //switch para taller e interno, modulo interno
        switch (event.tipo) {

          case "iframe":
            location.href = "/conferencia/" + id;
            break;
          case "taller":
            location.href = "/taller/" + id;
            break;
          case "interno":
            location.href = "/conferencia/" + id;
            break;
        }

      })
      .catch((err) => {
        console.log(err);
      })

  }
  ingresarp(event, id) {
    switch (event.tipo) {
      case "iframe":
        location.href = "/conferencia/" + id;
        break;
      case "taller":
        location.href = "/taller/" + id;
        break;
      case "interno":
        location.href = "/conferencia/" + id;
        break;
    }
  }
  irExternop(event, id, src) {
    location.href = src;
  }

  irExterno(event, id, src) {
    this.eventService.ingreso(event, this.user)
      .then((response) => {
        console.log(response);
        var events = this.user.events
        var index = events.indexOf(id);
        if (index > -1) {
          this.user.events.splice(index, 1);
        }
        location.href = src;
      })
      .catch((err) => {
        console.log(err);
      })

  }

  registrarme(event) {
    this.eventService.register(event, this.user)
      .then((response) => {
        console.log(response);
        this.user.events.push(event._id);
        this.authService.setUserInfo({ 'user': this.user });
      })
      .catch((err) => {
        console.log(err);
      })

  }

  ir_url() {
    location.href = "https://selesoluciona.com:4201/#/ipservices"
  }



}
