<div class="pop" *ngIf="pop" >
   
    <div class="row justify-content-center mt-5"  >
      
    <div class="col-md-8  bg-white col-10  justify-content-end" style="text-align: end;">
    <img (click)="closepop()" style="cursor: pointer;" src="/assets/x.png" width="10%" alt="">
    </div>
        </div>
    <div class="row justify-content-center"  >
    <div class="col-md-8 col-10 bg-white">
    <img src="{{popSrc}}" class="horizontal" width="99%" alt="">
  
    </div>
    </div>
    </div>



<div  class="lienzo">
    <br>
    <br><br><br><br>
  
    <div style="padding: 20px;">
       
        <div class="row justify-content-center" >
            
            <div class="col-md-11">
                
                <div  class="caja_formulario2">
                    <div>
                        <div class="btn-group mr-2" role="group" aria-label="Días">
                            <button (click)="selectDay(1)" type="button" class="btn m-1 btn-d1" >Dia 1</button>
                            <button (click)="selectDay(2)" type="button" class="btn m-1 btn-d2">Día 2</button>
                            <button (click)="selectDay(3)" type="button" class="btn m-1 btn-d3">Día 3</button>
                            <button (click)="selectDay(4)" type="button" class="btn m-1 btn-d4">Día 4</button>
                            <button (click)="selectDay(5)" type="button" class="btn m-1 btn-d5">Día 5</button>
                            <button (click)="selectDay(6)" type="button" class="btn m-1 btn-d1">Cierre</button>

                          </div>
                    </div>
                    <div class="row" *ngIf="day==1">
                        <div style="border-bottom: 0px solid #333; padding: 10px;" *ngFor="let it of d1" class="col-md-4">
                            <div *ngIf="it.type=='imagen' && it.day == day">
                               <img #img style="cursor: pointer;" (click)="openpop(it.url)" width="90%" src="{{it.url}}" /><br>
                                <span style="font-size: 14px;">{{it.name}}</span><br>
                            <button class="btn btn-d1" style="margin-top: 10px;" (click)="downloadImage(img, it.name)">Descargar</button>
                             
                            </div>
                            <div *ngIf="it.type=='video' && it.day == day" class="embed-responsive embed-responsive-16by9" >
                                <iframe width="100%" class="embed-responsive-item" allowfullscreen height="115"  [src]="it.url | safe" > </iframe> 
                              
                            </div>
                            <div *ngIf="it.type=='documento' && it.day == day">
                                {{it.name}} <a href="{{it.url}}">Descargar</a> 
                            </div>
                            <button class="btn btn-danger" (click)="delete(it._id)"  *ngIf="user.rol=='admin'" >Eliminar</button>

                        </div>
                    </div>
                    <div class="row" *ngIf="day==2">
                        <div style="border-bottom: 0px solid #333; padding: 10px;" *ngFor="let it of d2" class="col-md-4">
                            <div *ngIf="it.type=='imagen' && it.day == day">
                                <img #img style="cursor: pointer;" (click)="openpop(it.url)" width="90%" src="{{it.url}}" /><br>

                                <span style="font-size: 14px;">{{it.name}}</span><br>
                                <button class="btn btn-d1" style="margin-top: 10px;" (click)="downloadImage(img, it.name)">Descargar</button>
                           
                          </div>
                            <div *ngIf="it.type=='video' && it.day == day" class="embed-responsive embed-responsive-16by9" >
                                <iframe width="100%" class="embed-responsive-item" allowfullscreen height="115"  [src]="it.url | safe" > </iframe> 
                                
                            </div>
                            <div *ngIf="it.type=='documento' && it.day == day">
                                {{it.name}} <a href="{{it.url}}">Descargar</a> 
                            </div>
                            <button class="btn btn-danger" (click)="delete(it._id)"  *ngIf="user.rol=='admin'" >Eliminar</button>

                        </div>
                    </div>
                    <div class="row" *ngIf="day==3">
                        <div style="border-bottom: 0px solid #333; padding: 10px;" *ngFor="let it of d3" class="col-md-4">
                            <div *ngIf="it.type=='imagen' && it.day == day">
                                <img #img style="cursor: pointer;" (click)="openpop(it.url)" width="90%" src="{{it.url}}" /><br>

                                <span style="font-size: 14px;">{{it.name}}</span>
                                <button class="btn btn-d1" style="margin-top: 10px;" (click)="downloadImage(img, it.name)">Descargar</button>
                           
                            </div>
                            <div *ngIf="it.type=='video' && it.day == day" class="embed-responsive embed-responsive-16by9" >
                                <iframe width="100%" class="embed-responsive-item" allowfullscreen height="115"  [src]="it.url | safe" > </iframe> 
                                
                            </div>
                            <div *ngIf="it.type=='documento' && it.day == day">
                                {{it.name}} <a href="{{it.url}}">Descargar</a> 
                            </div>
                            <button class="btn btn-danger" (click)="delete(it._id)"  *ngIf="user.rol=='admin'" >Eliminar</button>

                        </div>
                    </div>
                    <div class="row" *ngIf="day==4">
                        <div style="border-bottom: 0px solid #333; padding: 10px;" *ngFor="let it of d4" class="col-md-4">
                            <div *ngIf="it.type=='imagen' && it.day == day">
                                <img #img style="cursor: pointer;" (click)="openpop(it.url)" width="90%" src="{{it.url}}" /><br>

                                <span style="font-size: 14px;">{{it.name}}</span>
                                <button class="btn btn-d1" style="margin-top: 10px;" (click)="downloadImage(img, it.name)">Descargar</button>
                           
                            </div>
                            <div *ngIf="it.type=='video' && it.day == day" class="embed-responsive embed-responsive-16by9" >
                                <iframe width="100%" class="embed-responsive-item" allowfullscreen height="115"  [src]="it.url | safe" > </iframe> 
                                
                            </div>
                            <div *ngIf="it.type=='documento' && it.day == day">
                                {{it.name}} <a href="{{it.url}}">Descargar</a> 
                            </div>
                            <button class="btn btn-danger" (click)="delete(it._id)"  *ngIf="user.rol=='admin'" >Eliminar</button>

                        </div>
                    </div>
                    <div class="row" *ngIf="day==5">
                        <div style="border-bottom: 0px solid #333; padding: 10px;" *ngFor="let it of d5" class="col-md-4">
                            <div *ngIf="it.type=='imagen' && it.day == day">
                                <img #img style="cursor: pointer;" (click)="openpop(it.url)" width="90%" src="{{it.url}}" /><br>

                                <span style="font-size: 14px;">{{it.name}}</span>
                                <button class="btn btn-d1" style="margin-top: 10px;" (click)="downloadImage(img, it.name)">Descargar</button>
                           
                            </div>
                            <div *ngIf="it.type=='video' && it.day == day" class="embed-responsive embed-responsive-16by9" >
                                <iframe width="100%" class="embed-responsive-item" allowfullscreen height="115"  [src]="it.url | safe" > </iframe> 
                             
                            </div>
                            <div *ngIf="it.type=='documento' && it.day == day">
                                {{it.name}} <a href="{{it.url}}">Descargar</a> 
                            </div>
                            <button class="btn btn-danger" (click)="delete(it._id)"  *ngIf="user.rol=='admin'" >Eliminar</button>

                        </div>
                    </div>
                    <div class="row" *ngIf="day==6">
                        <div style="border-bottom: 0px solid #333; padding: 10px;" *ngFor="let it of d6" class="col-md-4">
                            <div *ngIf="it.type=='imagen' && it.day == day">
                                <img #img style="cursor: pointer;" (click)="openpop(it.url)" width="90%" src="{{it.url}}" /><br>

                                <span style="font-size: 14px;">{{it.name}}</span>
                                <button class="btn btn-d1" style="margin-top: 10px;" (click)="downloadImage(img, it.name)">Descargar</button>
                           
                            </div>
                            <div *ngIf="it.type=='video' && it.day == day" class="embed-responsive embed-responsive-16by9" >
                                <iframe width="100%" class="embed-responsive-item" allowfullscreen height="115"  [src]="it.url | safe" > </iframe> 
                             
                            </div>
                            <div *ngIf="it.type=='documento' && it.day == day">
                                {{it.name}} <a href="{{it.url}}">Descargar</a> 
                            </div>
                            <button class="btn btn-danger" (click)="delete(it._id)"  *ngIf="user.rol=='admin'" >Eliminar</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-center ">
            <div class="col-md-4" *ngIf="user.rol=='admin'" >
                <div  class="caja_formulario">
                    <form [formGroup]="bitacoraForm" (ngSubmit)="bitacora()" >
                        <div class="form-group">
                            <label>Día:</label>
                            <select formControlName="day" class="form-control">
                                <option value="">-seleccionar-</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">cierre</option>
                              </select>
                            <div *ngIf="submitted && f.day.errors" class="invalid-feedback">
                                <div *ngIf="f.day.errors.required">Selecciona un día</div>
                                
                            </div>
                        </div> 
                        <div class="form-group">
                            <label>Nombre:</label>
                            <input  type="text" class="form-control input-form" placeholder="" formControlName="name"  [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                <div *ngIf="f.name.errors.required">Ingresa un nombre</div>
                                <div *ngIf="f.name.errors.minlength">El nombre debe tener al menos 3 caracteres</div>
                            </div>
                        </div> 
                        <br>
                        <div class="form-group">
                            <label>Tipo:</label>
                            <select #mySelect (change)='onOptionsSelected(mySelect.value)'  formControlName="type" class="form-control">
                                <option value="">-seleccionar-</option>
                                <option value="imagen">Imagen</option>
                                <option value="video">Video</option>
                                <option value="documento">Documento</option>
                              </select>
                            <div *ngIf="submitted && f.type.errors" class="invalid-feedback">
                                <div *ngIf="f.type.errors.required">Selecciona un tipo</div>
                                
                            </div>
                        </div> 
                        <br>
                        <div *ngIf="type == 'video'" class="form-group">
                            <label>Url:</label>
                            <input  type="text" class="form-control input-form" placeholder="" formControlName="url"  >
                            <div *ngIf="submitted && f.url.errors" class="invalid-feedback">
                                <div *ngIf="f.url.errors.required">Ingresa uns url</div>
                                <div *ngIf="f.url.errors.minlength">El nombre debe tener al menos 3 caracteres</div>
                            </div>
                        </div> 
                        <br>
                        <div  *ngIf="type == 'imagen' || type == 'documento' " class="form-group">
                            <label>Archivo</label>
                            <input formControlName="file" accept=".pdf, .png" id="file" type="file" class="form-control" (change)="onFileChange($event)">
                            <div *ngIf="f.file.touched && f.file.invalid" class="alert alert-danger">
                                <div *ngIf="f.file.errors.required">El archivo es necesario</div>
                            </div>
                        </div>
                        <br>
                        
                        <input type="submit" value="Guardar" class="btn btn-form">
        
                    </form>
                    <br>
                    <div *ngIf="response" class="alert alert-success" role="alert">
                        Elemento agregado a la bitácora
                      </div>
                  </div>
            </div>
        </div>
    </div>


    <!-- <div class="contenedor_contenido_centrado_hv">
      <div class="row w-100 contenido_centrado_hv">
        
        <div class="col-11 col-md-6 mt-4">
          
         
         
  
        </div>
      </div>
    </div> -->


  </div>
