<div  class="m-1">
    <div class="row mt-4 justify-content-center">
        <div class="col-md-3 m-2 card" *ngFor="let cortesia of cortesias" >
            {{cortesia.nombre}}
            <br>
            Cupos: {{cortesia.cupos}}
            <br>
            Registrados: {{cortesia.registrados.length}} - {{cortesia.registros}}
            <br>
            Tipo de entrada: 
          
            <span *ngIf="cortesia.tipo=='1'" >Presencial</span> 
            <span *ngIf="cortesia.tipo=='2'" >Virtual</span>
            <span *ngIf="cortesia.tipo=='3'" >Montaje</span>
            <span *ngIf="cortesia.tipo=='4'" >Expositor</span>
            <span *ngIf="cortesia.tipo=='5'" >Logistica</span>
            <span *ngIf="cortesia.tipo=='6'" >Prensa</span>


            <span style="word-wrap: break-word; font-style: italic; " >https://lad.pragma.co/registro/{{cortesia._id}}</span> 
            <button class="btn btn-dark" style="padding: 1px; font-size: 12px; margin-left: 15px;" (click)="copiar(cortesia._id)" >copiar</button> 
            <div class="card" >
                <div *ngFor="let user of cortesia.registrados" >
                    {{user.name}} - {{user.doc}}
                </div>
            </div>
            <button class="btn btn-danger w-50 mt-2" (click)="delete(cortesia._id)" >Eliminar</button>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-md-4">
            <div class="caja_formulario form-signin">
                <form [formGroup]="cortesiaForm" (ngSubmit)="newCortesia()" novalidate="">

                    <div class="form-floating">

                        <input class="form-control" type="text" placeholder="Nombre"
                            formControlName="nombre" [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }">
                        <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback ">
                            <div *ngIf="f.nombre.errors.required">campo obligatorio</div>

                        </div>

                    </div>

                    <div class="form-floating">

                        <input class="form-control" type="number" placeholder="Cupos"
                            formControlName="cupos" [ngClass]="{ 'is-invalid': submitted && f.cupos.errors }">
                        <div *ngIf="submitted && f.cupos.errors" class="invalid-feedback ">
                            <div *ngIf="f.cupos.errors.required">campo obligatorio</div>

                        </div>

                    </div>
                    <div class="form-floating">
                        <select class="form-control" formControlName="tipo" [ngClass]="{ 'is-invalid': submitted && f.tipo.errors }">
                            <option value="">tipo de entrada</option>
                            <option value="1">Presencial</option>
                            <option value="2">Virtual</option>
                            <option value="3">Montaje</option>
                            <option value="4" >Expositor</option>
                            <option value="5" >Logística</option>
                            <option value="6" >Prensa</option>
                          
                        </select>
                    </div>


                    <input type="submit" value="Ingresar" class="w-100 btn btn-orange mt-3">


                </form>
            </div>
        </div>
    </div>
</div>