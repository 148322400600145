import { Component, OnInit,ElementRef, Renderer2, ViewChild} from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import  Swal from 'sweetalert2'; 
import { first } from 'rxjs/operators';
import { OwlOptions } from 'ngx-owl-carousel-o';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
miFormulario: FormGroup;
submitted;
pay;
user;
loading;
stream;
videoWidth = 0;
videoHeight = 0;
error="";
patrocinadores;
  constructor(private authService : AuthService, private router : Router,private renderer: Renderer2) {

    this.miFormulario = new FormGroup({
 
      'correo': new FormControl('', [
        Validators.required,
    
      ]),

     
    });

  

   }

  ngOnInit() { 
  


  }



  
    customOptions: OwlOptions = {
      loop: true,
      mouseDrag: false,
      touchDrag: false,
      pullDrag: false,
      dots: false,
      navSpeed: 700,
      navText: ['', ''],
      responsive: {
        0: {
          items: 1
        },
        400: {
          items: 2
        },
        740: {
          items: 3
        },
        940: {
          items: 4
        }
      },
      nav: true
    }
  

 
  
  ocultar_modal(){
    document.getElementById("loadfondo").style.display = "none";

  }
  get f() { return this.miFormulario.controls; }


login(){
      this.submitted = true;
    if (this.miFormulario.invalid) {
     console.log("invalido");
     return;
  }
  this.authService.validateid(this.miFormulario.get("correo").value)
  .pipe(first())
  .subscribe(
    (res) => {
if(res["user"].ticket || res["user"].rol=="admin"){
  
  this.authService.setUserInfo({'user' : res['user']});
  localStorage.setItem('access_token', res["user"].token);
  if(res["user"]["modo"]=="virtual"){
    this.router.navigate(['cuenta']);  
  }
  else{
    this.router.navigate(['escarapela']); 
  }

}
else{
  
  Swal.fire(     {
    title: "No tienes una inscripción habilitada ",
    text:"Si no has adquirido tu inscripción, puedes hacerlo ahora",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#89c9b8',
    confirmButtonText: 'Comprar inscripción',
    cancelButtonText: "Reintentar"
    
}).then((result)=>{
if(result.value){
  location.href="https://inscripcioneslad.pragma.co/ "
}
else{}
});
}

    } ,
    (err) => {
      Swal.fire(     {
        title: "No tienes una inscripción habilitada ",
        text:"Si no has adquirido tu inscripción, puedes hacerlo ahora",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#89c9b8',
        confirmButtonText: 'Comprar inscripción',
        cancelButtonText: "Reintentar"
        
    }).then((result)=>{
    if(result.value){
      location.href="https://inscripcioneslad.pragma.co/ "
    }
    else{}
    });
    }
  );

}

  miSubmit() {
    console.log(this.miFormulario.value);
    console.log(this.miFormulario );
  }

  



}
