<!-- 
<div *ngIf="user.rol=='usuario'" style="  position: fixed; bottom:10px; right: 2px; z-index: 200;  text-align: center; color: #999; padding: 10px">
    <div style="  position: absolute; bottom:0px; right: 8px;   text-align: center; color: #999; padding: 10px">
  
        <button class="btn btn-orange" type="button" id="btnChat" #btnChat (click)="openChat()" >
         <strong>Solicitar videollamada</strong> 
        </button>
        <img src="./imagen/icon_soporte_small.png"> 
      </div>
      
    <div #chat [style.display]="'none'"  >
        <app-chat >
        </app-chat>
        <button (click)="closeChat()" class="btn btn-danger btnChat " >Cerrar</button>
    
    </div>
</div> -->



    <div style="padding: 5px; text-align: center;">
        <h1 style="color: white" >Bienvenido {{user.name}} </h1> 
        <h3 style="color: white" *ngIf="event"  >{{event.name}}</h3>
  
        <br>
        <div  class="row justify-content-center">
<a href="{{event.meet}}" target="_blank" rel="noopener noreferrer"> 
<button class="btn btn-warning" >
  Ingreso a la reunion
</button>
   
</a>
        </div>
        <div class="row justify-content-center">
          <a href="/preguntas/{{event._id}}" target="black">
          <button class="btn btn-primary m-2">Preguntas</button>
          </a>
          <a href="/votaciones/{{event._id}}" target="black">
              <button class="btn btn-primary m-2">Votaciones</button>
              </a>

              <button class="btn btn-primary m-2" (click)="openPointer()" > 
                  Pointer virtual
              </button>
              <a href="/conferencia/{{event._id}}"> <button  class="btn btn-primary m-2">Volver a la conferencia</button> </a>
      </div>  

          <div class="row justify-content-center" *ngIf="event">
            <!-- <div *ngIf="!session" class=" col-md-7 embed-responsive embed-responsive-16by9">
                <iframe id="iframe" class="embed-responsive-item"  width="98%" frameborder="0" [src]="event.eventSrc | safe" allowfullscreen style="display: block;"></iframe>

            </div>
            

              <div *ngIf="session"  class=" col-md-7  ">
                <div  id="session" width="98%" style="margin-top: 0; height:450px ; text-align: left !important;">
                  <img src="/assets/boton_ingresar.png" id="img_sesion" >
                  <opv-session #ovSessionComponent [sessionName]="event.name" [user]="user.name" [tokens]="tokens" [ovSettings]="ovSettings"
                    (sessionCreated)="handlerSessionCreatedEvent($event)" (participantCreated)="handlerPublisherCreatedEvent($event)" (error)="handlerErrorEvent($event)">
                  </opv-session>
              </div> 
      
              </div> -->
 <div class="col-md-6 justify-content-center" >
    <div class="container text-white" *ngIf="user.rol!='usuario'">
        <button (click)="downUsers()" class="btn btn-primary" >Descargar audiencia</button><br>
        <h3>Usuarios en linea: {{existingUsrs?.length || '0' }} </h3> 
        <ul>
            
    <li (click)="openChatAdmin(usr.id)" *ngFor="let usr of existingUsrs" id="li{{usr.id}}">
    {{usr.name}}
    </li>
        </ul>  
     </div>
 </div>


            <div class=" col-md-6">
              <div >
                <button *ngIf="user.rol=='admin' && session" class="bnt btn-primary" (click)="cerrar_llamada()">Cerrar llamada</button>
                <button *ngFor="let usr of connectedUsers" class="bnt btn-primary" (click)="colgar(usr.correo)"> colgar {{usr.name}} </button>
              </div>
              <div class="justify-content-center">
                <h1>Preguntas</h1>
            
            <div class="bg-dark w-75 container" >
                <ul>
                    <li class="text-white" *ngFor="let question of questions">pregunta por {{question.user.name}}: {{question.question}} </li>
                
                </ul>
            </div>
            </div>
              <br>
  
              <div style="background-color: #eaeaea; padding: 10px; text-align: left; border-radius: 2px 2px 2px 2px" *ngIf="activePull">
                    <textarea disabled name="pull" id="pull" cols="30" rows="5">{{pull_txt}}</textarea><br>
                    <div style="margin-top: 10px">
                    <button (click)="pull('a')" class="btn btn-primary" >A</button> <br>
                    <button (click)="pull('b')"  class="btn btn-primary">B</button> <br>
                    <button  (click)="pull('c')"  class="btn btn-primary">C</button> <br>
                </div>


  
              </div>
  
              <br>
  
  
  
              
  
  
           
             
  
            </div>
          </div>
          <br>
          <br>
          <div class="row justify-content-center" >
         
          </div>
        </div>

       
            <div *ngIf="user.rol=='admin'"  >
                <div *ngFor="let usr of existingUsrs" >
                    <div id="chat{{usr.id}}" style="display: none;">
                <app-chat  [remoteUser]="usr" >
                </app-chat>
                <button (click)="closeChatAdmin(usr.id)" class="btn btn-danger btnChat " >Cerrar</button>
                <button (click)="inviteuser(usr)" class="btn btn-danger btnCall "  >Invitar</button>
                </div>
                </div>
                </div> 
                <div *ngIf="user.rol=='admin'" class="bg-dark text-white m-2 w-50" >
                    <textarea name="spull" id="spull" cols="30" rows="8"></textarea>
                    <br>
                    <button (click)="sendPull()"  class="btn btn-primary m-2" >Enviar</button>
                    <button (click)="closePull()"  class="btn btn-danger m-2" >Cerrar</button>
                    <br>
                    <br>
             
            
                
                </div>
  














<audio id="audio">
    <source src='/ring.mp3' type="audio/mp3">
</audio>



  <!-- <div *ngIf="session" id="session">
    <img src="/assets/boton_ingresar.png" id="img_sesion">
    <opv-session #ovSessionComponent [sessionName]="event.name" [user]="user.name" [tokens]="tokens" [ovSettings]="ovSettings"
      (sessionCreated)="handlerSessionCreatedEvent($event)" (participantCreated)="handlerPublisherCreatedEvent($event)" (error)="handlerErrorEvent($event)">
    </opv-session>
  
 </div>  -->