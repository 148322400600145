<div class="lienzo" >
  <div style="position: fixed; z-index: 9999; top:0px; left: 0px; width: 100%; z-index: 99999999999;">
    <app-menu [user]="user"></app-menu> 
</div> 
<!-- <iframe src="https://eventosefectivos.eventovirtual.info/recorrido2/" frameborder="0"  style=" z-index: 1; position: fixed; top: 0; left: 0;width: 100%;height: 100%; " ></iframe>
 -->
<div  *ngIf="solicitudes"   class="caja-sol" >
  <button (click)="solicitudes=false" style="float: right;" class="btn btn-danger" >Cerrar</button>
  <br>
  <app-solicitudes [user]="user"></app-solicitudes> 
</div> 

<div style="padding: 30px; text-align: center;">
  <h3 style="color: white">Bienvenido {{user.name}}.</h3>
 
    <div class="row d-flex justify-content-around">
      <div class="text-white col-md-3 col-10 mt-5" style="background-color: rgba(1, 1, 1, 0.7); z-index: 2000; height: 500px; overflow-y: auto;"  >
        <button (click)="solicitudes=true" class="btn btn-primary" >Ver solicitudes de contacto</button>
         <h3 style="color: white !important;" >Usuarios en linea: </h3>  
         <!-- {{existingUsrs?.length || '0' }} -->

          <ul>
            <div *ngFor="let usr of existingUsrs">                      
   <li  *ngIf="usr.room == event._id" class="mb-2" >
          <img *ngIf="usr.user.photoavatar" src={{usr.user.photoavatar}}  style="border-radius: 40%;" width="100px" alt="">
          <img *ngIf="!usr.user.photoavatar" src="https://selesoluciona.com:8351/fotosmarcos/profile.png"  style="border-radius: 40%;" width="100px"  alt="">
   <h4  (click)="openChatAdmin(usr.id)" style="color: white !important; cursor: pointer; margin-bottom: 1px; " id="li{{usr.id}}" >{{usr.name}} </h4>
   <button class="btn btn-primary mt-0 mb-2"  *ngIf="!user.contactosenv.includes(usr.user.correo)" (click)="contactar(usr.user)" >Contactar</button>

   </li>
 </div>
       </ul>  
       </div>

      <div class="col-11 col-md-3 mt-5" style=" height: 500px; overflow-y: auto;" >
        <div style="text-align: left; " *ngFor="let sala of salas" >
        <div >
         
          <div style="  margin-top: 2px; padding: 20px; background-color: rgba(1, 1, 1, 0.7); border-radius: 2px 2px 2px 2px">
            <div style="color:white" ><strong> {{sala.name}}</strong>
              <br>
              <!-- <img src="../../../assets/botones/pngwing.com.png" width="80%" *ngIf="sala.status=='open'" style="cursor: pointer;"  (click)="irsala(sala)" alt=""> -->
              <button   *ngIf="sala.status=='open'" class="btn btn-primary" (click)="irsala(sala)" >Ingresar</button>
            </div>
          
              <span *ngIf="sala.status=='close'"  >Esta sala se encuentra cerrada, pero puedes solicitar acceso</span>
<button *ngIf="sala.status=='close'" class="btn btn-primary"  (click)="solicitar(sala.name)"  > Solicitar acceso </button>
              
              <span *ngIf="sala.status=='full'"  >Esta sala se encuentra llena</span><br>                
              <div  *ngIf="user.rol=='admin'" >
<span style="color:white" >{{sala.status}}</span>
<button class="btn btn-primary"  (click)="cambiarEstado(sala._id,sala.status)" > Cambiar estado </button>
              </div>
              <div class="container text-white col-md-5 col-10 " >
        
                <!-- <h3>Usuarios en linea: {{existingUsrs?.length || '0' }} </h3>  -->
                <ul>
                     <div *ngFor="let usr of existingUsrs">                      
            <li  *ngIf="usr.room == sala.name"  >
            <h4  style="color:white"  >{{usr.name}} </h4>
            </li>
          </div>
                </ul>  
             </div>
          </div>

         

        </div>

        <br>
      </div>




        <br>




      </div>
    </div>

  </div>



  <div  >
      <div *ngFor="let usr of existingUsrs" >
          <div id="chat{{usr.id}}" style="display: none;">
      <app-chat style="z-index: 99900000;" [remoteUser]="usr" >
      </app-chat>
      <button (click)="closeChatAdmin(usr.id)" class="btn btn-danger btnChat " >Cerrar</button>
       <button (click)="inviteuser(usr)" class="btn btn-danger btnCall "  >Invitar</button> 
      </div>
      </div>
      </div> 

<div class="container w-50 bg-dark p-2 rounded-top shadow text-white"   style="z-index: 200000; position: absolute;" *ngIf="user.rol=='admin'">
  <form [formGroup]="createSala" (ngSubmit)="newEvent()" novalidate="">    
      
     
       <div class="form-group">
       <h3>Ingrese el número de salas'</h3>     
       <input class="form-control" type="number" placeholder="Numero" formControlName="number" required>
       </div>
     
       <div class="form-group">
        <h3>¿Incluir video?</h3>     
       
        <select formControlName="video" required >
          <option value="si">si</option>
          <option value="no">no</option>

        </select>
        </div>
        <div class="form-group">
          <h3>Ingrese el iframe</h3>     
          <input class="form-control" type="string" placeholder="url" formControlName="url" required>
          </div>
       <input type="submit" value="Guardar" class="btn btn-info">
  
  </form>
  </div>


</div>