import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import { Observable } from 'rxjs';
import { Observer } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SocketioService {
  socket;
  //observer;
  constructor() { }
  setupSocketConnection() :Observable<String> {
    
    this.socket = io('https://selesoluciona.com',{path:"/eventopragma/socket.io", transports: [ 'websocket' ],secure: true, reconnection:true,rejectUnauthorized: false  });
var provi =new Observable<String>(observer=>{
  
  this.socket.on("pi",()=>{
    this.pong();
  });
this.socket.on("connect_error",()=>{
  console.log("socket caido");
})
this.socket.on("reconnect",()=>{
  console.log("socket reconnect");
      var  _message=JSON.stringify({"id":"reconnect"});
     observer.next(_message);
})
  this.socket.on('newUser', (data) => {
    var  _message=JSON.stringify({"id":"newUser","user":JSON.parse(data)});
     observer.next(_message);
   }); 

   this.socket.on('userLeft', (data) => {
     console.log(data);
    var  _message=JSON.stringify({"id":"userLeft","user":JSON.parse(data)});
     observer.next(_message);
   }); 

    this.socket.on('message', function (_message) {
      //var message = JSON.parse(_message);
    observer.next(_message);

  });
  
})
return provi;
}

pong(){
  //console.log("ping");
this.socket.emit("po");
}

  joinRoom(usr,event){
    this.socket.emit('joinRoom', JSON.stringify({"user":usr,"event":event}));
  }

close(){
  this.socket.disconnect();
}

send(msg){
  msg=JSON.stringify(msg);
  this.socket.send(msg);
}


}
