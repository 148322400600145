import { Component, OnInit } from '@angular/core';
import { EventsService } from '../../services/events.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { interval } from 'rxjs';
import { saveAs } from 'file-saver';
import { AuthService } from '../../services/auth.service';
import {ChangeDetectorRef} from '@angular/core'
import  Swal from 'sweetalert2'; 
@Component({
  selector: 'app-miagenda',
  templateUrl: './miagenda.component.html',
  styleUrls: ['./miagenda.component.css']
})
export class MiagendaComponent implements OnInit {

  intervalid;
  user;
  emptyUser;
 createEvent: FormGroup;
 events=[];
 pevents=[];
 premium=[];
 enabled;
  dia: any;
  jueves: any[];
  viernes: any[];
  lugar: any;
  eventshow: any[];
  privados: any;
  miercoles: any[];
  rooms=["Sala Débora Arango","Sala Coco Chanel","Sala Marie Curie","El Chiringuito "]
   constructor(private authService : AuthService, private eventService : EventsService, private router: Router, private cd: ChangeDetectorRef) { 
     this.createEvent = new FormGroup({
       'img':new FormControl("",[Validators.required,]),
       'imgSrc':new FormControl("",[Validators.required,]),
  'id':new FormControl("0",[Validators.required,]),
       'name': new FormControl('', [
         Validators.required,
         Validators.minLength(5),
       ]),
       'date': new FormControl('', [
         Validators.required,
         Validators.minLength(5),
       ]),
       'tipo': new FormControl('', [
         Validators.required,
         Validators.minLength(2),
       ]),
       'pay': new FormControl('', [
         Validators.required,
         Validators.minLength(2),
       ]),
       'iframeSrc': new FormControl('', [
         Validators.required,
         Validators.minLength(3),
       ]),
       'meet': new FormControl('', [
         Validators.required,
         Validators.minLength(3),
       ]),
       'externoSrc': new FormControl('', [
         Validators.required,
         Validators.minLength(10),
       ]),
     });
  
 
 
   }
 
   ngOnInit(): void {
     this.enabled=false;
     let user = localStorage.getItem('userInfo')
     this.user= JSON.parse(user).user;
     console.log(this.user);
     this.getEvents();
     this.loadpast();
   this.intervalid = setInterval(() => {
     this.getEvents();
   }, 15000);
     
   }
   ngOnDestroy() {
     if (this.intervalid) {
       clearInterval(this.intervalid);
     }
   }
 
 
   getEvents(){
    this.miercoles=[];
    this.jueves=[];
    this.viernes=[];
    this.privados=[];
    var now= new Date();
    var n = now.getUTCHours();
    if(n<5){
      n=24+n; 
    }
    function compare( a, b ) {
      if ( a.date < b.date ){
        
        return -1;
      }
      if ( a.date > b.date ){
        return 1;
      }
      return 0;
    }
  
  
   now.setHours(n-5);
   console.log(now);
   this.eventService.getAll()
   .then((r)=>{
    console.log(r)
     this.events=[];
     var events=r["events"]
     events.sort( compare );
     events.forEach(element => {
      //console.log(element);
      var date= new Date(element.date);
  var fecha= date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getUTCFullYear()+" hora: "+date.getHours()+":"+(date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
  element.date=fecha;
  if(now.getTime( ) > date.getTime()-(300*1000)){
  element.isnow=true
  }
  if(element.pay!="premium" && this.user.events.includes(element._id)){
    this.events.push(element)
    switch (element.day) {
        case "jueves":
          this.jueves.push(element);
          break;
           case "viernes":
        this.viernes.push(element);
        break;
      default:
        break;
    }
  }
  else{
    this.privados.push(element);
  
  }
  
  
    });
    this.cambioDia("jueves")
    this.cambioLugar(0)
   })
  }
 
 
   private loadAllEvents() {
   var now= new Date();
   var n = now.getUTCHours();
   if(n<5){
     n=24+n; 
   }
   function compare( a, b ) {
     if ( a.date < b.date ){
       
       return -1;
     }
     if ( a.date > b.date ){
       return 1;
     }
     return 0;
   }
 
 
  now.setHours(n-5);
  console.log(now);
     this.eventService.getAll()
     .then((response)=>{
      var events=response["events"];
     events.sort( compare );
      var eventos=[] 
      var premium=[];
      events.forEach(element => {
        //console.log(element);
        var date= new Date(element.date);
 var fecha= date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getUTCFullYear()+" hora: "+date.getHours()+":"+(date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
 element.date=fecha;
 if(now.getTime( ) > date.getTime()-(300*1000)){
   element.isnow="true"
 }
 //console.log(date.getDate());
 if(this.user.events.includes(element._id)){

  eventos.push(element);
 }


      });
       this.events=eventos;
    
     });
 
 }
 
 loadpast(){
   this.eventService.getAllp()
   .then((response)=>{
   this.pevents=response["events"];
   })
 
 }
 
 
 
 
 
 

 
 
 
 logout(){
   localStorage.removeItem('userInfo');
   this.router.navigate(['inicio']);
 }
 
 ingresar(event,id){
   this.eventService.ingreso(event,this.user)
   .then((response) => {
     console.log(response);
     var events=this.user.events
     var index=events.indexOf(id);
     if(index>-1){
       this.user.events.splice(index,1);
     }
 //switch para taller e interno, modulo interno
 switch (event.tipo) {
 
   case "iframe":
     location.href="/conferencia/"+id;
       break;
   case"taller":
   location.href="/taller/"+id;
   break;
   case"interno":
   location.href="/conferencia/"+id;
   break;
     }
 
   })
   .catch((err)=>{
    console.log(err);
 })
 
 }
 ingresarp(event,id){
   switch (event.tipo) {
     case "iframe":
       location.href="/conferencia/"+id;
         break;
     case"taller":
     location.href="/taller/"+id;
     break;
     case"interno":
     location.href="/conferencia/"+id;
     break;
       }
 }
 irExternop(event,id,src){
   location.href=src;
 }
 
 irExterno(event,id,src){
   this.eventService.ingreso(event,this.user)
   .then((response) => {
     console.log(response);
     var events=this.user.events
     var index=events.indexOf(id);
     if(index>-1){
       this.user.events.splice(index,1);
     }
     location.href=src;
   })
   .catch((err)=>{
    console.log(err);
 })
 
 }
 
 
 
 cambioDia(dia){
  this.dia=dia;
  switch (dia) {
      case "jueves":
        this.events=this.jueves;
        break;
         case "viernes":
          this.events=this.viernes;
      break;
    default:
      break;
  }
  this.cambioLugar(this.lugar);
}

cambioLugar(lugar){
  this.lugar=lugar;
  this.eventshow=this.events.filter((r)=>{return r.room== lugar})

}
 
 
 }
 