<div class="lienzo" >


  <div style="padding: 1px; text-align: center;">
    <h1 style="color: white" *ngIf="event && event.video!='si'"  >Bienvenido {{user.name}} </h1> 
    

 <div class="row justify-content-center  mb-2" *ngIf="event && event.video=='si' "  >
  <div  class=" col-md-6 embed-responsive embed-responsive-16by9">
    <iframe id="iframe" class="embed-responsive-item"  width="98%" frameborder="0" [src]="event.url | safe" allowfullscreen style="display: block;"></iframe>

</div>
 </div>
      
      <div class="row justify-content-center" *ngIf="event">
       
        <div class="col-1"> 
          <button class="btn btn-primary" *ngIf="event.status=='open' && existingUsrs.length>0 " (click)="change('close')" >Cerrar sala</button> <br>
          <button class="btn btn-primary" *ngIf="event.status=='close' " (click)="change('open')" >Abrir  sala</button>
          <img (click)="cambiarCam()" width="100%" src="/assets/switch.png" alt="">
          </div>
          <div *ngIf="session && event.video=='si' "  class=" col-md-9  ">
            
            <div  id="session" width="98%" style="margin-top: 0; height:250px ; text-align: left !important;">
          
              <opv-session #ovSessionComponent [sessionName]="event.name" [user]="user.name" [tokens]="tokens" [ovSettings]="ovSettings"
                (sessionCreated)="handlerSessionCreatedEvent($event)"  (publisherCreated)="handlerPublisherCreatedEvent($event)" (participantCreated)="handlerPublisherCreatedEvent($event)" (error)="handlerErrorEvent($event)">
              </opv-session>
          </div> 
  
          </div>

          <div *ngIf="session && event.video=='no' "  class=" col-md-9  ">
            
            <div  id="session" width="98%" style="margin-top: 0; height:550px ; text-align: left !important;">
          
              <opv-session #ovSessionComponent [sessionName]="event.name" [user]="user.name" [tokens]="tokens" [ovSettings]="ovSettings"
                (sessionCreated)="handlerSessionCreatedEvent($event)"  (publisherCreated)="handlerPublisherCreatedEvent($event)" (participantCreated)="handlerPublisherCreatedEvent($event)" (error)="handlerErrorEvent($event)">
              </opv-session>
          </div> 
  
          </div>

          <div class="col-1">
           
            </div>

      </div>
   
      <br>
      <br>

    </div>

  </div>