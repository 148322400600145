import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import  Swal from 'sweetalert2'; 
import { EmpresaService } from "../../services/empresa.service";
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-empresa-profile',
  templateUrl: './empresa-profile.component.html',
  styleUrls: ['./empresa-profile.component.css']
})
export class EmpresaProfileComponent implements OnInit {


  bitacoraForm: FormGroup;
  submitted = false;
  type: string;
  items: any;
  user;
  personas=[];
  id;
  contacts;
  imgPop=false;
  popSrc;
  edato=false;
  @ViewChild('escarapela', {static: false}) escarapela: ElementRef;
  empresa:any;
  registerForm: FormGroup;
  add:boolean=false;
  yaPerson:boolean=false;
  person:any;
  lPerson: boolean;
  docPop: boolean;
  reperson;
  registerFormPerson: FormGroup;
  cargador: boolean;
  tipoagregar="3"
  constructor(private authService : AuthService,
    private router : Router,
     private empresaService:EmpresaService,
    private cd:ChangeDetectorRef,
    private route: ActivatedRoute, 
    private elRef:ElementRef, ) { }

  ngOnInit(): void {
    this.id=this.route.snapshot.paramMap.get("id")
    let user = localStorage.getItem('userInfo')
    this.user = JSON.parse(user).user;
    console.log(this.user)
    this.bitacoraForm = new FormGroup({
      name: new FormControl('', Validators.required),
      type: new FormControl('documento', Validators.required),
      file: new FormControl('', ),
      url: new FormControl('', ),
      fileSource: new FormControl('', )
    })


    this.registerFormPerson  = new FormGroup({
      'name': new FormControl('', [
        Validators.required,
       
      ]),
      
      'doc': new FormControl('', [
        Validators.required,
  
      ]),
      'company': new FormControl('', [
        Validators.required,
        ]),
        'email': new FormControl('', [
          Validators.required,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
        ]),
        'position': new FormControl('', [
          Validators.required,
          ]),
          'phone': new FormControl('', [
            Validators.required,
            ]),
  
   

      'terminos':new FormControl(true, [
        Validators.requiredTrue]),
      'rol': new FormControl('staff', [
        Validators.required,
        Validators.minLength(3)
      ]),

    });
 

 

    this.registerForm = new FormGroup({ 
      'name': new FormControl('', [
        Validators.required,
        Validators.minLength(3)
      ]),
    

      'nit': new FormControl('', [
        Validators.required,
        
      ]),
      'descripcion': new FormControl('', [
        Validators.required,
        
      ]),

      'img':new FormControl(""), 

      'imgSrc':new FormControl(""),
    
     
 
     

    });
    this.getBitacora()
    this.loadCompany();

  }


  onFileChangeEdit(event) {
    let reader = new FileReader();

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
   
        this.registerForm.patchValue({
          imgSrc: reader.result
        });
        
        // need to run CD since file load runs outside of zone
        this.cd.markForCheck();
      };

    }
  }
  edituser(){
    var evento:Object ={
     name:this.empresa.name,    
     nit:this.empresa.nit,
     descripcion:this.empresa.descripcion  
    }
    this.registerForm.reset(evento);
  }

  onOptionsSelected(value:string){
    console.log("the selected value is " + value);
    this.type = value;
}
loadCompany(){
  this.authService.loadcompany(this.id)
  .then((r)=>{
    console.log(r);
    this.empresa=r["user"];
    this.personas=[];
    //console.log(this.empresa.personas)
    this.edituser();
    this.registerFormPerson.patchValue({
      company:this.empresa.name
    })
  })
}
get f2() { return this.registerForm.controls; }

get f3() { return this.registerFormPerson.controls; }

  get f() { return this.bitacoraForm.controls; }


  onFileChange(event) {

    /* if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.bitacoraForm.patchValue({
        fileSource: file
      });
    } */


    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
   
        this.bitacoraForm.patchValue({
         fileSource: reader.result
        });
        
        // need to run CD since file load runs outside of zone
        this.cd.markForCheck();
      };

    }




  }

  bitacora(){
    //const formData = new FormData();
    //formData.append('file', this.bitacoraForm.get('fileSource').value);
    this.submitted = true;
    console.log(this.f)
    if (this.bitacoraForm.invalid) { 
    
      return;
    }
    
    //console.log(this.bitacoraForm.value)
    this.empresaService.addElement(this.bitacoraForm.value,this.user._id)
    .then((r)=>{
      console.log(r["item"]);
      this.items.push(r["item"])
      this.bitacoraForm.reset();
      this.bitacoraForm.patchValue({
        type:"documento"
      })
     
    })
    .catch((err)=>{
      console.log(err)
    })

    

  
  } 

  delete(id){
    console.log(id);
   
     this.empresaService.deleteItem(id)
     .then((response) => {
       console.log(response);
   this.getBitacora();
     }) 
     .catch((err)=>{
      console.log(err);
   })
 }
getBitacora(){
  this.empresaService.getItems(this.id)
  .then((r)=>{
    console.log("items",r)
    this.items=r
  })
}


downloadItems(){
  var documentos= this.items.filter((el)=>{return el.type=='documento'})
  documentos.forEach(element => {
    var a = document.createElement("a");
    a.setAttribute('href', element.url);
    a.setAttribute('download', element.name);
    a.setAttribute('target', '_blank');
    a.click();
  });
}

downloadAsPDF() {
  // Extraemos el
  const DATA = this.escarapela.nativeElement;
  const doc = new jsPDF('p', 'pt', 'a4');
  const options = {
    background: '#575b93',
    scale: 3
  };
  html2canvas(DATA, options).then((canvas) => {

    const img = canvas.toDataURL('image/PNG');

    // Add image Canvas to PDF
    const bufferX = 15;
    const bufferY = 45;
    const imgProps = (doc as any).getImageProperties(img);
    const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
    return doc;
  }).then((docResult) => {
    docResult.save(`${new Date().toISOString()}_+ctg.pdf`);
  });
}

 
onActive(agreed: boolean) {
  this.cargador = false;
  this.loadCompany();

  //this.ngOnInit();
}

register2(){
  this.submitted = true;
 // console.log(this.registerForm.value)
  if (this.registerForm.invalid) {
    console.log(this.f2)
    return;
}
this.empresaService.create(this.registerForm.value)
.pipe(first())
.subscribe(
  (res) =>{
    console.log(res);
    if(res["user"]["aprobe"]=="true"){
        this.authService.setUserInfo({'user' : res['user']});
        Swal.fire(     {
          title: "Se realizaron los cambios con exito",
          text:"",
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Continuar'
          
      }).then((result)=>{
       this.loadCompany();     
      });
      
      }
      else{
        Swal.fire("Los datos se cargaron de forma correcta.");
        this.edato=false;
        this.loadCompany()
      }
      
  
  },
  (err) =>{

    Swal.fire(     {
            title: "Hubo un error",
            text:"Por favor intenta de nuevo.",
            icon: 'error',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ingresar ',
            cancelButtonText: "Reintentar"
            
        }).then((result)=>{
        if(result.value){
          this.edato=false;
         this.loadCompany();
        }
        else{
          this.edato=false;
          this.loadCompany();
        }
        });
   
  } 
);

}


registerp(){
  this.submitted = true;
  //console.log(this.registerForm.value)
  if (this.registerFormPerson.invalid) {
    console.log("invalido",this.f3);
    return;
}
//console.log(this.f3)

this.authService.create(this.registerFormPerson.value)
.pipe(first())
.subscribe(
  (res:any) =>{
    console.log("usuario",res);
  
    var ticket={
      user:res.user._id,
      ready:true,
      type:this.tipoagregar
    }
    this.authService.addTicket(ticket)
    .then((r)=>{
     // Swal.fire("Los datos se cargaron de forma correcta.");
   
    })
     
      
   
      this.empresaService.addPerson(res["user"]._id,this.id)
     
      .then((r)=>{
        Swal.fire(     {
          title: "El registro fue exitoso",
          text:"",
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Continuar'
          
      }).then((result)=>{
        //añadir invitado a usuario.     
       this.loadCompany();
       this.reperson=false;
        this.add=false;
        this.submitted=false;
        this.registerFormPerson.reset();

      });
      })
      
        
      
    
    
  
  },
  (err) =>{

    Swal.fire(     {
            title: "El registro no fue exitoso",
            text:"Por favor verifica tus datos, si ya te encuentras registrado pulsa el boton ingresar. Recomendamos el uso del navegador web Chrome. Si estás conectado por VPN por favor desconéctate y vuelve a intentarlo.",
            icon: 'error',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ingresar ',
            cancelButtonText: "Reintentar"
            
        }).then((result)=>{
        if(result.value){
        
          this.loadCompany()
        }
        else{
         this.loadCompany()
        }
        });

  } 
);

}

cancelBuscar(){
  this.add=false;
   this.person=null;
   this.yaPerson=false;
   this.reperson=false;
   this.add=false;
}

buscarPerson(){
  var buscado=this.elRef.nativeElement.querySelector('#cedula').value;
  console.log(buscado);
  this.authService.loadcontactid(buscado)
  .then((r)=>{
    if(r==null){
      Swal.fire("Documento no encontrado")
    }
    else{
      this.yaPerson=true;
      this.person=r["user"]
    }
    
  })
  .catch(()=>{
    Swal.fire("Documento no encontrado")
  })
}
addPerson(){
  this.empresaService.addPerson(this.person._id,this.id)
  .then((r)=>{
    Swal.fire("Persona agregada correctamente")
    .then(()=>{
      this.add=false;
      this.person=null;
      this.loadCompany();
      this.yaPerson=false;
    })
  })
  .catch(()=>{

  })
}


verPersonas(){
  this.empresa.personas.forEach(persona => {
    this.authService.loadcontact(persona)
    .then((r)=>{
      this.lPerson=true;
      this.personas.push(r["user"])
    })
    .catch((er)=>{
      this.deletePerson(persona);
    })
    
  });
}
deletePerson(id){
  this.empresaService.removePerson(id,this.id)
  .then((r)=>{
    Swal.fire("Persona removida correctamente")
    .then(()=>{
     this.lPerson=false;
      this.loadCompany();
    })
  })
  .catch(()=>{

  })
}


openPopImg(it){
  this.imgPop=true;
  this.popSrc=it.url
}
openPopDoc(it){
  this.docPop=true;
  this.popSrc=it.url
}



descargar(){
  var  contacts_=this.personas.map((contact)=>{return {"nombre":contact.name,"correo":contact.email,"doc":contact.doc,"Empresa":contact.company,"Cargo":contact.position,"celular":contact.phone}})
  this.exportAsExcelFile(contacts_,"contactos")
}

public exportAsExcelFile(json: any[], excelFileName: string): void {
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
  const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
  const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  this.saveAsExcelFile(excelBuffer, excelFileName);
}

private saveAsExcelFile(buffer: any, fileName: string): void {
  const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
  FileSaver.saveAs(data, fileName + '_export_' + new  Date().getTime() + EXCEL_EXTENSION);
}

}
