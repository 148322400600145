<div class="fondo" >


<div style="padding: 30px; text-align: center;">
  <h3 style="color: white">Bienvenido {{user.name}}</h3>
  <!-- <div class="row mt-3 justify-content-center ">
    <div class="col-md-6">

      <div class="m-2" style="float: left;">
        <button *ngIf="dia!='miercoles'" (click)="cambioDia('miercoles')" class="btn btn-orange">Miercoles 4 de
          octubre</button>
        <span *ngIf="dia=='miercoles'" style="font-size: 20px; font-weight:bold; margin:10px  ">Miercoles 4 de
          octubre</span>
      </div>
      <div class="m-2" style="float: left;">
        <button *ngIf="dia!='jueves'" (click)="cambioDia('jueves')" class="btn btn-orange">Jueves 5 de octubre</button>
        <span *ngIf="dia=='jueves'" style="font-size: 20px; font-weight:bold; margin:10px  ">Jueves 5 de octubre</span>
      </div>
      <div class="m-2" style="float: left;">
        <button *ngIf="dia!='viernes'" (click)="cambioDia('viernes')" class="btn btn-orange">Viernes 6 de
          octubre</button>
        <span *ngIf="dia=='viernes'" style="font-size: 20px; font-weight:bold; margin:10px  ">Viernes 6 de
          octubre</span>
      </div>
    </div>

  </div> -->
  <hr>
  <!-- <div class="row mt-0 justify-content-center ">
    <div class="col-md-6">
      <div class="m-2" style="float: left;">
        <button *ngIf="lugar!=0" (click)="cambioLugar(0)" class="btn btn-orange">{{rooms[0]}}</button>
        <span *ngIf="lugar==0" style="font-size: 20px; font-weight:bold; margin:10px  ">{{rooms[0]}}</span>
      </div>

      <div class="m-2" style="float: left;">
        <button *ngIf="lugar!=1" (click)="cambioLugar(1)" class="btn btn-orange">{{rooms[1]}}</button>
        <span *ngIf="lugar==1" style="font-size: 20px; font-weight:bold; margin:10px  ">{{rooms[1]}}</span>
      </div>
      <div class="m-2" style="float: left;">
        <button *ngIf="lugar!=2" (click)="cambioLugar(2)" class="btn btn-orange">{{rooms[2]}}</button>
        <span *ngIf="lugar==2" style="font-size: 20px; font-weight:bold; margin:10px  ">{{rooms[2]}}</span>
      </div>

      <div class="m-2" style="float: left;">
        <button *ngIf="lugar!=3" (click)="cambioLugar(3)" class="btn btn-orange">{{rooms[3]}}</button>
        <span *ngIf="lugar==3" style="font-size: 20px; font-weight:bold; margin:10px  ">{{rooms[3]}}</span>
      </div>

      <div class="m-2" style="float: left;">
        <button *ngIf="lugar!=4" (click)="cambioLugar(4)" class="btn btn-orange">{{rooms[4]}}</button>
        <span *ngIf="lugar==4" style="font-size: 20px; font-weight:bold; margin:10px  ">{{rooms[4]}}</span>
      </div> 

    </div>

  </div> -->
  <div class="row mt-3 justify-content-center ">


    <div *ngFor="let event of eventshow" class="caja m-1 col-md-3" style="background-color: #eaeaea; ">
      <div class="row justify-content-start">
        <div *ngIf="event.imgSrc" class="col-md-3">
          <img width="100%" src={{event.imgSrc}}>
        </div>
        <div class="col-md-12">
          <span style="font-size: 14px; ">{{event.name}}</span> <br>
          <span style="color: black;"> Fecha: {{event.date}}</span> <br>
          <span>Salon: {{rooms[event.room]}}</span>
        </div>


      </div>


      <div *ngIf="event.isnow=='false'">
        - <button (click)="registrarme(event)" *ngIf="!user.events.includes(event._id)"
          class="btn btn-orange">Registrarme</button>
        <p *ngIf="user.events.includes(event._id)">Registrado</p>
      </div>
      <div *ngIf="event.isnow=='true'">
        <img width="48%" (click)="ingresar(event,event._id)" *ngIf="event.tipo!='externo'"
          src="/assets/boton_ingresar.png">
        <img width="48%" (click)="irExterno(event,event._id, event.eventSrc)" *ngIf="event.tipo=='externo'"
          src="/assets/boton_ingresar.png">
        -
      </div>
      <!-- <a href="/votaciones/{{event._id}}"> <button  class="btn btn-danger" >Votaciones</button></a> <br> -->
      <div *ngIf="user.rol=='admin'">
        <button class="btn btn-danger" (click)="removeEvent(event._id)" *ngIf="user.rol=='admin'">Archivar</button> <br>
        <button (click)="edit(event)" class="btn btn-danger">Editar</button>
        <button class="btn btn-info" (click)="registrados(event._id,event.name)"
          *ngIf="user.rol=='admin'">Registrados</button>
        <button class="btn btn-info" (click)="asistentes(event._id,event.name)"
          *ngIf="user.rol=='admin'">Asistentes</button>

        <a href="/validacion/{{event._id}}" class="btn btn-danger">Validar ingresos</a>
        <br>
        <button class="btn btn-info" (click)="memoria(event)" *ngIf="user.rol=='admin'">Crear memoria</button>

      </div>

    </div>

  </div>



  <!-- <div class="row justify-content-center">
        <div class="col-11 col-md-2" *ngFor="let event of events">
          <div style="text-align: left;"  >
          <div *ngIf="event.pay!='premium'" >
           <img *ngIf="event.imgSrc" width="100%" src={{event.imgSrc}}> 
            <div style="margin-top: 2px; padding: 20px; background-color: #eaeaea;  border-radius: 2px 2px 2px 2px">
              <div><strong> {{event.name}}</strong></div>
              <div style="font-size: 12px">fecha: {{event.date}}</div>
              
              <div *ngIf="event.isnow=='false'" >
                - <button (click)="registrarme(event)" *ngIf="!user.events.includes(event._id)" class="btn btn-orange" >Registrarme</button>
                <p *ngIf="user.events.includes(event._id)">Registrado</p>
            </div>
               <div *ngIf="event.isnow=='true'">
                <img width="48%" (click)="ingresar(event,event._id)" *ngIf="event.tipo!='externo'" src="/assets/boton_ingresar.png">
                <img width="48%" (click)="irExterno(event,event._id, event.eventSrc)" *ngIf="event.tipo=='externo'" src="/assets/boton_ingresar.png">
                - 
            </div >
            <div *ngIf="user.rol=='admin' && event.isnow=='false'">
            <img width="48%" (click)="ingresar(event,event._id)" *ngIf="event.tipo!='externo'"src="/assets/boton_ingresar.png">
            <img width="48%" (click)="irExterno(event,event._id, event.eventSrc)" *ngIf="event.tipo=='externo'" src="/assets/boton_ingresar.png">
          </div>
        
            <div *ngIf="user.rol!='usuario'">
              <span *ngIf="url1=='close'" >El auditorio esta cerrado <button class="btn btn-primary" (click)="changeState('/conferencia/'+event._id)" >enviar a este evento</button> </span>
              <span *ngIf="url1!='close'" >El auditorio esta abierto  <button class="btn btn-primary" (click)="changeState('close')" >Cerrar</button> </span>
              <br>
              <a href="/monitor/{{event._id}}">
                <button class="btn btn-primary"  *ngIf="user.rol!='usuario'">ingreso conferencistas</button></a> <br>
                <button class="btn btn-danger" (click)="edit(event)" *ngIf="user.rol=='admin'">Editar</button> <br>
                <button class="btn btn-danger" (click)="removeEvent(event._id)" *ngIf="user.rol=='admin'">Archivar</button> <br>
           
                  <button class="btn btn-info" (click)="registrados(event._id,event.name)" *ngIf="user.rol=='admin'">Registrados</button>
                  <button class="btn btn-info" (click)="asistentes(event._id,event.name)" *ngIf="user.rol=='admin'">Asistentes</button>
      
            </div>
            </div>

           

          </div>

          <br>
        </div>




          <br>


  

        </div>
      </div> -->




  <div class="row justify-content-center" *ngIf="user.rol=='admin' || user.pay=='premium'">
    <div class="col-11 col-md-6">
      <div style="text-align: left;" *ngFor="let event of privados">
        <div *ngIf="event.pay=='premium'">

          <div style="margin-top: 2px; padding: 20px; background-color: #eaeaea;  border-radius: 2px 2px 2px 2px">
            <div><strong> {{event.name}} (Privado)</strong></div>
            <span>/conferencia/{{event._id}}</span>
            <div *ngIf="event.speaker" style="font-size: 13px; color: black; margin-top: 10px; ">{{event.speaker}}</div>
            <div style="font-size: 12px">fecha: {{event.date}}</div>

            <div *ngIf="event.isnow=='false'">
              - <button (click)="registrarme(event)" *ngIf="!user.events.includes(event._id)"
                class="btn btn-orange">Registrarme</button>
              <p *ngIf="user.events.includes(event._id)">Registrado</p>
            </div>
            <div *ngIf="event.isnow=='true'">
              <img width="48%" (click)="ingresar(event,event._id)" *ngIf="event.tipo!='externo'"
                src="/assets/boton_ingresar.png">
              <img width="48%" (click)="irExterno(event,event._id, event.eventSrc)" *ngIf="event.tipo=='externo'"
                src="/assets/boton_ingresar.png">
              -
            </div>
            <div *ngIf="user.rol=='admin' && event.isnow=='false'">
              <img width="48%" (click)="ingresar(event,event._id)" *ngIf="event.tipo!='externo'"
                src="/assets/boton_ingresar.png">
              <img width="48%" (click)="irExterno(event,event._id, event.eventSrc)" *ngIf="event.tipo=='externo'"
                src="/assets/boton_ingresar.png">
            </div>
            <button class="btn btn-danger" (click)="removeEvent(event._id)" *ngIf="user.rol=='admin'">Archivar</button>
            <br>
            <button class="btn btn-danger" (click)="edit(event)" *ngIf="user.rol=='admin'">Editar</button> <br>
            <button class="btn btn-info" (click)="registrados(event._id,event.name)"
              *ngIf="user.rol=='admin'">Registrados</button>
            <button class="btn btn-info" (click)="asistentes(event._id,event.name)"
              *ngIf="user.rol=='admin'">Asistentes</button>

            <button class="btn btn-info" (click)="memoria(event)" *ngIf="user.rol=='admin'">Crear memoria</button>

          </div>



        </div>

        <br>
      </div>




      <br>




    </div>
  </div>



</div>


<!-- <div class="row justify-content-center mb-3" >
      <button (click)="enabled=!enabled"class="btn btn-primary" >Eventos pasados <i class="fas fa-bars"></i></button>
    </div> -->

<div class="row justify-content-center">

  <div class="col-8 col-md-4">


    <div style="text-align: left;" *ngFor="let event of pevents">
      <div *ngIf="enabled">
        <img width="100%" src={{event.imgSrc}}>
        <div style="margin-top: 2px; padding: 20px; background-color: #eaeaea;  border-radius: 2px 2px 2px 2px">
          <div><strong> {{event.name}}</strong></div>

          <img width="48%" (click)="ingresarp(event,event.id)" *ngIf="event.tipo!='externo'"
            src="/assets/boton_ingresar.png">
          <img width="48%" (click)="irExternop(event,event.id, event.eventSrc)" *ngIf="event.tipo=='externo'"
            src="/assets/boton_ingresar.png">
          -

          <button class="btn btn-danger" (click)="removepEvent(event._id)" *ngIf="user.rol=='admin'">Eliminar</button>
          <br>
          <button class="btn btn-danger" (click)="edit(event)" *ngIf="user.rol=='admin'">Editar</button> <br>
          <button class="btn btn-info" (click)="registrados(event.id,event.name)"
            *ngIf="user.rol=='admin'">Registrados</button>
          <button class="btn btn-info" (click)="asistentes(event.id,event.name)"
            *ngIf="user.rol=='admin'">Asistentes</button>

        </div>



      </div>

      <br>
    </div>




    <br>




  </div>
</div>


<div class="container w-50 bg-dark p-2 rounded-top shadow text-white" *ngIf="user.rol=='admin'">
  <form [formGroup]="createEvent" (ngSubmit)="newEvent()" novalidate="">
    <div class="form-group" *ngIf="createEvent.controls['id'].value!='0'">
      <h3>Id</h3>
      <input type="text" class="form-control" formControlName="id" readonly>
    </div>
    <div class="form-group">
      <h3>Ingrese el poster del evento</h3>
      <input class="form-control" type="file" (change)="onFileChange($event)" placeholder="png, jpg"
        formControlName="img" required>
    </div>
    <div class="form-group">
      <h3>Ingrese el nombre del evento</h3>
      <input class="form-control" type="text" placeholder="Evento" formControlName="name" required>
    </div>

    <div class="form-group">
      <h3>Ingrese los conferencistas</h3>
      <input class="form-control" type="text" placeholder="conferencistas" formControlName="speaker" required>
    </div>

    <div class="form-group">
      <h3>Ingrese la fecha del evento</h3>
      <input class="form-control" type="datetime-local" placeholder="Fecha" formControlName="date" min="2020-06-01"
        max="2023-12-31" required>
    </div>
    <div class="form-group">
      <h3>Ingrese la privacidad del evento</h3>
      <select formControlName="pay">
        <option value="free">Abierto</option>
        <option value="premium">Premium</option>

      </select>
    </div>
    <div class="form-group">
      <h3>dia de evento</h3>
      <select formControlName="day">
        <option value="miercoles">Miercoles</option>
        <option value="jueves">Jueves</option>
        <option value="viernes">Viernes</option>

      </select>
    </div>
    <div class="form-group">
      <h3> salon evento</h3>
      <select formControlName="room">
        <option value="0">Salón INTELIGENCIA ARTIFICIAL</option>
        <option value="1">Salón INTELIGENCIA CREATIVA</option>
        <option value="2">Salón INTELIGENCIA HUMANA</option>
        

      </select>
    </div>

    <div class="form-group">
      <input type="checkbox" formControlName="almuerzo"> <span style="color: white;">Marque si este evento requiere
        validacion solo para usuarios con almuerzo</span>

    </div>
    <div *ngIf="createEvent.controls['tipo'].value=='taller'">
      <h3>Ingrese la url del iframe del taller:</h3>
      <input class="form-control" type="text" placeholder="https://" formControlName="iframeSrc" required>
    </div>
    <div>
      <h3>Ingrese la url del iframe del evento:</h3>
      <input class="form-control" type="text" placeholder="https://" formControlName="iframeSrc" required>
    </div>
    <div *ngIf="createEvent.controls['tipo'].value=='interno'">
      <h3>Ingrese el nombre de la reunion:</h3>
      <input class="form-control" type="text" placeholder="Reunion" formControlName="iframeSrc" required>
    </div>
    <div *ngIf="createEvent.controls['tipo'].value=='externo'">
      <h3>Ingrese la url a la web externa:</h3>
      <input class="form-control" type="text" placeholder="https://" formControlName="externoSrc" required>
    </div>
    <div>
      <h3>Ingrese la url de conferecnistas:</h3>
      <input class="form-control" type="text" placeholder="https://" formControlName="meet" required>
    </div>
    <input type="submit" value="Guardar" class="btn btn-info">

  </form>
</div>
</div>