import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { EventsService } from '../../services/events.service';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  @Input() user ={"nit":"","pais":"","apellido":"", "phone":"","empresar":"", "name":"Soporte","email":"adminDefault","cargo":"","position":"na","rol":"admin","company":"","id":"admin1","_id":"","photoavatar":"https://selesoluciona.com:8351/fotosmarcos/profile.png","contactos":[],"ticket":{"type":""}};
  @Input() Vqr=false;
  elementType = NgxQrcodeElementTypes.URL;
  qrvalue = null;
 
  correctionLevel =NgxQrcodeErrorCorrectionLevels.LOW;
  constructor() { }

  ngOnInit(): void {
console.log("hola sii",this.user);
if(this.user._id){
  this.qrvalue=this.user._id;
}
else{
  this.qrvalue="na"
}
    
  }

}
